import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import { getCorrespondenceLogs } from "../../../services/correspondence-log-service";
import { EntityTypes } from "../../../types/enums";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { CorrespondenceLogModal } from "../correspondence-log/correspondence-log-modal";
import { dateUtil } from "../../../services/date-util";
import EditIcon from "@mui/icons-material/Edit";
import { LynxDialog } from "../../lynx-dialog";
import { Grid } from "tabler-react";
import { CorrespondenceContactChip } from "../correspondence-log/correspondence-contact-chip";
export function EventDetailsCorrespondenceLog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCorrespondenceModal, setShowCorrespondenceModal] = useState(false);
  const [selectedCorrespondenceLog, setSelectedCorrespondenceLog] =
    useState(null);

  const [corLogForEdit, setCorLogForEdit] = useState(null);
  const handleCorView = (row) => {
    setSelectedCorrespondenceLog(row);
  };
  const handleAddCorrespondenceLog = () => {
    setIsLoading(true);
    setShowCorrespondenceModal(true);
  };

  const handleEditCorrespondenceLog = (log) => {
    setIsLoading(true);
    setCorLogForEdit(log);
    setShowCorrespondenceModal(true);
  };
  const correspondenceLogColumns = [
    {
      field: "actions",
      headerName: "View / Edit",
      width: 95,
      type: "string",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                handleCorView(params.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleEditCorrespondenceLog(params.row);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "correspondenceDateTimeUtc",
      headerName: "Date Time",
      width: props.isEventForm ? 140 : 110,
      type: "dateTime",
      renderCell: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
    },
    {
      field: "correspondenceTypeCode",
      headerName: "Type",
      width: 75,
      type: "string",
    },
    {
      field: "subject",
      headerName: "Subject",
      width: props.isEventForm ? 220 : 90,
      type: "string",
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      type: "string",
    },
  ];

  const getCorLogDetails = () => {
    const log = selectedCorrespondenceLog.emailIntegrationLog;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Col width={12} className="">
            <Typography variant="h6" component={"span"} className="mr-2">
              Correspondence Log Details
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Date Time:</b>
            </Typography>
            <Typography component={"span"}>
              {dateUtil.convertDateTimeToLocal(
                selectedCorrespondenceLog.correspondenceDateTimeUtc
              )}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Type:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog.correspondenceTypeCode}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Subject:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog.subject}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Details:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog.details}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12} className="mt-2">
            <Typography component={"span"} className="mr-2">
              <b>From:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog.fromDetails}
            </Typography>
            {_.sortBy(
              selectedCorrespondenceLog.correspondenceLogContacts.filter(
                (x) => x.isFrom
              ),
              [
                (contact) =>
                  contact.userId
                    ? contact.userFullName?.toLowerCase()
                    : contact.contactId
                    ? contact.contactFullName?.toLowerCase()
                    : contact.contactGroupId
                    ? contact.contactGroupName?.toLowerCase()
                    : "",
              ]
            ).map((contact, i) => (
              <CorrespondenceContactChip contact={contact} className="ml-2" />
            ))}
          </Grid.Col>
          <Grid.Col width={12} className="mt-2">
            <Typography component={"span"} className="mr-2">
              <b>To:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog.toDetails}
            </Typography>
            {_.sortBy(
              selectedCorrespondenceLog.correspondenceLogContacts.filter(
                (x) => x.isTo
              ),
              [
                (contact) =>
                  contact.userId
                    ? contact.userFullName?.toLowerCase()
                    : contact.contactId
                    ? contact.contactFullName?.toLowerCase()
                    : contact.contactGroupId
                    ? contact.contactGroupName?.toLowerCase()
                    : "",
              ]
            ).map((contact, i) => (
              <CorrespondenceContactChip contact={contact} className="ml-2" />
            ))}
          </Grid.Col>
          {log && !_.isEmpty(log) && (
            <>
              <Grid.Col width={12} className="mt-2 ">
                <Typography variant="h6" component={"span"} className="mr-2">
                  Forwarded Email Details
                </Typography>
              </Grid.Col>{" "}
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>From:</b>
                </Typography>
                <Typography component={"span"}>{log.from}</Typography>
              </Grid.Col>{" "}
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>To:</b>
                </Typography>
                <Typography component={"span"}>{log.toRecipients}</Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Recieved:</b>
                </Typography>
                <Typography component={"span"}>
                  {dateUtil.convertDateTimeToLocal(log.recievedDateTime)}
                </Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Subject:</b>
                </Typography>
                <Typography component={"span"}>{log.subject}</Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Body:</b>
                </Typography>
                <div className="email-body-container">
                  <Typography
                    component={"span"}
                    dangerouslySetInnerHTML={{ __html: log.body }}
                  ></Typography>
                </div>
              </Grid.Col>
            </>
          )}
        </Grid.Row>
      </Grid>
    );
  };
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      {!props.noHeader && (
        <CardHeader
          className="lynx-card-header"
          title="Correspondence Log"
          action={
            <Button onClick={handleAddCorrespondenceLog}>
              Add Correspondence
            </Button>
          }
        />
      )}

      <CardContent
        sx={{
          padding: 0,
          paddingBottom: "0 !important",
        }}
      >
        {props.event.id && (
          <LynxDataGrid
            autoHeight
            isLoading={isLoading}
            isForSettings
            hasNoLookups
            noBorder
            disableToolbar
            columns={correspondenceLogColumns}
            getDataFunction={getCorrespondenceLogs}
            getDataParams={{
              entityType: EntityTypes.Event,
              entityId: props.event.id,
            }}
            onlyShowTable
            entityName="correspondenceLog"
            localStorageName={`${
              props.isEventForm ? "eventForm_" : ""
            }correspondenceLogs-${EntityTypes.Event}-${props.event.id}`}
            {...props}
          />
        )}
      </CardContent>
      {selectedCorrespondenceLog != null && (
        <LynxDialog
          closeOnBackdropClick
          isCloseInHeader
          handleClose={() => setSelectedCorrespondenceLog(null)}
          maxWidth="xl"
          open={selectedCorrespondenceLog != null}
          dialogContent={getCorLogDetails()}
        />
      )}
      {showCorrespondenceModal && (
        <CorrespondenceLogModal
          event={props.event}
          correspondenceLog={corLogForEdit}
          open={showCorrespondenceModal}
          handleClose={() => {
            setShowCorrespondenceModal(false);
            setCorLogForEdit(null);
            setIsLoading(false);
          }}
        />
      )}
    </Card>
  );
}
