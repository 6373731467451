import { LookupTypes } from "../../../types/enums";

export const contactGroupColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    type: "string",
  },
  {
    field: "contactGroupType",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    lookupType: LookupTypes.ContactGroupType,
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },
  {
    field: "numberOfAssociatedContacts",
    headerName: "# Associated Contacts",
    width: 250,
    type: "number",
  },
  {
    field: "numberOfAssociatedEvents",
    headerName: "# Associated Events",
    width: 250,
    type: "number",
  },
];
