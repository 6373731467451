import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

export const lookupSlice = createSlice({
  name: "lookupSlice",
  initialState: initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export default lookupSlice.reducer;

export const { setUsers } = lookupSlice.actions;
