import { apiUrl, client } from "../modules/store";

export function createEntityReview(dto) {
  return client.post(apiUrl + "/entityReview", dto);
}
export function getReviewsForEntity(entityType, entityId) {
  return client.get(
    `/entityReview?entityType=${entityType}&entityId=${entityId}`
  );
}
