import React, { useState, useEffect } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import Button from "@mui/material/Button";
import { getKpis } from "../../../services/kpi-service";
import moment from "moment";
import { LynxDialog } from "../../lynx-dialog";
import _ from "lodash";

export function KpiList(props) {
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    getKpis().then((res) => {
      if (_.isEmpty(res.data)) {
        setShowDialog(true);
      }
    });
  }, []);
  const handleKpiClick = (row) => {
    props.history.push(`/kpis/${row.id}`);
  };
  const statusColorSwitch = (params) => {
    const kpi = params.row;
    if (!kpi.threshold) {
      return "text-warning";
    }
    if (kpi.isUpperThreshold) {
      if (kpi.lastCalculatedValue > kpi.threshold) {
        return "text-danger";
      } else {
        return "text-success";
      }
    } else {
      if (kpi.lastCalculatedValue > kpi.threshold) {
        return "text-success";
      } else {
        return "text-danger";
      }
    }
  };

  const kpiColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      type: "string",
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleKpiClick(params.row)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
    },
    {
      field: "kpiCalculation",
      headerName: "Calculation",
      width: 500,
      type: "string",
    },
    {
      field: "filterName",
      headerName: "Filter",
      width: 150,
      type: "string",
    },
    {
      field: "lastCalculatedDateTimeUtc",
      headerName: "Last Calculated Date",
      width: 150,
      type: "dateTime",
      renderCell: (params) => (
        <div>
          {params.value
            ? moment.utc(params.value).local().format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </div>
      ),
    },
    {
      field: "lastCalculatedValue",
      headerName: "Last Calculated Value",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }

        return parseFloat(params.value).toFixed(8);
      },
    },
    {
      field: "threshold",
      headerName: "Threshold",
      width: 150,
      type: "number",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "number",
      renderCell: (params) => (
        <div>
          <>
            <i className={"fe fe-circle " + statusColorSwitch(params)} />
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      <LynxDialog
        title="Contact Lynx to add KPIs!"
        description="Stay on top of your team’s performance with the Lynx Key Performance Indicator (KPI) module. KPI’s are like a compass to keep you on track and headed in the right direction with performance, benchmarking and evaluation. Contact the Lynx team to get started today."
        handleConfirm={() => {
          setShowDialog(false);
        }}
        open={showDialog}
      />
      <LynxDataGrid
        columns={kpiColumns}
        getDataFunction={getKpis}
        localStorageName="kpis"
        entityName={"KPI"}
        {...props}
      />
    </>
  );
}
