import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { Form } from "tabler-react";
function PhotoCarousel(props) {
  const images = props.result.values;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handlePhotoClick = () => {};

  return (
    <Box
      sx={{
        maxWidth: 500,
        flexGrow: 1,
        marginRight: "2rem",
        marginBottom: "2rem",
        border: "1px solid black",
      }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 100,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div className="pl-3">
          <Form.Group label="Label">
            <Form.Select
              style={{ width: "30%" }}
              name="labelName"
              value={props.result.labelName}
              onChange={(e) => {
                props.handleInputChange(e, props.result.dateTime);
              }}
            >
              <option value={""}></option>
              {_.orderBy(props.supportedLabels).map((label) => (
                <option value={label} key={label}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="pl-3">
          <Form.Group label="Instance Count">
            <Form.Input
              type="number"
              name={`labelCount`}
              onChange={(e) => {
                props.handleInputChange(e, props.result.dateTime);
              }}
              value={props.result.labelCount}
            ></Form.Input>
          </Form.Group>
        </div>
        <div className="pl-3">
          {props.result.customLabelConfidence
            ? "Label Confidence"
            : "General Confidence"}{" "}
          {props.result.customLabelConfidence || props.result.animalConfidence}
        </div>
      </Paper>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                onClick={() => {
                  props.handlePhotoClick(step.imageUrl);
                }}
                className="pointer"
                component="img"
                sx={{
                  height: 305,
                  display: "block",
                  maxWidth: 500,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imageUrl}
                alt={step.filePath}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default PhotoCarousel;
