import { apiUrl, client } from "../modules/store";

export function getEvents(params) {
  return client.get(apiUrl + "/events", {
    params: params,
  });
}

export function getEventsForMap(params) {
  return client.get(apiUrl + "/events/map", {
    params: params,
  });
}

export function getEvent(id) {
  return client.get(apiUrl + "/events/" + id);
}

export function searchEvents(search) {
  return client.get(apiUrl + "/events/search?search=" + search);
}
export function getEventCountByFilterId(filterId) {
  let url = apiUrl + "/events/count/" + filterId;
  return client.get(url);
}

export function getEventsAttachments(params) {
  var url = `${apiUrl}/events/attachments`;

  return client
    .get(url, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}

export function getEventsExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/events/export`;

  return client.post(url, columnDefinitionDto, {
    responseType: "blob",
    params: params,
  });
}

export function createEvent(dto) {
  return client.post(apiUrl + "/events", {
    ...dto,
  });
}
export function mergeEventCorrespondence(fromId, toId) {
  return client.post(
    `${apiUrl}/events/mergeCorrespondence?fromId=${fromId}&toId=${toId}`,
    {}
  );
}

export function createEventWithAttachments(form) {
  return client.post(apiUrl + "/events/attachments/", form);
}
export function deleteEvent(id) {
  return client.delete(apiUrl + "/events/" + id);
}

export function patchEvent(id, jPatch) {
  return client.patch(apiUrl + "/events/" + id, jPatch);
}
export function createGuid() {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
}
export function bulkUpdateEvents(params, data) {
  return client.post(apiUrl + "/events/bulkUpdate", data, {
    params: params,
  });
}

export function bulkUpdateEventAttachments(params, attachments) {
  let form = new FormData();

  for (var index = 0; index < attachments.length; index++) {
    var element = attachments[index];
    form.append("file", element);
  }

  return client.post(apiUrl + "/events/bulkUpdateAttachments", form, {
    params: params,
  });
}
