import React, { useState } from "react";
import { Page } from "tabler-react";
import ResetPassword from "./reset-password";
import { validatePasswordReset } from "../../services/login";
import { LynxDialog } from "./../lynx-dialog";
import { logoutUser } from "../../actions/auth";

export function ResetForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  React.useEffect(() => {
    //Clear login token
    logoutUser();

    validatePasswordReset(props.match.params.guid)
      .then((res) => {
        setEmail(res.data.email);
      })
      .catch((err) => {
        setShowDialog(true);
      });
  }, []);

  const handleBack = () => {
    props.history.push("/");
  };

  return (
    <div className="content-wrapper">
      <Page>
        <Page.Main>
          <Page.Content>
            <ResetPassword
              handleBack={handleBack}
              passwordResetGuid={props.match.params.guid}
              email={email}
              apiUrl="/auth/password"
            ></ResetPassword>
          </Page.Content>
        </Page.Main>
      </Page>
      <LynxDialog
        open={showDialog}
        handleConfirm={handleBack}
        title={`Password Reset Error`}
        description={`This password reset link has expired. Please try resetting your password again.`}
      />
    </div>
  );
}
