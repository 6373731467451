import { getAssetLookups } from "../../../services/assets";

import { getGridSingleSelectOperators } from "@mui/x-data-grid";
import { dateUtil } from "../../../services/date-util";
import { LookupTypes } from "../../../types/enums";
export const monEventColumns = (predefinedLookups = null) => [
  {
    field: "monitoringEventNumber",
    headerName: "Event Number",
    width: 150,
    type: "string",
  },
  {
    field: "monitoringLocationNumber",
    headerName: "Location Number",
    width: 150,
    type: "singleSelect",
    lookup: "monitoringLocations",
    valueField: "id",
    labelField: "monitoringLocationNumber",
  },
  {
    field: "monitoringLocationName",
    headerName: "Location Name",
    width: 150,
    type: "singleSelect",
    lookup: "monitoringLocations",
    valueField: "id",
    labelField: "name",
    updateable: true,
    nullable: false,
    dtoId: "monitoringLocationId",
  },

  {
    field: "name",
    headerName: "Sample ID",
    width: 150,
    type: "string",
  },
  {
    field: "eventTypeName",
    headerName: "Type",
    width: 150,
    valueField: "id",
    labelField: "code",
    type: "singleSelect",
    lookup: "monitoringEventType",
    updateable: true,
    nullable: false,
    dtoId: "eventTypeId",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
    updateable: true,
    nullable: true,
  },
  {
    field: "dueDateTimeUtc",
    headerName: "Due Date",
    width: 110,
    type: "date",
    updateable: true,
    nullable: true,
    valueGetter: (params) => {
      return dateUtil.convertDateOnlyToLocal(params.value);
    },
  },
  {
    field: "assignedToUserFullName",
    headerName: "Assigned To",
    width: 150,
    type: "singleSelect",
    lookup: "users",
    lookupValues: predefinedLookups.users,
    valueField: "id",
    labelField: "fullName",
    updateable: true,
    nullable: true,
    dtoId: "assignedToUserId",
  },
  {
    field: "startDateTimeUtc",
    headerName: "Start Date",
    width: 150,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
    updateable: true,
    nullable: true,
  },
  {
    field: "endDateTimeUtc",
    headerName: "End Date",
    width: 150,
    type: "dateTime",
    updateable: true,
    nullable: false,
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    width: 150,
    type: "singleSelect",
    lookup: "assets",
    valueField: "id",
    labelField: "name",

    dtoId: "assetId",
  },
  {
    field: "isConfidential",
    headerName: "Confidential?",
    width: 200,
    type: "boolean",
    updateable: true,
    nullable: true,
  },
  {
    field: "isNonCompliant",
    headerName: "Compliance Status",
    width: 150,
    type: "singleSelect",
    valueOptions: ["Noncompliant", "Compliant"],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value !== "isAnyOf"
    ),
    renderCell: (params) => (
      <div>
        <>
          <i
            className={`fe fe-circle ${
              params.value === true ? "text-danger" : "text-success"
            }`}
          />
          &nbsp;{params.value}
        </>
      </div>
    ),
  },
];

export const monLocationColumns = [
  {
    field: "monitoringLocationNumber",
    headerName: "Number",
    width: 150,
    type: "string",
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    type: "string",
  },
  {
    field: "monitoringLocationType",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    lookupType: LookupTypes.MonitoringLocationType,
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    width: 150,
    type: "singleSelect",
    lookupFunction: getAssetLookups,
    valueField: "id",
    labelField: "name",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
  {
    field: "isWildlifeCamera",
    headerName: "Wildlife Camera",
    width: 100,
    type: "boolean",
  },
  {
    field: "isTimeSeriesLocation",
    headerName: "Timeseries Location",
    width: 100,
    type: "boolean",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
  // {
  //   field: "Add Monitoring Event",
  //   headerName: "",
  //   width: 250,
  //   type: "actions",
  //   resizable: false,
  //   disableColumnMenu: true,
  //   disableExport: true,
  //   hideSortIcons: true,
  // },
  {
    field: "Edit Monitoring Parameters and Limits",
    headerName: "",
    width: 300,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableExport: true,
    hideSortIcons: true,
    sortable: false,
  },
  {
    field: "Edit Variance Allowances",
    headerName: "",
    width: 300,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableExport: true,
    hideSortIcons: true,
    sortable: false,
  },
];
