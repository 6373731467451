import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { dateUtil } from "../../../services/date-util";
import React from "react";
import { useHistory } from "react-router-dom";

export function PermitMobileCard(props) {
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const permit = props.entity;
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) => handleNavigateTo(e, `permits/${permit.id}`)}
          >
            {permit.permitNumber}
          </Link>
        </Typography>
        <Typography color="text.secondary">
          Assets: {permit.assetNames}
        </Typography>
        <Typography color="text.secondary">
          Permit Type: {permit.permitTypeName}
        </Typography>
        <Typography color="text.secondary">
          Start Date: {dateUtil.convertDateOnlyToLocal(permit.startDate)}
        </Typography>
        <Typography color="text.secondary">
          Expiry Date: {dateUtil.convertDateOnlyToLocal(permit.expiryDate)}
        </Typography>
        <Typography color="text.secondary">
          Applied Date: {dateUtil.convertDateOnlyToLocal(permit.appliedDate)}
        </Typography>
        <Typography color="text.secondary">
          Circulated Date: {dateUtil.convertDateOnlyToLocal(permit.circulatedDate)}
        </Typography> 
      </CardContent>
    </Card>
  );
}
