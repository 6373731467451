import { apiUrl, client } from "../modules/store";

export function getParameters(params) {
  return client.get(apiUrl + "/parameters", {
    params: params,
  });
}
export function createParameter(dto) {
  return client.post(apiUrl + "/parameters", {
    ...dto,
  });
}

export function deleteParameter(id) {
  return client.delete(apiUrl + "/parameters/" + id);
}

export function patchParameter(id, jPatch) {
  return client.patch(apiUrl + "/parameters/" + id, jPatch);
}
export function getUnusedParameters(entityType, entityId) {
  return client.get(`${apiUrl}/parameters/${entityType}/${entityId}`);
}
