import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Card, Grid } from "tabler-react";

const columns = [
  { type: "string", label: "ID" },
  { type: "string", label: "Milestone" },

  { type: "date", label: "Start Date" },
  { type: "date", label: "Due Date" },
  { type: "number", label: "Duration" },
  { type: "number", label: "Percent Complete" },
  { type: "string", label: "Dependencies" },
];

const rows = [
  [
    "Phase1",
    "Phase 1",
    new Date(2021, 7, 7),
    new Date(2021, 10, 7),
    null,
    100,
    null,
  ],
  [
    "Phase2",
    "Phase 2",
    new Date(2021, 9, 19),
    new Date(2021, 11, 19),
    null,
    75,
    null,
  ],
  [
    "Phase3",
    "Phase 3",
    new Date(2021, 11, 19),
    new Date(2022, 4, 7),
    null,
    0,
    null,
  ],
];

export const data = [columns, ...rows];

export const options = {
  height: 200,
  gantt: {
    criticalPathEnabled: false,
    trackHeight: 50,
    innerGridHorizLine: {
      stroke: "#ffe0b2",
      strokeWidth: 2,
    },
    innerGridTrack: { fill: "#fff3e0" },
    innerGridDarkTrack: { fill: "#ffcc80" },
    palette: [
      {
        color: "#227c9d",
        dark: "#17c3b2",
        light: "#a2faa3",
      },
    ],
  },
};

export function ProjectDetails(props) {
  const handleEditClick = () => {};
  const editButton = () => {
    return (
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleEditClick()}
          aria-label="Edit"
          size="small"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <Grid>
      <Grid.Row className="ml-0 mr-0">
        <Grid.Col lg={12} width={12} className="">
          <h1 className="display-4 header-text">
            Westbrook Sanitation Improvement
          </h1>{" "}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="m-0">
        <Grid.Col lg={6} width={12} className="">
          <Card>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Milestone</TableCell>

                    <TableCell>Start date</TableCell>
                    <TableCell>Due date</TableCell>
                    <TableCell>Complete date</TableCell>
                    <TableCell>Percent complete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{editButton()}</TableCell>
                    <TableCell component="th" scope="row">
                      Phase 1 - Description here
                    </TableCell>

                    <TableCell>07/07/2021</TableCell>
                    <TableCell>10/07/2021</TableCell>
                    <TableCell>9/18/2021</TableCell>
                    <TableCell>
                      {" "}
                      <div>
                        <i className={"fe fe-circle text-success"} /> 100%
                      </div>{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{editButton()}</TableCell>
                    <TableCell component="th" scope="row">
                      Phase 2 - Description here
                    </TableCell>

                    <TableCell>09/19/2021</TableCell>
                    <TableCell>11/19/2021</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {" "}
                      <div>
                        <i className={"fe fe-circle text-warning"} /> 75%
                      </div>{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{editButton()}</TableCell>
                    <TableCell component="th" scope="row">
                      Phase 3 - Description here
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell>04/07/2022</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {" "}
                      <div>
                        <i className={"fe fe-circle text-danger"} /> 0%
                      </div>{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
