import React from "react";
import { Redirect, Route } from "react-router-dom";
import { loggedIn, roleMatch } from "../actions/auth";
import queryString from "query-string";
import PageNotFound from "./PageNotFound";
import { UserRoles } from "types/enums";

function PortalRoute({ component: Component, roles, path, ...rest }) {
  const redirectPath = { redirect: rest.location.pathname };
  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;
  const isPortalAdmin = loggedIn() ? roleMatch([UserRoles.PortalAdmin]) : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          loggedIn() &&
          (isPortalUser || isPortalAdmin) &&
          roles === undefined
        ) {
          return <Component {...props} />;
        }

        if (loggedIn() && (isPortalUser || isPortalAdmin) && roleMatch(roles)) {
          return <Component {...props} />;
        }
        if (
          loggedIn() &&
          (isPortalUser || isPortalAdmin) &&
          !roleMatch(roles)
        ) {
          return <PageNotFound />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/wyloo",
                state: { from: props.location },
                search: `?${queryString.stringify(redirectPath)}`,
              }}
            />
          );
        }
      }}
    />
  );
}
export default PortalRoute;
