import { apiUrl, client } from "../modules/store";
import _ from "lodash";
export function getProgram(id) {
  return client.get(apiUrl + "/programs/" + id);
}
export function getPrograms(params) {
  return client.get(apiUrl + "/programs", {
    params: params,
  });
}
export function updateProgramDetails(id, dto) {
  return client.put(apiUrl + "/programs/" + id + "/details", dto);
}
