import _ from "lodash";
import * as React from "react";
import { Icon } from "tabler-react";

export const energyTrackingCustomFieldColumns = [
  {
    field: "edit",
    headerName: " ",
    width: 45,
    type: "",
    sortable: false,
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5" name="edit" />
      </div>
    ),
  },
  {
    field: "fieldLabel",
    headerName: "Label",
    width: 200,
    type: "string",
    sortable: false,
  },
  {
    field: "fieldType",
    headerName: "Type",
    width: 200,
    type: "string",
    sortable: false,
    renderCell: (params) => <div>{_.upperFirst(_.toLower(params.value))}</div>,
  },
  {
    field: "parentLookupCode",
    headerName: "Energy Log Type",
    width: 200,
    type: "string",
    sortable: false,
  },
  {
    field: "fieldDescription",
    headerName: "Description",
    width: 250,
    type: "string",
    sortable: false,
  },
  {
    field: "fieldName",
    headerName: "Name",
    width: 250,
    type: "string",
    sortable: false,
  },
  {
    field: "isRequired",
    headerName: "Is Required?",
    width: 200,
    type: "string",
    sortable: false,
  },
  {
    field: "isDeleted",
    headerName: "Is Inactive?",
    width: 200,
    type: "string",
    sortable: false,
  },
];
