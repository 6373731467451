import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import React from "react";
import { dateUtil } from "../../../services/date-util";
import { useSelector } from "react-redux";
const na = "-";
export function EventDetails(props) {
  var organization = useSelector((state) => state.organization);
  return (
    <div className="w-100  d-flex justify-content-center">
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardHeader
          className="lynx-card-header"
          title="Event Details"
          action={
            props.eventLocked ? (
              <LockIcon
                className="mt-3 mr-3"
                color="disabled"
                fontSize="small"
              />
            ) : (
              <Button
                onClick={() => props.handleEdit("details")}
                disabled={props.eventLocked}
                id="editEventDetailsBtn"
              >
                Edit
              </Button>
            )
          }
        />

        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={organization.hasPermitFeature ? 6 : 12}>
              {organization.multipleAssetEvents ? (
                <>
                  <div className="details-label-text">Associated Assets</div>
                  <div className="details-value-text">
                    {props.event.assetNames}
                  </div>
                </>
              ) : (
                <>
                  <div className="details-label-text">Asset</div>

                  <div className="details-value-text">
                    {props.event.assetName}
                  </div>
                </>
              )}
            </Grid>
            {organization.hasPermitFeature && (
              <Grid item xs={12} sm={6}>
                <div className="details-label-text">Associated Permits</div>

                <div className="details-value-text">
                  {props.event.permitNames || na}
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Event Category</div>

              <div className="details-value-text">
                {props.event.eventCategory}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Event Subcategory</div>

              <div className="details-value-text">
                {props.event.eventSubCategory || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Due Date</div>

              <div className="details-value-text">
                {dateUtil.convertDateOnlyToLocal(
                  props.event.eventDueDateTime
                ) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Completed Date</div>

              <div className="details-value-text">
                {dateUtil.convertDateTimeToLocal(props.event.eventDateTime) ||
                  na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ overflowWrap: "break-word" }}>
              <div className="details-label-text">Assigned To</div>

              <div className="details-value-text" variant="div">
                {props.event.assignedToUserFullNames || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Urgency</div>

              <div className="details-value-text">
                {props.event.urgency || na}
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className="details-label-text">Description</div>

              <div className="details-value-text">
                {props.event.description || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Follow up required?</div>

              <div className="details-value-text">
                {props.event.followUpRequired == true ? "Yes" : "No"}
              </div>
            </Grid>
            {props.event.followUpRequired && (
              <>
                <Grid item xs={12} sm={6}>
                  <div className="details-label-text">Follow Up Date</div>

                  <div className="details-value-text">
                    {dateUtil.convertDateOnlyToLocal(
                      props.event.followUpDate
                    ) || na}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="details-label-text">
                    Follow Up Description
                  </div>

                  <div className="details-value-text">
                    {props.event.followUpDescription || na}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Divider className="mt-2 mb-2" />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Created Date</div>

              <div className="details-value-text">
                {dateUtil.convertDateTimeToLocal(
                  props.event.createdDateTimeUtc
                ) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Created By</div>

              <div className="details-value-text">
                {props.event.createdByFullName || na}
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Last Modified Date</div>

              <div className="details-value-text">
                {dateUtil.convertDateTimeToLocal(
                  props.event.lastModifiedDateTimeUtc
                ) || na}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="details-label-text">Last Modified By</div>

              <div className="details-value-text">
                {props.event.lastModifiedByFullName || na}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
