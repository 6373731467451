import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { getIncidentExportAsXLSX, getIncidents } from "../../../services/incident-service";
import { getIncidentLookups } from "../../../services/lookup";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { incidentColumns } from "./incident-columns";
import { LynxTour } from "./../../lynx-tour";
import { incidentGridTourSteps } from "../../../tours/incident-grid-tour";
import { TourTypes } from "../../../types/enums";
import { IncidentMobileCard } from "./incident-mobile-card";
import { useSelector } from "react-redux";
export function IncidentsList(props) {
  const [columns, setColumns] = useState([]);
  const handleIncidentNumberClick = (row) => {
    props.history.push(`/incidents/${row.id}?view=incident`);
  };
  const navigateToAddIncident = () => {
    props.history.push(`/submit-incident`);
  };
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };
  
  useEffect(() => {
    let newColumns = [...incidentColumns(predefinedLookups)];
    let colIndex = newColumns.findIndex((x) => x.field == "incidentNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="incident-number-button"
          onClick={() => handleIncidentNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [incidentColumns]);

  const getTour = () => {
    return (
      <LynxTour
        title={"Welcome to the Incidents Grid!"}
        description={
          "Incidents are used for submitting and investigating health and safety, environmental, and community incidents. The Incident grid shows your Incidents, and provides a number of options to sort, filter, and export."
        }
        steps={incidentGridTourSteps}
        flagField={"incidentsGridTourCompletedDateTimeUtc"}
        tourType={TourTypes.IncidentsGrid}
      />
    );
  };

  return (
    <>
      <LynxDataGrid
        tour={getTour}
        enableSavedFilters
        columns={columns}
        getDataFunction={getIncidents}
        localStorageName="incidents"
        entityName="Incident"
        addButtonAction={navigateToAddIncident}
        getLookupsFunction={getIncidentLookups}
        mobileCard={IncidentMobileCard}
        handleEventsExportXLSX={getIncidentExportAsXLSX}
        {...props}
      />
    </>
  );
}
