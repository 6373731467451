import React, { useState, useEffect } from "react";
import { Layer, Source, Marker } from "react-map-gl";
import colors from "../../../modules/lynxColors";

import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
export const EventsLayer = React.forwardRef((props, ref) => {
  const [markers, setMarkers] = useState([]);
  const [markersOnScreen, setMarkersOnScreen] = useState([]);

  useEffect(() => {
    if (!ref.current.loaded() && !ref.current.isSourceLoaded("events")) return;
    updateMarkers(props.eventLegendKey);
  }, [props.eventLegendKey]);

  const getPaintExpression = () => {
    if (props.eventLegendKey == "Status") {
      return [
        "match",
        ["get", "status"],
        "Complete",
        lynxColors.primary,
        "Incomplete",
        lynxColors.success,
        "Incomplete (Overdue)",
        lynxColors.error,
        "Pending Review",
        lynxColors.harvestOrange,
        "Pending Actions",
        lynxColors.harvestOrange,
        lynxColors.primary,
      ];
    }
    if (props.eventLegendKey == "Urgency") {
      return [
        "match",
        ["get", "urgency"],
        "Low",
        lynxColors.success,
        "Medium",
        lynxColors.warning,
        "High",
        lynxColors.warning,
        colors.primary,
      ];
    }
  };

  function updateMarkers(eventLegendKey) {
    const features = ref.current.querySourceFeatures("events");

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    let markersOnScreenIds = [];
    for (const feature of features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;
      if (!props.cluster) continue;
      const id = props.cluster_id;
      markersOnScreenIds = [id, ...markersOnScreenIds];
      setMarkers((markers) => {
        let existingMarkers = markers;
        let marker = existingMarkers.find(
          (x) => x.id == id && x.key == eventLegendKey
        );
        if (!marker) {
          const el = createDonutChart(props, eventLegendKey);
          marker = {
            id: id,
            longitude: coords[0],
            latitude: coords[1],
            element: el,
            key: eventLegendKey,
            props: feature,
          };
          return [...markers, marker];
        } else {
          return markers;
        }
      });
    }

    setMarkersOnScreen((existingMarkers) => {
      if (!_.isEqual(existingMarkers, markersOnScreenIds)) {
        return markersOnScreenIds;
      } else {
        return existingMarkers;
      }
    });
  }

  // after the GeoJSON data is loaded, update markers on the screen on every frame
  ref &&
    ref.current &&
    ref.current.on("render", () => {
      if (!ref.current.isSourceLoaded("events")) return;

      updateMarkers(props.eventLegendKey);
    });

  const handleClusterClick = (clusterId) => {
    let clusterSource = ref.current.getSource("events");
    clusterSource.getClusterLeaves(
      clusterId,
      999999,
      0,
      function (err, features) {
        props.handleClusterClick(features);
      }
    );
  };

  return (
    <Source
      type="geojson"
      cluster={true}
      clusterMaxZoom={20}
      clusterRadius={50}
      clusterProperties={{
        completedEvents: [
          "+",
          ["case", ["==", ["get", "status"], "Complete"], 1, 0],
        ],
        incompleteEvents: [
          "+",
          ["case", ["==", ["get", "status"], "Incomplete"], 1, 0],
        ],
        pendingReviewEvents: [
          "+",
          ["case", ["==", ["get", "status"], "Pending Review"], 1, 0],
        ],
        overdueEvents: [
          "+",
          ["case", ["==", ["get", "status"], "Incomplete (Overdue)"], 1, 0],
        ],
        highEvents: ["+", ["case", ["==", ["get", "urgency"], "High"], 1, 0]],
        mediumEvents: [
          "+",
          ["case", ["==", ["get", "urgency"], "Medium"], 1, 0],
        ],
        lowEvents: ["+", ["case", ["==", ["get", "urgency"], "Low"], 1, 0]],
        noEvents: ["+", ["case", ["==", ["get", "urgency"], ""], 1, 0]],
      }}
      data={createEventGeojson(props.events)}
      id="events"
    >
      <Layer
        type="circle"
        filter={["!=", "cluster", true]}
        paint={{
          "circle-color": getPaintExpression(),
        }}
        id="internalEvents"
        layout={{ visibility: "visible" }}
      />
      {!_.isEmpty(markers) &&
        markers
          .filter(
            (x) =>
              markersOnScreen.includes(x.id) && x.key == props.eventLegendKey
          )
          .map((x) => (
            <Marker
              key={x.id}
              longitude={x.longitude}
              latitude={x.latitude}
              anchor="center"
              onClick={() => handleClusterClick(x.id)}
            >
              <div dangerouslySetInnerHTML={{ __html: x.element }}></div>
            </Marker>
          ))}
    </Source>
  );
});
function createDonutChart(props, eventLegendKey) {
  const offsets = [];
  const counts =
    eventLegendKey == "Status"
      ? [
          props.completedEvents,
          props.incompleteEvents,
          props.pendingReviewEvents,
          props.overdueEvents,
        ]
      : [props.highEvents, props.mediumEvents, props.lowEvents, props.noEvents];
  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }
  const fontSize =
    total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
  const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div class="pointer">
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      eventLegendKey == "Status"
        ? [
            lynxColors.primary,
            lynxColors.success,
            lynxColors.harvestOrange,
            lynxColors.error,
          ][i]
        : [
            lynxColors.error,
            lynxColors.warning,
            lynxColors.success,
            colors.primary,
          ][i]
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
  ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

  return html;
}

function donutSegment(start, end, r, r0, color) {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
    r + r * y0
  } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1} ${
    r + r0 * y1
  } A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
    r + r0 * y0
  }" fill="${color}" />`;
}
function createEventGeojson(events) {
  let geojson = {
    type: "FeatureCollection",
    features: events.map((event) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [event.longitude, event.latitude],
      },
      properties: {
        ...event,
      },
    })),
  };
  return geojson;
}
