import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Container, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MobileStepper from "@mui/material/MobileStepper";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import {
  downloadImage,
  getObservations,
  getObservationStats,
} from "services/organization-portal-service";
import { Grid } from "tabler-react";
import { MonitoringLocationPortalTypes } from "../../../types/enums";
import { BadgeWidget } from "../../dashboards/dashboards/widgets/badge-widget";
import { WylooMap } from "./wyloo-map";
import { wylooColors, wylooTheme } from "./wyloo-theme";
import { loggedIn } from "actions/auth";
import { WylooLoginRegister } from "./wyloo-login-register";
import { useSelector } from "react-redux";
import PageNotFound from "components/PageNotFound";
export function WylooMicrositeWildlife(props) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [photosLoading, setPhotosLoading] = useState(false);
  const [filePaths, setFilePaths] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showLoginRegister, setShowLoginRegister] = useState(false);
  const [stats, setStats] = useState({});
  const [observationResponse, setObservationResponse] = useState([]);
  const history = useHistory();
  const portal = useSelector((state) => state.portal);
  useEffect(() => {
    getObservationStats(window.location.href).then((res) => {
      setStats(res.data);
    });
  }, []);

  const handleFeatureClick = (feature) => {
    feature = feature.properties;
    if (feature && feature.id) {
      setActiveStep(0);
      setPhotosLoading(true);
      setSelectedLocation(feature);
      getObservations(feature.id, window.location.href).then((res) => {
        let paths = [];
        setObservationResponse(res.data);
        res.data.forEach((data) => {
          paths.push(...data.filePaths);
        });
        var objToSet = [];
        paths.slice(0, 20).forEach((path) => {
          objToSet.push({ path: path, url: null });
        });
        if (!_.isEmpty(objToSet)) {
          downloadImage(objToSet[0].path, window.location.href).then((res) => {
            objToSet[0].url = URL.createObjectURL(res.data);
            setFilePaths(objToSet);
            setPhotosLoading(false);
          });
        } else {
          setFilePaths([]);
          setPhotosLoading(false);
        }
      });
    }
  };

  const handleNext = () => {
    var newStep = activeStep + 1;
    if (newStep > 1 && !loggedIn()) {
      setShowLoginRegister(true);
    } else {
      setActiveStep(newStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!_.isEmpty(filePaths)) {
      let newPaths = [...filePaths];
      setPhotosLoading(true);
      if (filePaths[activeStep].url == null) {
        downloadImage(filePaths[activeStep].path, window.location.href).then(
          (res) => {
            newPaths[activeStep].url = URL.createObjectURL(res.data);
            setPhotosLoading(false);
            setFilePaths(newPaths);
          }
        );
      } else {
        setPhotosLoading(false);
      }
    }
  }, [activeStep]);

  const getSpeciesFromPath = (path) => {
    var object = observationResponse.find((o) =>
      o.filePaths.includes(path.path)
    );
    if (object && object.observation) {
      return object.observation.substring(object.observation.indexOf(" ") + 1);
    } else return "";
  };
  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <div className="header-container-wyloo" style={{}}>
            <img
              alt="wyloo-header-image"
              className="wyloo-header-image"
              src="https://wyloo.com/wp-content/uploads/2024/03/Mincor-220906-016-64.png"
            ></img>
            <div className="wyloo-header-text">
              <Typography variant="h2">
                <IconButton
                  onClick={(e) => navigateTo(history, "/wyloo/", e)}
                  variant="outlined"
                  className="mr-5"
                  sx={{ color: "white", border: "3px solid white" }}
                  size="large"
                >
                  <ArrowBackIcon />
                </IconButton>
                {`Wildlife Monitoring`}
              </Typography>
            </div>
          </div>
          <Container maxWidth="xl" className="mb-5">
            {/* <div className="flex-column d-flex wyloo-text-container">
            <div className="wyloo-text-section mb-1 w-75 text-center">
              <Typography variant="h4" sx={{ width: "fit-content" }}>
                Monitoring wildlife to ensure sustainable operations that
                protect and preserve local ecosystems for future generations
              </Typography>
            </div>
          </div>
          <WylooMenu {...props} /> */}
            <Grid className="">
              <Grid.Row className="mt-5">
                <Grid.Col width={6}>
                  <div
                    className="d-flex mb-5 "
                    style={{ justifyContent: "space-between" }}
                  >
                    <BadgeWidget
                      className="mr-2 portal-badge"
                      title={"Wildlife Observations"}
                      value={stats.totalSampleEvents}
                      subValue={`Between ${moment(stats.firstSampleDate).format(
                        "MMM YYYY"
                      )} and ${moment(stats.lastSampleDate).format(
                        "MMM YYYY"
                      )}`}
                    />
                    <BadgeWidget
                      className="portal-badge"
                      title={"Unique Species Observed"}
                      value={stats.distinctParametersSampled}
                      subValue={`On trail cameras around site`}
                    />
                  </div>
                  <div className="wyloo-text-section mb-5">
                    <Typography variant="h6" sx={{ width: "fit-content" }}>
                      Observed species include: {stats.speciesObserved}
                    </Typography>
                  </div>
                </Grid.Col>
                <Grid.Col width={6}>
                  <div className="wyloo-text-section ">
                    <Typography sx={{ width: "fit-content" }}>
                      Lorem ipsum odor amet, consectetuer adipiscing elit.
                      Pharetra elit dignissim leo, massa vivamus fusce ac. Fusce
                      et facilisi venenatis elit sed penatibus fames. Hac
                      penatibus parturient scelerisque tristique taciti fames
                      nisl volutpat. Fermentum dui fringilla quam aliquam
                      viverra. Pulvinar tincidunt bibendum quam velit efficitur
                      cras tempor. Malesuada rhoncus lectus vestibulum quis mus,
                      orci purus tellus himenaeos. Ultrices arcu interdum ac non
                      lectus convallis sit porttitor commodo. Volutpat velit
                      metus lobortis feugiat malesuada.
                    </Typography>
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-5">
                <Grid.Col width={6}>
                  <WylooMap
                    handleFeatureClick={handleFeatureClick}
                    portalTypes={[MonitoringLocationPortalTypes.Wildlife]}
                  />{" "}
                </Grid.Col>
                <Grid.Col width={6}>
                  <Box
                    sx={{
                      alignSelf: "center",
                      justifySelf: "center",
                      height: 650,
                    }}
                  >
                    {!_.isEmpty(filePaths) && (
                      <div
                        className="wyloo-text-section "
                        style={{
                          borderRadius: "25px 25px 0px 0px",
                          height: "50px",
                        }}
                      >
                        {!_.isEmpty(filePaths) && (
                          <Typography variant="h6">
                            Species: {getSpeciesFromPath(filePaths[activeStep])}
                          </Typography>
                        )}
                      </div>
                    )}
                    {_.isEmpty(selectedLocation) && (
                      <div className="wyloo-text-section">
                        <Typography variant="h4" className=" pl-4">
                          {"Select a camera on the map to view observations"}
                        </Typography>
                      </div>
                    )}
                    {!_.isEmpty(selectedLocation) &&
                      _.isEmpty(filePaths) &&
                      !photosLoading && (
                        <div className="wyloo-text-section">
                          <Typography variant="h4" className=" pl-4">
                            {"No observations available for this location."}
                          </Typography>
                        </div>
                      )}
                    <div key={filePaths[activeStep]}>
                      {!_.isEmpty(filePaths) && filePaths[activeStep].url ? (
                        <>
                          <Box
                            component="img"
                            sx={{
                              height: 500,
                              display: "block",
                              border: "1px solid black",
                              overflow: "hidden",
                              width: "100%",
                            }}
                            src={filePaths[activeStep].url}
                            alt={filePaths[activeStep]}
                          ></Box>
                        </>
                      ) : (
                        <>
                          {photosLoading && (
                            <Box
                              sx={{
                                height: 500,
                                backgroundColor: wylooColors.white,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderLeft: "1px solid black",
                                borderRight: "1px solid black",
                                borderBottom: "1px solid black",
                                borderTop: _.isEmpty(filePaths)
                                  ? "1px solid black"
                                  : "",
                                overflow: "hidden",
                                width: "100%",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                        </>
                      )}
                    </div>

                    {!_.isEmpty(selectedLocation) && !_.isEmpty(filePaths) && (
                      <MobileStepper
                        steps={filePaths.length}
                        position="static"
                        sx={{
                          borderRadius: "0px 0px 25px 25px",
                        }}
                        activeStep={activeStep}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === filePaths.length - 1}
                          >
                            Next
                            <KeyboardArrowRight />
                          </Button>
                        }
                        backButton={
                          <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            <KeyboardArrowLeft />
                            Back
                          </Button>
                        }
                      />
                    )}
                  </Box>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            {showLoginRegister && (
              <WylooLoginRegister
                isForWildlife
                open={showLoginRegister}
                handleClose={() => {
                  setShowLoginRegister(false);
                }}
              />
            )}
          </Container>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
