import { apiUrl, client } from "../modules/store";

export function getAssetLookups(showAll = false) {
  return client.get(apiUrl + "/assets/lookup?showAll=" + showAll);
}

export function getTypeLookups() {
  return client.get(apiUrl + "/assets/assetTypes");
}

export function getAssets(params) {
  return client.get(apiUrl + "/assets", {
    params: params,
  });
}

export function getAsset(id, includeLocation = true) {
  let url = apiUrl + "/assets/" + id;
  if (!includeLocation) {
    url += "?includeLocation=false";
  }

  return client.get(url);
}

export function createAsset(dto) {
  return client.post(apiUrl + "/assets", {
    ...dto,
  });
}

export function updateAssetLocation(id, dto) {
  return client.patch(apiUrl + `/assets/${id}/location`, {
    ...dto,
  });
}

export function deleteAsset(id) {
  return client.delete(apiUrl + "/assets/" + id);
}

export function patchAsset(id, dto, updateLocation) {
  return client.patch(
    `${apiUrl}/assets/${id}?updateLocation=${updateLocation}`,
    dto
  );
}

export function getAssetLayerById(id) {
  return client.get(apiUrl + "/assets/" + id + "/geojson");
}

export function getAssetLayers() {
  return client.get(apiUrl + "/assets/geojson");
}

export function getAssetExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/assets/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
