import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useRef, useState } from "react";
import "./LynxDataGrid.css";

import moment from "moment";
export const betweenDateTimeOperator = [
  {
    label: "is between",
    value: "isBetween",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return true;
    },
    InputComponent: BetweenDateTimeOperator,
  },
];

const SUBMIT_FILTER_STROKE_TIME = 500;
function BetweenDateTimeOperator(props) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState(item.value ?? "");

  const [applying, setIsApplying] = useState(false);
  const [radio1Value, setRadio1Value] = useState(null);
  const [radio2Value, setRadio2Value] = useState(null);
  const [dateValue1, setDateValue1] = useState(null);
  const [dateValue2, setDateValue2] = useState(null);

  const dateInput1 = useRef();
  const dateInput2 = useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    if (itemValue[0] == "today") {
      setRadio1Value("today");
    }
    if (itemValue[0] != "today" && itemValue[0]) {
      setDateValue1(itemValue[0]);
      setRadio1Value("selectDate");
    }
    if (itemValue[1] == "today") {
      setRadio2Value("today");
    }
    if (itemValue[1] != "today" && itemValue[1]) {
      setDateValue2(itemValue[1]);
      setRadio2Value("selectDate");
    }
    setFilterValueState(itemValue);
  }, [item.value]);

  const handleRadio1Change = (e) => {
    setRadio1Value(e.target.value);
    if (e.target.value == "today") {
      handleFromDayFilterChange(e.target.value);
    }
  };

  const handleRadio2Change = (e) => {
    setRadio2Value(e.target.value);
    if (e.target.value == "today") {
      handleToDayFilterChange(e.target.value);
    }
  };

  const updateFilterValue = (fromDay, toDay) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([fromDay, toDay]);

    if (fromDay != null && toDay != null) {
      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: [fromDay, toDay] });
      }, SUBMIT_FILTER_STROKE_TIME);
    }
  };

  const handleFromDayFilterChange = (value) => {
    const newFromDay = value;
    updateFilterValue(newFromDay, filterValueState[1]);
  };

  const handleToDayFilterChange = (value) => {
    const newToDay = value;
    updateFilterValue(filterValueState[0], newToDay);
  };

  const handleUpdateDateValue1 = (e) => {
    setDateValue1(e.target.value);
    handleFromDayFilterChange(e.target.value);
  };

  const handleUpdateDateValue2 = (e) => {
    setDateValue2(e.target.value);
    handleToDayFilterChange(e.target.value);
  };

  return (
    <Box
      sx={{
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        width: 500,
      }}
    >
      <input
        type="datetime-local"
        ref={dateInput1}
        className="hidden-date-input"
        onChange={handleUpdateDateValue1}
        value={dateValue1}
      />
      <FormControl className="ml-2">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={radio1Value}
          onChange={handleRadio1Change}
        >
          <FormControlLabel value="today" control={<Radio />} label="Today" />
          <FormControlLabel
            value="selectDate"
            control={<Radio onClick={() => dateInput1.current?.showPicker()} />}
            label={
              dateValue1 ? (
                <div className="date-filter-display">
                  {moment(dateValue1).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                "Select Date"
              )
            }
          />
        </RadioGroup>
      </FormControl>
      and
      <input
        type="datetime-local"
        ref={dateInput2}
        className="hidden-date-input"
        onChange={handleUpdateDateValue2}
        value={dateValue2}
      />
      <FormControl className="ml-2">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={radio2Value}
          onChange={handleRadio2Change}
        >
          <FormControlLabel value="today" control={<Radio />} label="Today" />
          <FormControlLabel
            value="selectDate"
            control={<Radio onClick={() => dateInput2.current?.showPicker()} />}
            label={
              dateValue2 ? (
                <div className="date-filter-display">
                  {moment(dateValue2).local().format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                "Select Date"
              )
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
