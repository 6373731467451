import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Grid, Form } from "tabler-react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import {
  getOrganizationPortal,
  toggleOrganizationPortalStatus,
} from "../../../services/organization-portal-service";
import _ from "lodash";
import { LynxDialog } from "../../lynx-dialog";
import Tooltip from "@mui/material/Tooltip";
import alertify from "alertifyjs";
import {
  addPortalUser,
  deletePortalUser,
  getPortalUsers,
  updatePortalUser,
} from "../../../services/users";
import { validationService } from "../../../services";
import { LynxTextArea } from "../../form-controls/lynx-form-controls";
import { dateUtil } from "../../../services/date-util";
export function OrganizationPortalSettings(props) {
  const initialFormState = {
    email: "",
    emailError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    notes: "",
  };
  const history = useHistory();
  const [organizationPortal, setOrganizationPortal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showToggleWarning, setShowToggleWarning] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [formState, setFormState] = useState(initialFormState);
  const [error, setError] = useState(null);
  const [gridLoading, setGridLoading] = useState(false);
  const [createdUser, setCreatedUser] = useState(null);
  let defaultColumns = [
    {
      field: "Actions",
      headerName: "",
      filterable: false,
      sortable: false,
      width: 50,
      type: "actions",
      resizable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit User">
              <IconButton
                onClick={() => {
                  setSelectedUser(params.row);
                  setFormStateFromUser(params.row);
                  setShowUserModal(true);
                }}
                aria-label="Edit User"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      type: "string",
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      type: "string",
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 250,
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 250,
      type: "boolean",
    },
    {
      field: "registrationCompletedDateTimeUtc",
      headerName: "Registered Date",
      width: 150,
      type: "dateTime",
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
    },
  ];
  useEffect(() => {
    getOrganizationPortal()
      .then((res) => {
        setOrganizationPortal(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const setFormStateFromUser = (user) => {
    setFormState({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      notes: user.notes,
      isActive: user.isActive,
    });
  };

  const toggleStatus = () => {
    toggleOrganizationPortalStatus(!organizationPortal.isEnabled)
      .then((res) => {
        setOrganizationPortal(res.data);
        alertify.success(
          `Portal access ${
            organizationPortal.isEnabled ? "disabled" : "enabled"
          }.`
        );
      })
      .finally(() => {
        setShowToggleWarning(false);
      });
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const userForm = (
    <Grid>
      <Grid.Row>
        <Grid.Col md={12} width={12}>
          <Form.Group label="Email" isRequired>
            <Form.Input
              name="email"
              disabled={!_.isEmpty(selectedUser)}
              onChange={handleInputChange}
              value={formState.email}
              error={formState.emailError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={12} width={12}>
          <Form.Group label="First name" isRequired>
            <Form.Input
              name="firstName"
              error={formState.firstNameError}
              onChange={handleInputChange}
              value={formState.firstName}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={12} width={12}>
          <Form.Group label="Last name" isRequired>
            <Form.Input
              name="lastName"
              error={formState.lastNameError}
              onChange={handleInputChange}
              value={formState.lastName}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={12} width={12}>
          <Form.Group label="Notes (internal purposes only)">
            <LynxTextArea
              name="notes"
              onChange={handleInputChange}
              value={formState.notes}
            ></LynxTextArea>
          </Form.Group>
        </Grid.Col>

        {!_.isEmpty(selectedUser) &&
          !formState.registrationCompletedDateTimeUtc && (
            <Grid.Col>
              <div className="text-red mb-2">{`User is pending registration. Registration link: ${window.location.origin}/register/${selectedUser.registrationGuid}`}</div>
            </Grid.Col>
          )}
        {!_.isEmpty(selectedUser) && (
          <Grid.Col md={12} width={12}>
            <Form.Group>
              <Form.Checkbox
                label="Active"
                name="isActive"
                onChange={handleInputChange}
                checked={formState.isActive}
              />
            </Form.Group>
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid>
  );

  const handleSaveUser = () => {
    if (!validateDataForSave()) {
      return;
    }
    setGridLoading(true);
    var dtoToSave = { ...formState };
    if (!_.isEmpty(selectedUser)) {
      updatePortalUser(selectedUser.id, dtoToSave)
        .then((res) => {
          alertify.success("User updated successfully.");
          setCreatedUser(null);

          setShowUserModal(false);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
        .finally(() => {
          setGridLoading(false);
          setFormState(initialFormState);
        });
    } else {
      addPortalUser(dtoToSave)
        .then((res) => {
          setCreatedUser(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
        .finally(() => {
          setGridLoading(false);
          setFormState(initialFormState);
        });
    }
  };
  const validateDataForSave = (saveAction) => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateEmailValue(
      newState.email,
      newState,
      "emailError",
      true
    );

    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First name"
    );

    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last name"
    );

    let errorFields = ["emailError", "firstNameError", "lastNameError"];

    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Grid>
      {" "}
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
            <Grid.Col width={12}>
              <div className="d-flex h-100">
                <Typography
                  className="align-self-center"
                  variant="h3"
                  component="div"
                >
                  Organization Portal Settings
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/settings")}
                >
                  Settings
                </Link>

                <Typography color="text.primary">
                  Organization Portal Settings
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Portal Access
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Enable or disable portal access for all portal users.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Body>
                <Button
                  variant="contained"
                  color={organizationPortal.isEnabled ? "error" : "primary"}
                  onClick={() => setShowToggleWarning(true)}
                >
                  {organizationPortal.isEnabled ? "Disable" : "Enable"}{" "}
                  Organization Portal
                </Button>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Portal Users
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                View portal users, registration status, and enable or disable
                user access.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card className="mb-0">
              <Card.Body className="p-0">
                <Button
                  variant="outlined"
                  onClick={() => setShowUserModal(true)}
                  className="mt-2 ml-2 mb-2"
                >
                  Add Portal User
                </Button>
              </Card.Body>
            </Card>
            <div style={{ height: 630, width: "100%" }}>
              <LynxDataGrid
                columns={defaultColumns}
                getDataFunction={getPortalUsers}
                entityName={"User"}
                {...props}
                passEntityOnClick={true}
                disableAddButton={true}
                localStorageName="users"
                isForSettings
                isLoading={gridLoading}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>
      <LynxDialog
        dividers
        open={showToggleWarning}
        title={
          organizationPortal.isEnabled
            ? "Disable Portal Access?"
            : "Enable Portal Access?"
        }
        description={
          organizationPortal.isEnabled
            ? "Are you sure you want to disable portal access? This will immediately disable access for all portal users."
            : "Are you sure you want to enable portal access? This will immediately enable access for all portal users."
        }
        handleClose={() => {
          setShowToggleWarning(false);
        }}
        handleConfirm={toggleStatus}
      />
      {showUserModal && (
        <LynxDialog
          dividers
          open={showUserModal}
          title={
            _.isEmpty(selectedUser) ? "Add Portal User" : "Edit Portal User"
          }
          saveButtonText={_.isEmpty(selectedUser) ? "Add" : "Save"}
          dialogContent={userForm}
          handleClose={() => {
            setSelectedUser({});
            setFormState(initialFormState);
            setShowUserModal(false);
          }}
          handleSave={handleSaveUser}
          handleDelete={
            !_.isEmpty(selectedUser) &&
            selectedUser.registrationCompletedDateTimeUtc == null
              ? () => {
                  setGridLoading(true);
                  deletePortalUser(selectedUser.id).then(() => {
                    alertify.success("Portal user deleted.");
                    setShowUserModal(false);
                    setGridLoading(false);
                    setFormState(initialFormState);
                    setSelectedUser({});
                  });
                }
              : null
          }
        />
      )}
      {error && (
        <LynxDialog
          open={error != null}
          title={
            _.isEmpty(selectedUser) ? "Error Adding User" : "Error Saving User"
          }
          description={error}
          handleConfirm={() => {
            setError(null);
          }}
        />
      )}
      {createdUser && (
        <LynxDialog
          open={createdUser != null}
          title={"Portal User Added"}
          description={`The user will be recieve an email with the following registration link: ${window.location.origin}/register/${createdUser.registrationGuid} . You can check the portal user grid for their registration status.`}
          handleConfirm={() => {
            setCreatedUser(null);
            setFormState(initialFormState);
            setShowUserModal(false);
          }}
        />
      )}
    </Grid>
  );
}
