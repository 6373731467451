import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { Card, Dimmer, Form, Grid, Icon } from "tabler-react";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  createAsset,
  deleteAsset,
  getAsset,
  patchAsset,
} from "../../../services/assets";
import { getLookups } from "../../../services/lookup";
import { UserRoles } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LocationSelectionControl } from "../../location-selection-control";
import { UsersPermissions } from "../settings/users-permissions";

const initialForm = {
  name: "",
  assetTypeId: "",
  status: "",

  nameError: "",
  assetTypeIdError: "",
  latitude: "",
  longitude: "",
};

export function AssetForm(props) {
  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(true);

  const [assetTypeLookups, setAssetTypeLookups] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [assetGeojson, setAssetGeojson] = useState("");
  const [selector, setSelector] = useState(null);
  const [geojsonCentroid, setGeojsonCentroid] = useState([]);
  const [asset, setAsset] = useState({});
  const [locationTouched, setLocationTouched] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [usersPermissions, setUsersPermissions] = useState([]);
  const history = useHistory();
  // prettier-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  const isExistingAsset = () => {
    return props.match.params.assetId;
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  useEffect(() => {
    if (isExistingAsset()) {
      getAsset(props.match.params.assetId).then((res) => {
        setFormStateFromProps(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      setSelector("pin");
    }
  }, []);

  useEffect(() => {
    getLookups("assetType").then((res) => {
      setAssetTypeLookups(res.data);
    });
  }, []);

  const setUsersPermissionsData = (usersPermissions) => {
    setUsersPermissions(usersPermissions);
  };

  const setFormStateFromProps = (asset) => {
    setAsset(asset);
    let newState = {
      name: asset.name,
      assetTypeId: asset.assetTypeId,
      status: asset.status,
      latitude: asset.latitude,
      longitude: asset.longitude,
    };
    if (asset.latitude && asset.longitude) {
      setSelector("pin");
    } else {
      setSelector("fence");
      setAssetGeojson(asset.geojson);
      setGeojsonCentroid([asset.centroidLatitude, asset.centroidLongitude]);
    }
    setFormState(newState);
  };

  const deleteItem = () => {
    deleteAsset(props.match.params.assetId).then(() => {
      alertify.success("Asset Deleted");
      handleNavigateTo(null, "/assets");
    });
  };
  const handleDelete = () => {
    setShowDelete(true);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    validationService.validateRequiredField(
      newState,
      "assetTypeId",
      "assetTypeIdError",
      "Asset Type"
    );

    isFormValid = !validationService.hasError(
      newState,
      "assetTypeIdError",
      "nameError"
    );
    if (
      selector == "pin" &&
      (formState.latitude == "" || formState.longitude == "")
    ) {
      setLocationError("Location is required");
      isFormValid = false;
    } else {
      setLocationError("");
    }
    if (selector == "fence" && assetGeojson == "") {
      setLocationError("Location is required");
      isFormValid = false;
    } else {
      setLocationError("");
    }
    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleSave = () => {
    if (!validateDataForSave()) {
      return;
    }
    let asset = { ...formState };
    if (selector === "fence") {
      asset = {
        ...asset,
        geojson: assetGeojson,
      };
    }

    if (selector === "pin") {
      asset = {
        ...asset,
        geojson: null,
      };
    }
    if (!isExistingAsset()) {
      asset = {
        ...asset,
        usersPermissions: usersPermissions.filter(
          (up) => up.canAccess === true
        ),
      };
      createAsset(asset)
        .then(() => {
          alertify.success("Asset Added.");
          handleNavigateTo(null, "/assets");
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
          handleNavigateTo(null, "/assets");
        });
    } else {
      asset = {
        ...asset,
        usersPermissions: usersPermissions.filter(
          (up) => up.hasChanges === true
        ),
      };
      patchAsset(
        props.match.params.assetId,
        asset,
        locationTouched ? true : false
      )
        .then((res) => {
          alertify.success("Asset Saved.");
          handleNavigateTo(null, "/assets");
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };
  const handleLatLongChange = (lat, long) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };

  const handleGeojsonChange = (e) => {
    setAssetGeojson(e);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {isExistingAsset() ? asset.assetNumber : "Add Asset"}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/assets")}
                >
                  Assets
                </Link>
                <Typography color="text.primary">
                  {isExistingAsset() ? asset.assetNumber : "Add Asset"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Container className="mt-2" maxWidth="xl">
            <Form className="card ">
              <Card.Body className="p-0">
                <Grid.Row>
                  <Accordion className="w-100" defaultExpanded>
                    <AccordionSummary
                      aria-controls="details-content"
                      id="details-header"
                    >
                      <Typography>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <Grid.Col md={12} width={12}>
                          <Form.Group label="Asset Name" isRequired>
                            <Form.Input
                              type="text"
                              name="name"
                              onChange={handleInputChange}
                              value={formState.name}
                              error={formState.nameError}
                            ></Form.Input>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={12} width={12}>
                          <Form.Group isRequired label="Type">
                            <Form.Select
                              name="assetTypeId"
                              onChange={handleInputChange}
                              value={formState.assetTypeId}
                              error={formState.assetTypeIdError}
                            >
                              <option value={""}></option>
                              {assetTypeLookups.map((type) => (
                                <option value={type.id} key={type.id}>
                                  {type.code}
                                </option>
                              ))}
                              type
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={12} width={12}>
                          <Form.Group label="Status">
                            <Form.Select
                              name="status"
                              onChange={handleInputChange}
                              value={formState.status}
                            >
                              <option value={""}></option>
                              <option value={"Green"}>Green</option>
                              <option value={"Yellow"}>Yellow</option>
                              <option value={"Red"}>Red</option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="w-100" defaultExpanded>
                    <AccordionSummary
                      aria-controls="location-content"
                      id="location-header"
                    >
                      <Typography>Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <Grid.Col md={12} width={12}>
                          <Grid.Row>
                            {selector != null && (
                              <LocationSelectionControl
                                handleLocationTouched={() => {
                                  setLocationTouched(true);
                                }}
                                defaultLatitude={formState.latitude}
                                defaultLongitude={formState.longitude}
                                isRequired
                                defaultGeojsonCentroid={geojsonCentroid}
                                defaultGeojson={asset.geojson}
                                handleLatLongChange={handleLatLongChange}
                                handleGeojsonChange={handleGeojsonChange}
                                error={locationError}
                                selector={selector}
                                setSelector={setSelector}
                                hasGeocoder
                              />
                            )}
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="w-100" defaultExpanded>
                    <AccordionSummary
                      aria-controls="location-content"
                      id="permissions-header"
                    >
                      <Typography>Permissions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UsersPermissions
                        lookupType={"asset"}
                        lookupId={
                          isExistingAsset() ? props.match.params.assetId : 0
                        }
                        setUsersPermissionsData={setUsersPermissionsData}
                      ></UsersPermissions>
                    </AccordionDetails>
                  </Accordion>
                </Grid.Row>
              </Card.Body>

              <Card.Footer>
                {!isExistingAsset() && (
                  <Button
                    variant="contained"
                    className="float-right"
                    onClick={() => handleSave(formState)}
                  >
                    Add Asset
                  </Button>
                )}
                {isExistingAsset() && (
                  <div>
                    <div>
                      {roleMatch([UserRoles.Admin]) && (
                        <Button
                          color="error"
                          variant="contained"
                          className="float-left"
                          onClick={handleDelete}
                        >
                          Delete Asset
                        </Button>
                      )}
                    </div>
                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={handleSave}
                    >
                      Save Asset
                    </Button>
                  </div>
                )}
              </Card.Footer>
              <LynxDialog
                open={showDelete}
                handleClose={() => setShowDelete(false)}
                handleDelete={deleteItem}
                title={"Delete Asset?"}
                description={
                  "All associated events, and attachments will also be deleted."
                }
              />
            </Form>
          </Container>
        </Grid.Row>
      </Dimmer>
    </Grid>
  );
}
