import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";

import { getPrograms } from "../../../services/program-service";
import { EntityTypes, UserRoles } from "../../../types/enums";
import { roleMatch } from "../../../actions/auth";
export function ProgramList(props) {
  const [columns, setColumns] = useState([]);
  const handleInspectionNumberClick = (row) => {
    props.history.push(`/programs/${row.id}`);
  };

  useEffect(() => {
    if (roleMatch([UserRoles.MshaUser])) {
      columnsToSet.splice(2, 0, {
        field: "organizationName",
        headerName: "Customer",
        width: 150,
        type: "string",
      });
    }
    setColumns(columnsToSet);
  }, []);
  let columnsToSet = [
    {
      field: "name",
      headerName: "Program",
      width: 500,
      type: "string",

      renderCell: (params) => {
        return (
          <Button
            variant="text"
            className="program-number-button"
            onClick={() => handleInspectionNumberClick(params.row)}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "assetName",
      headerName: "Mine Site",
      width: 300,
      type: "string",
    },
    {
      field: "incompleteEvaluations",
      headerName: "Incomplete Evaluations",
      width: 250,
      type: "number",
    },
    {
      field: "completedEvaluations",
      headerName: "Completed Evaluations",
      width: 250,
      type: "number",
    },
    {
      field: "overdueEvaluations",
      headerName: "Overdue Evaluations",
      width: 250,
      type: "number",
    },
  ];

  return (
    <>
      <LynxDataGrid
        hasNoLookups
        columns={columns}
        title="Programs"
        getDataFunction={getPrograms}
        localStorageName="programs"
        entityName={EntityTypes.Program}
        {...props}
      />
    </>
  );
}
