import { getParameters } from "./../../../../services/parameters";
import { LookupTypes } from "./../../../../types/enums";

export const monitoringLimitColumns = [
  {
    field: "edit",
    headerName: "Edit / Delete",
    width: 100,
    type: "actions",
    sortable: false,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 150,
    type: "singleSelect",
    lookupFunction: getParameters,
    valueField: "id",
    labelField: "name",
    importable: true,
  },
  {
    field: "value",
    headerName: "Value",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "unitName",
    headerName: "Unit",
    width: 150,
    type: "singleSelect",
    lookupType: "unit",
    importable: true,
  },

  {
    field: "monitoringLimitType",
    headerName: "Type",
    width: 100,
    type: "singleSelect",
    lookupType: LookupTypes.MonitoringLimitType,
    importable: true,
  },
];
