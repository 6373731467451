import { apiUrl, client } from "../modules/store";

const url = `${apiUrl}/parameterGroups`;
export function getParameterGroups() {
  return client.get(url);
}
export function getParameterGroup(id) {
  return client.get(`${url}/${id}`);
}

export function createParameterGroup(dto) {
  return client.post(url, dto);
}

export function deleteParameterGroup(id) {
  return client.delete(`${url}/${id}`);
}

export function updateParameterGroup(id, dto) {
  return client.put(`${url}/${id}`, dto);
}
