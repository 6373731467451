import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useState } from "react";

export function FilterModal(props) {
  const [filterName, setFilterName] = useState("");
  const [existingFilter, setExistingFilter] = useState(0);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth
    >
      {!props.isLoadFilterMode ? (
        <>
          <DialogTitle>Save Filter</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              margin="dense"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              disabled={existingFilter != 0}
            />
            <Typography variant="body2" gutterBottom>
              Enter a name to save as a new filter OR select existing filter
              below
            </Typography>
            <FormControl fullWidth className="mt-2" disabled={filterName != ""}>
              <InputLabel id="filter-select-label">Existing Filter</InputLabel>
              <Select
                labelId="filter-select-label"
                id="filter-select"
                value={existingFilter}
                label="Existing Filter"
                onChange={(e) => setExistingFilter(e.target.value)}
              >
                <MenuItem value={0}>&nbsp;</MenuItem>
                {props.savedFilters.map((filter) => (
                  <MenuItem value={filter.id} key={filter.id}>
                    {filter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <Button
              onClick={() => props.handleSaveFilter(filterName, existingFilter)}
              disabled={existingFilter == 0 && filterName == ""}
            >
              Save
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Apply Saved Filter</DialogTitle>
          <DialogContent>
            {!_.isEmpty(props.selectedFilter) && (
              <>
                <Typography variant="body1" className="mb-2">
                  Current Filter: {props.selectedFilter.name}
                </Typography>
              </>
            )}
            <FormControl fullWidth className="mt-2">
              <InputLabel id="filter-select-label">Select Filter</InputLabel>
              <Select
                labelId="filter-select-label"
                id="filter-select"
                value={existingFilter}
                label="Existing Filter"
                onChange={(e) => setExistingFilter(e.target.value)}
                sx={{ minWidth: "500" }}
              >
                <MenuItem value={0}>&nbsp;</MenuItem>
                {props.savedFilters.map((filter) => (
                  <MenuItem value={filter.id} key={filter.id}>
                    {filter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            {!_.isEmpty(props.selectedFilter) && (
              <Button
                size="small"
                color="error"
                onClick={props.handleClearFilter}
              >
                Clear Filter
              </Button>
            )}

            <Button onClick={props.handleLoadFilterClose}>Cancel</Button>
            <Button
              onClick={() => props.handleLoadFilterSelected(existingFilter)}
            >
              Load
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
