import { getUserId, hasLookupPermissions } from "../actions/auth";

export class PermissionsService {
  canViewIncidentInvestigation(incident) {
    return (
      hasLookupPermissions(incident.typeId, "canPerformInitialReview") ||
      hasLookupPermissions(incident.typeId, "canPerformFinalReview") ||
      incident.investigationLeadUserId == getUserId() ||
      (incident.incidentUsers &&
        incident.incidentUsers.some((s) => s.userId == getUserId()))
    );
  }
}

export const permissionsService = new PermissionsService();
