import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form, Dimmer } from "tabler-react";
import { createEvent, deleteEvent, patchEvent } from "../../../services/events";

import _ from "lodash";
import alertify from "alertifyjs";
import { validationService } from "../../../services";
import * as jsonpatch from "fast-json-patch";
import Button from "@mui/material/Button";
import { addEntityAttachments } from "./../../../services/attachments";

const initialForm = {
  assetId: "",
  name: "",
  projectTypeId: "",
  description: "",
  estimatedCost: "",
  actualCost: "",
  startDateTime: "",
  dueDateTime: "",
  completeDateTime: "",
  projectManagerId: "",
};

export function ProjectModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [assetLookups, setAssetLookups] = useState([]);
  const [projectTypes, setProjectTypes] = useState([
    "Development",
    "Health",
    "Communications",
    "Water",
    "Sanitation",
  ]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (props.projectId > 0) {
      //   getProject(props.projectId).then((res) => {
      //     setFormStateFromProps(res.data);
      //     getEntityAttachments("event", props.eventId).then((res) => {
      //       setExistingFiles(res.data);
      //     });
      //     setIsLoading(false);
      //   });
    } else {
      setIsLoading(false);
    }

    // getLookups("projectType").then((res) => {
    //   setProjectTypes(res.data);
    // });
  }, []);

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  //   const deleteAttachment = (id) => {
  //     deleteEntityAttachment(id).then((res) => {
  //       getEntityAttachments("event", props.eventId).then((res) => {
  //         setExistingFiles(res.data);
  //         alertify.success("Attachment Deleted");
  //       });
  //     });
  //   };

  const setFormStateFromProps = (event) => {
    let newState = {
      assetId: event.assetId,
      eventDateTime: event.eventDateTime,
      eventCategoryId: event.eventCategoryId,
      eventSubCategoryId: event.eventSubCategoryId,
      eventStatus: event.eventStatus,
      description: event.description,
      actorName: event.actorName,
      actorDescription: event.actorDescription,
      numberOfInjuries: event.numberOfInjuries,
      injuriesType: event.injuriesType,
      numberOfFatalities: event.numberOfFatalities,
      causeOfFatalities: event.causeOfFatalities,
      location: event.location,
      locationTypeId: event.locationTypeId,
      severity: event.severity,
      latitude: event.latitude,
      longitude: event.longitude,
      urgency: event.urgency,
    };

    setFormState(newState);
    setInitialFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "eventDateTimeError",
      "eventStatusError",
      "eventCategoryIdError",
      "eventSubCategoryIdError",
      "assetIdError",
      "locationError",
      "locationTypeIdError",
      "severityError"
    );
    if (props.eventId <= 0) {
      createEvent(formToSave).then((res) => {
        if (!_.isEmpty(uploadedFiles)) {
          addEntityAttachments("event", res.data.id, uploadedFiles).then(
            (res) => {}
          );
        }
        alertify.success("Event Created");
        props.handleModalClose(true);
      });
    }
    if (props.eventId > 0) {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      patchEvent(props.eventId, diff).then((res) => {
        if (!_.isEmpty(uploadedFiles)) {
          addEntityAttachments("event", props.eventId, uploadedFiles).then(
            (res) => {}
          );
        }
        alertify.success("Event Saved");
        props.handleModalClose(true);
      });
    }
  };

  const deleteItem = () => {
    deleteEvent(props.eventId).then(() => {
      alertify.success("Project Deleted");
      props.handleModalClose(true);
    });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "eventDateTime",
      "eventDateTimeError",
      "Event Date"
    );

    validationService.validateRequiredField(
      newState,
      "eventStatus",
      "eventStatusError",
      "Event Status"
    );
    validationService.validateRequiredField(
      newState,
      "eventCategoryId",
      "eventCategoryIdError",
      "Event Category"
    );

    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    isFormValid = !validationService.hasError(
      newState,
      "eventDateTimeError",
      "eventStatusError",
      "eventCategoryIdError",
      "eventSubCategoryIdError",
      "assetIdError",
      "locationTypeIdError",
      "locationError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  //   const downloadAttachment = (id, fileName) => {
  //     downloadEntityAttachment(id).then((res) => {
  //       saveAs(res.data, fileName);
  //     });
  //   };
  //   const handleUploadClick = (event) => {
  //     hiddenFileInput.current.click();
  //   };

  //   const handleFileChange = (event) => {
  //     const selectedFiles = event.target.files;
  //     setUploadedFiles([...uploadedFiles, ...selectedFiles]);
  //   };
  //   const hiddenFileInput = useRef(null);

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          {props.eventId > 0 ? "Edit Project" : "Add Project"}

          <Icon
            name="x"
            onClick={props.handleModalClose}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Dimmer active={isLoading} loader>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Asset">
                <Form.Select
                  name="assetId"
                  onChange={handleInputChange}
                  value={formState.assetId}
                  error={formState.assetIdError}
                  disabled={props.assetDisabled ? true : false}
                >
                  <option value={""}></option>
                  {assetLookups.map((asset) => (
                    <option value={asset.id} key={asset.id}>
                      {asset.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group isRequired label="Type">
                <Form.Select
                  name="projectTypeId"
                  value={formState.projectTypeId}
                  onChange={handleInputChange}
                  error={formState.projectTypeIdError}
                >
                  <option value={""}></option>
                  {projectTypes.map((type) => (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={6} width={12}>
              <Form.Group isRequired label="Start Date">
                <Form.Input
                  type="datetime-local"
                  value={formState.startDateTime}
                  name="startDateTime"
                  onChange={handleInputChange}
                  error={formState.startDateTimeError}
                />
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={12} width={12}>
              <Form.Group label="Description">
                <Form.Textarea
                  name="description"
                  onChange={handleInputChange}
                  value={formState.description}
                ></Form.Textarea>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {props.projectId <= 0 && (
          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            Add Project
          </Button>
        )}
        {props.projectId > 0 && (
          <div>
            <Button
              color="error"
              className="float-left"
              variant="contained"
              onClick={deleteItem}
            >
              Delete Project
            </Button>

            <Button
              variant="contained"
              className="float-right"
              onClick={saveForm}
            >
              Save Project
            </Button>
          </div>
        )}
      </Card.Footer>
    </Form>
  );
}
