export const mshaCustomerColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    type: "string",
  },
  {
    field: "description",
    headerName: "Notes",
    width: 150,
    type: "string",
  },
  {
    field: "registeredUsersCount",
    headerName: "# Registered Users",
    width: 150,
    type: "number",
  },
  {
    field: "usersPendingRegistrationCount",
    headerName: "# Users Pending Registration",
    width: 150,
    type: "number",
  },
];
