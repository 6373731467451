import React, { useState } from "react";
import { Grid, Form, Card } from "tabler-react";
import { apiUrl, client } from "./../../modules/store";
import alertify from "alertifyjs";
import Button from "@mui/material/Button";
import _ from "lodash";
import { validatePasswordReset } from "./../../services/login";
import { LynxDialog } from "./../lynx-dialog";
var passwordValidator = require("password-validator");
var schema = new passwordValidator();
schema
  .is()
  .min(6) // Minimum length 8
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits()
  .has()
  .symbols() // Must have at least 2 digits
  .has()
  .not()
  .spaces(); // Should not have spaces
  
export default function ResetPassword(props) {
    const [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        passwordErrorList: [],
      });      
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMsg, setDialogMsg] = useState("");

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name == "newPassword") {
      setState({
        ...state,
        passwordErrorList: schema.validate(value, { details: true }),
        [name]: value,
      });
    }
    else{
        setState({
            ...state,
            [name]: value,
        });
    }
  }

  const changePassword = () => {
    if (props.requireCurrentPassword && state.currentPassword === "") {
      alertify.error("Please enter Current Password");
      return;
    }
    if (state.newPassword === "") {
      alertify.error("Please enter New Password");
      return;
    }

    if (state.newPassword !== state.newPasswordConfirm) {
      alertify.error("Passwords do not match");
      return;
    }

    if (props.passwordResetGuid){
        //First check password reset guid and time stamp within 30 minutes (also does this on page load from forgot password email page)
        validatePasswordReset(props.passwordResetGuid).then((res) => {        
            callPasswordReset();
          })
          .catch((err) => {
            setShowDialog(true);
            setState({
              currentPassword: "",
              newPassword: "",
              newPasswordConfirm: "",
            });
            setDialogMsg(err.response.data.message);
          });
    }
    else{
        callPasswordReset();
    }

  }

  const callPasswordReset = () => {
    client
      .put(apiUrl + props.apiUrl, {
        oldPassword: state.currentPassword,
        newPassword: state.newPassword,
        email: props.email
      })
      .then((res) => {
        alertify.success("Password Reset");
        props.handleBack();
      })
      .catch((error) => {
        setShowDialog(true);
        setState({
          currentPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        });
        setDialogMsg(error.response.data.message);
      });
  };
    return (
        <Form className="card">
        <Card.Body>
          <Card.Title>
            Reset Password
            <Button
              variant="contained"
              onClick={ props.handleBack }
              color="neutral"
              className="ml-2 float-right"
            >
              Back
            </Button>
          </Card.Title>
          <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group label="Email">
              <Form.Input
                disabled
                name="email"
                value={props.email}
              ></Form.Input>
            </Form.Group>
          </Grid.Col>
          </Grid.Row>

        {props.requireCurrentPassword && (
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group>
                <Form.Label>Current Password</Form.Label>
                <Form.Input
                  type="password"
                  value={state.currentPassword}
                  name="currentPassword"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row> 
          )}
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Input
                  type="password"
                  value={state.newPassword}
                  name="newPassword"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            {!_.isEmpty(state.passwordErrorList) && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  {state.passwordErrorList.map((err) => (
                    <li className="text-danger" key={err.message}>
                      {err.message}
                    </li>
                  ))}
                </Form.Group>
              </Grid.Col>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group>
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Input
                  type="password"
                  value={state.newPasswordConfirm}
                  name="newPasswordConfirm"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            {!_.isEmpty(state.newPasswordConfirm) &&
              state.newPassword !=
                state.newPasswordConfirm && (
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <li className="text-danger">
                      Password does not match
                    </li>
                  </Form.Group>
                </Grid.Col>
              )}
          </Grid.Row>
        </Card.Body>
        <Card.Footer className="text-right">
          <Button
            variant="contained"
            onClick={changePassword}
            disabled={
              (props.requireCurrentPassword && state.currentPassword == "") ||
              state.newPassword == "" ||
              state.newPasswordConfirm == "" ||
              !_.isEmpty(state.passwordErrorList) ||
              state.newPassword !=
                state.newPasswordConfirm
            }
          >
            Update Password
          </Button>
        </Card.Footer>
        <LynxDialog
           open={showDialog}
           handleConfirm={() => setShowDialog(false)}
           title={`Password Reset Error`}       
           description= {dialogMsg}
         />    
      </Form>
      
    );
 
}

