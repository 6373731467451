import React from "react";
import { Layer, Source } from "react-map-gl";
import colors from "../../../modules/lynxColors";
export function AssetLayer(props) {
  const getLayerColor = () => {
    switch (props.asset.status) {
      case "Red":
        return colors.error;
      case "Yellow":
        return colors.warning;
      case "Green":
        return colors.success;
      default:
        return colors.primary;
    }
  };
  return (
    <>
      <Source type="geojson" data={JSON.parse(props.asset.geojson)}>
        <Layer
          type="fill"
          filter={["==", "$type", "Polygon"]}
          paint={{
            "fill-outline-color": getLayerColor(),
            "fill-color": getLayerColor(),
            "fill-opacity": 0.5,
          }}
          layout={{ visibility: "visible" }}
        />
      </Source>
      <Source type="geojson" data={JSON.parse(props.asset.geojson)}>
        <Layer
          type="line"
          filter={["==", "$type", "LineString"]}
          paint={{
            "line-color": getLayerColor(),
          }}
          layout={{ visibility: "visible" }}
        />
      </Source>
      <Source type="geojson" data={JSON.parse(props.asset.geojson)}>
        <Layer
          type="circle"
          filter={["==", "$type", "Point"]}
          paint={{
            "circle-color": getLayerColor(),
            "circle-opacity": 0.5,
            "circle-radius": 7,
          }}
          layout={{ visibility: "visible" }}
        />
      </Source>
    </>
  );
}
