import { apiUrl, client } from "../modules/store";
import _ from "lodash";
import queryString from "query-string";
export function downloadWord(html) {
  return client.post(
    `${apiUrl}/reports`,
    { html: _.toString(html) },
    {
      responseType: "blob",
    }
  );
}

export function getReports(params) {
  return client.get(apiUrl + "/reports", {
    params: params,
  });
}

export function runReportById(
  id,
  filter,
  eventId,
  parentLookupId,
  childLookupId
) {
  var url = `${apiUrl}/reports/${id}`;
  var qs = {};
  if (filter) {
    qs = { filter: filter, ...qs };
  }
  if (eventId) {
    qs = { eventId: eventId, ...qs };
  }
  if (parentLookupId) {
    qs = { parentLookupId: parentLookupId, ...qs };
  }
  if (childLookupId) {
    qs = { childLookupId: childLookupId, ...qs };
  }
  url = url + "?" + queryString.stringify(qs);
  return client.get(url, {
    responseType: "blob",
  });
}
export function runIncidentReport(id, reportType) {
  var url = `${apiUrl}/reports/incident/${id}?reportType=${reportType}`;

  return client.get(url, {
    responseType: "blob",
  });
}
