import React, { useState } from "react";
import { saveAs } from "file-saver";
import { reportColumns } from "./Columns";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import Paper from "@mui/material/Paper";
import { downloadWord, getReports } from "../../../services/reports-service";
export function ReportsList(props) {
  return (
    <>
      <LynxDataGrid
        columns={reportColumns}
        getDataFunction={getReports}
        localStorageName="reports"
        hasEditButton={false}
        entityName={"Report"}
        {...props}
      />
    </>
  );
}
