import * as React from "react";
import Button from "@mui/material/Button";
import { saveAs } from "file-saver";
import { runReportById } from "../../../services/reports-service";

const downloadReport = (params) => {
  const id = params.id;
  runReportById(id).then((res) => {
    saveAs(res.data, params.row.name);
  });
};

// const navigateToHistory = (params) => {
//   history.push("/dashboard/reports/" + params.id);
// };
export const reportColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 350,
    type: "string",
  },

  {
    field: "description",
    headerName: "Description",
    width: 400,
    type: "string",
  },

  {
    field: "runReport",
    headerName: "",
    width: 150,
    type: "string",

    renderCell: (params) => (
      <Button onClick={() => downloadReport(params)} variant="outlined">
        Run Report
      </Button>
    ),
  },
];
