import { apiUrl, client } from "../modules/store";

export function getLookups(lookupType, bypassFilter = false) {
  return client.get(
    apiUrl +
      "/lookups?lookupType=" +
      lookupType +
      "&bypassFilter=" +
      bypassFilter
  );
}

export function getMultipleLookups(lookupTypes, bypassFilter = false) {
  return client.get(
    apiUrl +
      "/lookups/list?lookupTypes=" +
      lookupTypes +
      "&bypassFilter=" +
      bypassFilter
  );
}

export function getEventLookups() {
  return client.get(apiUrl + "/lookups/event");
}
export function getMonitoringEventLookups() {
  return client.get(apiUrl + "/lookups/monitoringEvent");
}
export function getActionLookups() {
  return client.get(apiUrl + "/lookups/action");
}

export function getIncidentLookups() {
  return client.get(apiUrl + "/lookups/incident");
}

export function getEnergyLogLookups() {
  return client.get(apiUrl + "/lookups/energylog");
}

export function getPermitLookups() {
  return client.get(apiUrl + "/lookups/permit");
}

export function getLookupsByParentId(id) {
  return client.get(apiUrl + "/lookups/children/" + id);
}

export function getLookup(id) {
  return client.get(apiUrl + "/lookups/" + id);
}

export function createLookup(dto) {
  return client.post(apiUrl + "/lookups", {
    ...dto,
  });
}

export function deleteLookup(id) {
  return client.delete(apiUrl + "/lookups/" + id);
}

export function patchLookup(id, dto) {
  return client.patch(apiUrl + "/lookups/" + id, dto);
}

export function updateEventCompliance(eventLookupId, complianceLookupIds) {
  return client.put(apiUrl + "/lookups/compliance", {
    lookupId: eventLookupId,
    complianceLookupIds: complianceLookupIds,
  });
}
