import FactoryIcon from "@mui/icons-material/Factory";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer, Grid, Icon } from "tabler-react";
import { getEvent } from "../../../services/events";
import { getCustomFields } from "../../../services/custom-fields-service";
import {
  downloadAttachment as downloadEntityAttachment,
  getEntityAttachments,
} from "./../../../services/attachments";

import { urgencyColorSwitch } from "./event-columns";
import { getStatusChip } from "./event-details-container";
import "./event-quick-view.css";
import { dateUtil } from "../../../services/date-util";
import { EntityTypes } from "../../../types/enums";
export function EventQuickView(props) {
  const [attachments, setAttachments] = useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachmentsLoaded, setAttachmentesLoaded] = useState(false);

  const [event, setEvent] = useState({});

  const [customFields, setCustomFields] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!_.isEmpty(props.event)) {
      setEvent(props.event);
      getEntityAttachments("event", props.event.id).then((res) => {
        setAttachments(res.data);
        setAttachmentesLoaded(true);
      });
    } else if (props.eventId > 0) {
      getEvent(props.eventId).then((res) => {
        setEvent(res.data);
      });
      getEntityAttachments("event", props.eventId).then((res) => {
        setAttachments(res.data);
        setAttachmentesLoaded(true);
      });
    }
  }, [props.event, props.eventId]);

  useEffect(() => {
    if (props.customFields) {
      setCustomFields(props.customFields);
    } else {
      getCustomFields(EntityTypes.Event).then((res) => {
        let eventCustomFields = res.data;
        setCustomFields(eventCustomFields);
      });
    }
  }, [props.customFields, props.event]);

  const downloadAttachment = (id, fileName) => {
    downloadEntityAttachment(id).then((res) => {
      saveAs(res.data, fileName);
    });
  };

  const handleEventNumberClick = (e) => {
    e.preventDefault();
    history.push(`/events/${event.id}`);
  };

  const handleLinkClick = (e) => {
    navigator.clipboard.writeText(`${window.origin}/events/${event.id}`);
    alertify.success("Link copied!");
  };

  return (
    <>
      <>
        <DialogContent
          className="event-quick-view-dialog"
          sx={{ padding: "10px 12px" }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Col width={10}>
                <div className="d-flex flex-wrap align-items-center mb-1">
                  <Link
                    href="#"
                    onClick={(e) => handleEventNumberClick(e)}
                    underline="hover"
                    className="mr-2"
                  >
                    <Typography variant="h6" component="span">
                      {event.eventNumber} - {event.eventCategory}
                    </Typography>
                  </Link>
                  {event.eventSubCategory && (
                    <Typography
                      className="mr-2"
                      variant="subtitle1"
                      component="span"
                    >
                      {event.eventSubCategory}
                    </Typography>
                  )}

                  <div> {getStatusChip(event)}</div>
                </div>
              </Grid.Col>
              <Grid.Col width={2}>
                <div className="d-flex flex-wrap align-items-center mb-1">
                  <div className="ml-auto">
                    <Tooltip title="Copy link to event">
                      <IconButton onClick={handleLinkClick}>
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={6} width={12} className="">
                {!_.isEmpty(event.eventDueDateTime) && (
                  <Typography variant="subtitle2">
                    Due:&nbsp;
                    {dateUtil.convertDateOnlyToLocal(event.eventDueDateTime)}
                  </Typography>
                )}
                {event.eventDateTime && (
                  <Typography variant="subtitle2">
                    Completed:&nbsp;
                    {dateUtil.convertDateTimeToLocal(event.eventDateTime)}
                  </Typography>
                )}
              </Grid.Col>
              <Grid.Col md={6} width={12} className="">
                <Typography variant="subtitle2">{event.assetName}</Typography>
              </Grid.Col>
            </Grid.Row>
            {event.co2Emissions > 0 && (
              <Grid.Row>
                <Grid.Col md={6} width={12} className="mt-1">
                  <FactoryIcon className="pt-1 mr-1" />
                  <Typography variant="body">
                    {_.round(event.co2Emissions, 2)} kg/CO2
                  </Typography>
                </Grid.Col>
              </Grid.Row>
            )}

            <Divider className="mt-2 mb-2" />
            <Grid.Row className="mt-2">
              <Grid.Col md={6} width={12} className="">
                <Typography variant="body">
                  <b>Urgency:</b>&nbsp;
                  <i
                    className={"fe fe-circle "}
                    style={{ color: urgencyColorSwitch(event) }}
                  />
                  &nbsp;{event.urgency || "None"}
                </Typography>
              </Grid.Col>
              {event.assignedToUserFullName && (
                <Grid.Col md={6} width={12} className="">
                  <Typography variant="body">
                    <b>Assigned To: </b>
                    {event.assignedToUserFullName}
                  </Typography>
                </Grid.Col>
              )}
            </Grid.Row>
            {event.description && (
              <Grid.Row className="mt-2">
                <Grid.Col md={12} width={12} className="">
                  <Typography variant="body">
                    <b>Description: </b>
                    {event.description}
                  </Typography>
                </Grid.Col>
              </Grid.Row>
            )}
            <Divider className="mt-2 mb-2" />
            <Grid.Row>
              <Grid.Col md={6} width={12} className="">
                <Typography variant="body">
                  <b>Follow up required? </b>
                  {event.followUpRequired ? "Yes" : "No"}
                </Typography>
              </Grid.Col>
              {event.followUpDate && (
                <Grid.Col md={6} width={12} className="">
                  <Typography component="div" variant="body">
                    <b>Follow up date: </b>
                    {dateUtil.convertDateOnlyToLocal(event.followUpDate)}
                  </Typography>
                </Grid.Col>
              )}
            </Grid.Row>
            {event.followUpDescription && (
              <Grid.Row>
                <Grid.Col md={12} width={12} className="">
                  <Typography variant="body">
                    <b>Follow Up Description: </b>
                    {event.followUpDescription}
                  </Typography>
                </Grid.Col>
              </Grid.Row>
            )}
            {!_.isEmpty(event.customFieldData) && !_.isEmpty(customFields) && (
              <>
                <Divider className="mt-2 mb-2" />
                <Grid.Row>
                  {" "}
                  {Object.keys(
                    Object.fromEntries(
                      Object.entries(event.customFieldData).filter(
                        ([_, v]) => v != "" && v != null
                      )
                    )
                  ).map((item, i) => (
                    <Grid.Col md={6} width={12} className="" key={i}>
                      {" "}
                      <Typography variant="body">
                        <b>{`${
                          customFields.find((x) => x.fieldName == item)
                            .fieldLabel
                        }: `}</b>
                        {customFields.find((x) => x.fieldName == item)
                          .fieldType == "Date"
                          ? dateUtil.convertDateOnlyToLocal(
                              event.customFieldData[item].dateValue
                            )
                          : customFields.find((x) => x.fieldName == item)
                              .fieldType == "DateTime"
                          ? dateUtil.convertDateTimeToLocal(
                              event.customFieldData[item].dateTimeValue
                            )
                          : customFields.find((x) => x.fieldName == item)
                              .fieldType == "Checkbox"
                          ? event.customFieldData[item].booleanValue
                            ? "Yes"
                            : "No"
                          : customFields.find((x) => x.fieldName == item)
                              .fieldType == "MultiSelect"
                          ? _.orderBy(
                              event.customFieldData[item].textValue.split("|")
                            ).map((a) => (
                              <Chip
                                className="mr-1"
                                label={a}
                                variant="outlined"
                                key={a}
                              />
                            ))
                          : event.customFieldData[item].textValue}
                      </Typography>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              </>
            )}

            <>
              <Divider className="mt-2 mb-2" />
              <Grid.Row>
                <div style={{ height: 40, width: "100%" }}>
                  <Dimmer active={!attachmentsLoaded} loader>
                    <Grid.Col className="">
                      {!_.isEmpty(attachments) ? (
                        <Button
                          onClick={() => setShowAttachments(!showAttachments)}
                        >
                          {`${attachments.length} Attachment${
                            attachments.length > 1 ? "s" : ""
                          }`}
                        </Button>
                      ) : (
                        <Typography variant="body">No attachments</Typography>
                      )}
                    </Grid.Col>
                  </Dimmer>
                </div>
              </Grid.Row>
              <Grid.Row>
                {showAttachments && !_.isEmpty(attachments) && (
                  <>
                    {attachments
                      .sort(function (a, b) {
                        var textA = a.fileName.toUpperCase();
                        var textB = b.fileName.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      })
                      .map((file) => (
                        <Grid.Col
                          width={12}
                          key={file.fileName}
                          className="mb-1"
                        >
                          <li>
                            {file.fileName}

                            <Icon
                              onClick={() =>
                                downloadAttachment(
                                  file.entityAttachmentId,
                                  file.fileName
                                )
                              }
                              className="ml-2 pointer"
                              name="download"
                            />
                          </li>
                        </Grid.Col>
                      ))}
                  </>
                )}
              </Grid.Row>
              <Divider className="mt-2 mb-2" />
              <Grid.Row>
                <Grid.Col md={6} width={12} className="mb-1">
                  <Typography variant="body">
                    <b>Created by:</b> {event.createdByFullName}
                    <br></br>
                    <b>Created date:</b>{" "}
                    {moment
                      .utc(event.createdDateTimeUtc)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </Grid.Col>

                {!_.isEmpty(_.trim(event.lastModifiedByFullName)) && (
                  <Grid.Col md={6} width={12} className="mb-1">
                    <Typography variant="body">
                      <b>Last modified by:</b> {event.lastModifiedByFullName}
                      <br></br>
                      <b>Last modified date:</b>{" "}
                      {moment
                        .utc(event.lastModifiedDateTimeUtc)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </Typography>
                  </Grid.Col>
                )}
              </Grid.Row>
              {!_.isEmpty(event) && event.lastReviewedBy && (
                <>
                  <Divider />
                  <Grid.Row>
                    <Grid.Col md={6} width={12} className="mb-1">
                      <Typography variant="body">
                        <b>Review Completed:</b>{" "}
                        {event.reviewCompleted
                          ? event.reviewCompleted.toString()
                          : ""}
                        <br></br>
                        <b>Last Reviewed By:</b> {event.lastReviewedBy}
                      </Typography>
                    </Grid.Col>
                    <Grid.Col md={6} width={12} className="mb-1">
                      <Typography variant="body">
                        <b>Last Reviewed Date:</b>{" "}
                        {moment
                          .utc(event.lastReviewedDateTimeUtc)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </Typography>
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose()}>Close</Button>
        </DialogActions>
      </>
    </>
  );
}
