import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import _ from "lodash";
import React from "react";
import { EntityTypes } from "../../../types/enums";
import { Review } from "./review";

export function EventDetailsReview(props) {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Final Review" />
      <CardContent className="p-1 mb-2 mt-2">
        <Review
          setEventLocked={(e) => props.setEventLocked(e)}
          lookupId={props.event.eventCategoryId}
          isEventDetails
          entityType={EntityTypes.Event}
          entityId={props.event.id}
          hasPendingActions={_.toLower(props.event.status) == "pending actions"}
        />
      </CardContent>
    </Card>
  );
}
