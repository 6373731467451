import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { loggedIn, roleMatch } from "actions/auth";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MonitoringLocationPortalTypes, UserRoles } from "../../../types/enums";
import { WylooLoginRegister } from "./wyloo-login-register";
import { navigateTo } from "services/navigation-service";
export function MonitoringLocationMicrositeCard(props) {
  const [showRegister, setShowRegister] = useState(false);
  const location = props.entity;

  const history = useHistory();

  var type = props.entity.portalType;
  const isWildlife = type == MonitoringLocationPortalTypes.Wildlife;

  return (
    <>
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">{location.name}</Typography>
        <Typography variant="body2">
          Type: {location.monitoringLocationType || "-"}
        </Typography>
        <Typography variant="body2" className="mb-2">
          Description: {location.description || "-"}
        </Typography>
        <Typography variant="body2" className="">
          {isWildlife ? "" : location.numberOfEvents + " monitoring events"}
        </Typography>
        {location.firstEventDateTimeUtc && (
          <>
            <Typography variant="body2" className="">
              Latest Sample Available:{" "}
              {moment(location.lastEventDateTimeUtc).format("MMM YYYY")}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          onClick={(e) => {
            if (
              loggedIn() &&
              roleMatch([UserRoles.PortalAdmin, UserRoles.PortalUser])
            ) {
              navigateTo(
                history,
                isWildlife
                  ? "/wyloo/wildlife"
                  : `/wyloo/monitoring-locations/${location.id}/`,
                e
              );
            } else {
              setShowRegister(true);
            }
          }}
        >
          {isWildlife
            ? "View Wildlife Observations"
            : "View Monitoring Events and Results"}
        </Button>
      </CardActions>
      {showRegister && (
        <WylooLoginRegister
          location={location}
          open={showRegister}
          handleClose={() => setShowRegister(false)}
        />
      )}
    </>
  );
}
