import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip, { chipClasses } from "@mui/material/Chip";
import _ from "lodash";
import {
  mergeEventCorrespondence,
  searchEvents,
} from "../../../services/events";
import { LynxDialog } from "../../lynx-dialog";
import { useHistory } from "react-router-dom";
import alertify from "alertifyjs";
export function EventCorrespondenceMerge(props) {
  const [autoCompleteEventValue, setAutoCompleteEventValue] = useState(null);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [mergeWarning, setMergeWarning] = useState(false);
  const history = useHistory();
  const [autoCompleteEventInputValue, setAutoCompleteEventInputValue] =
    useState("");
  const [options, setOptions] = useState([]);
  const getEventOptions = () => {
    return options;
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleEventSelected = (e, value) => {
    if (value) {
      setAutoCompleteEventValue(null);

      setSelectedEvent(value);
    }
  };
  const autoCompleteEventInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteEventValue(null);

      return;
    } else {
      setAutoCompleteEventInputValue(newValue);
    }
  };
  useEffect(() => {
    if (autoCompleteEventInputValue === "") {
      setOptions(autoCompleteEventValue ? [autoCompleteEventValue] : []);
      return;
    }
    setEventsLoading(true);
    searchEvents(autoCompleteEventInputValue).then((res) => {
      setOptions(res.data);
      setEventsLoading(false);
    });
  }, [autoCompleteEventValue, autoCompleteEventInputValue]);

  const handleMergeCorrespondence = () => {
    mergeEventCorrespondence(props.event.id, selectedEvent.id).then((res) => {
      alertify.success("Correspondence merged successfully");
      handleNavigateTo(null, `/events/${selectedEvent.id}`);
    });
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Autocomplete
          filterOptions={(x) => x}
          loading={eventsLoading}
          id="event-search"
          options={getEventOptions()}
          noOptionsText={
            !autoCompleteEventInputValue ? "Enter text to search" : "No options"
          }
          getOptionLabel={(option) => option.name + " " + option.tagGroupName}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <Typography>{option.eventNumber}&nbsp;</Typography>(
              <Typography>{option.eventCategory}</Typography>
              {option.eventSubCategory && (
                <Typography>- {option.eventSubCategory}</Typography>
              )}
              )
              <Typography variant="body2">
                &nbsp;{option.description}
              </Typography>
            </Box>
          )}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Search events by event number, category, subcategory, or description"
            />
          )}
          onChange={handleEventSelected}
          value={autoCompleteEventValue}
          inputValue={autoCompleteEventInputValue}
          onInputChange={autoCompleteEventInputChange}
        />
        {!_.isEmpty(selectedEvent) && (
          <>
            <Chip
              label={`Merge correspondence from ${props.event.eventNumber} to ${selectedEvent.eventNumber}`}
              variant="outlined"
              className="mt-2"
              sx={{
                [`& .${chipClasses.icon}`]: {
                  color: "rgba(0, 0, 0, 0.26)",
                },
              }}
              {...(!props.eventLocked
                ? {
                    onDelete: () => setSelectedEvent({}),
                  }
                : {})}
            />
            <Button
              variant="outlined"
              onClick={() => {
                setMergeWarning(true);
              }}
              className="mt-2"
            >
              Merge Correspondence
            </Button>
          </>
        )}
        {mergeWarning && (
          <LynxDialog
            handleClose={() => setMergeWarning(false)}
            title={"Are you sure you?"}
            open={mergeWarning}
            handleConfirm={handleMergeCorrespondence}
            description={`The correspondence from event ${props.event.eventNumber} will be merged into event ${selectedEvent.eventNumber} and ${selectedEvent.eventNumber} will be deleted.`}
          />
        )}
      </div>
    </>
  );
}
