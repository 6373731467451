import { apiUrl, client } from "../modules/store";

const mainUrl = apiUrl + "/comments";
export function createComment(dto) {
  return client.post(mainUrl, dto);
}

export function getCommentsForEntity(entityType, entityId) {
  return client.get(`${mainUrl}?entityType=${entityType}&entityId=${entityId}`);
}

export function deleteComment(id) {
  return client.delete(`${mainUrl}/${id}`);
}

export function updateComment(id, dto) {
  return client.put(`${mainUrl}/${id}`, dto);
}
