import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Typography } from "@mui/material";
import lynxColors from "../../../../modules/lynxColors";
import { Layer, Map, NavigationControl, Source } from "react-map-gl";
import {
  MAPBOX_TOKEN,
  baseMapStyleKey,
  mapStyles,
} from "../../map/dashboard-map";
import { localStorageService } from "../../../../services/local-storage-service";
export function MapWidget(props) {
  const [baseMapStyle, setBaseMapStyle] = useState(null);
  const [viewState, setViewState] = useState({
    longitude: -105.60533895426761,
    latitude: 55.2268559337825,
    zoom: 9,
  });

  useEffect(() => {
    var baseStyle = localStorageService.getLocalStorage(baseMapStyleKey);
    if (baseStyle) {
      setBaseMapStyle(baseStyle);
    } else {
      setBaseMapStyle(mapStyles[0]);
    }
  }, []);
  let targetRef = useRef();
  const onViewportChange = (e) => {
    const viewport = e.viewState;
    setViewState(viewport);
  };

  return (
    <Card variant="outlined" className="lynx-card">
      <CardContent className="p-0">
        <div
          style={{
            height: "400px",
            width: "100%",
          }}
          ref={targetRef}
        >
          {baseMapStyle && baseMapStyle.value && (
            <Map
              {...viewState}
              mapStyle={"mapbox://styles/mapbox/" + baseMapStyle.value}
              mapboxAccessToken={MAPBOX_TOKEN}
              style={{ width: "100%", height: "100%" }}
              onMove={onViewportChange}
            >
              {" "}
              <Source type="geojson" data={props.geojson} id="events">
                <Layer
                  type="circle"
                  paint={{
                    "circle-color": "blue",
                  }}
                  id="internalEvents"
                  layout={{ visibility: "visible" }}
                />
              </Source>
            </Map>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
