import { apiUrl, client } from "../modules/store";
import queryString from "query-string";
export function acceptPrivacyPolicy(user) {
  return client.post(apiUrl + "/account/accept", {});
}

export function checkPortalStatus(email, url) {
  var object = { email: email, url: url };
  var qs = queryString.stringify(object);
  return client.get(`${apiUrl}/auth/portalCheck?${qs}`);
}
export function deactivateUser() {
  return client.get(`${apiUrl}/auth/deactivate`);
}
