import { apiUrl, client } from "../modules/store";
import queryString from "query-string";
import { localStorageService } from "./local-storage-service";
import { LocalStorageKeys } from "../types/enums";

export function createCountListWidget(dto) {
  let url = `${apiUrl}/dashboardWidgets/countList`;
  return client.post(url, dto);
}

export function createGroupChartWidget(dto) {
  let url = `${apiUrl}/dashboardWidgets/groupChart`;
  return client.post(url, dto);
}

export function getGroupByData(entity, filterId, groupBy) {
  let url = `${apiUrl}/dashboardWidgets/groupData?filterId=${filterId}&groupBy=${groupBy}`;

  return client.get(url);
}

export function deleteWidget(widgetId) {
  let url = `${apiUrl}/dashboardWidgets/${widgetId}`;
  return client.delete(url);
}

export function updateWidgetSort(dto) {
  let url = `${apiUrl}/dashboardWidgets/sortOrder`;
  return client.post(url, dto);
}

export function getSystemWidgets() {
  let url = `${apiUrl}/dashboardWidgets/system`;

  return client.get(url);
}
export function addSystemWidget(dto) {
  let url = `${apiUrl}/dashboardWidgets/system`;
  return client.post(url, dto);
}

export function getWidgetData(id, refreshData) {
  let url = `${apiUrl}/dashboardWidgets/${id}/data`;
  if (refreshData) {
    url = url + "?refreshData=true";
  }
  return client.get(url);
}

export function getSystemDashboardWidgetData(
  systemWidgetId,
  eventStatus,
  dateFilter,
  emissionsScope,
  tagGroup,
  tagCategory,
  parameters,
  monitoringLocaitons,
  resultValues,
  refreshData
) {
  let url = apiUrl + `/dashboardWidgets/system/${systemWidgetId}?`;

  let queryObject = {};
  if (dateFilter) {
    queryObject = { dateFilter: dateFilter, ...queryObject };
  }
  if (eventStatus) {
    queryObject = { eventStatus: eventStatus, ...queryObject };
  }
  if (emissionsScope) {
    queryObject = {
      emissionsScope: emissionsScope.replace(" ", ""),
      ...queryObject,
    };
  }
  if (tagGroup) {
    queryObject = { tagGroup: tagGroup, ...queryObject };
  }
  if (tagCategory) {
    queryObject = { tagCategory: tagCategory, ...queryObject };
  }

  if (parameters) {
    queryObject = { parameters: parameters, ...queryObject };
  }
  if (monitoringLocaitons) {
    queryObject = { monitoringLocations: monitoringLocaitons, ...queryObject };
  }
  if (resultValues) {
    queryObject = { resultValues: resultValues, ...queryObject };
  }
  if (refreshData) {
    queryObject = { refreshData: refreshData, ...queryObject };
  }
  url = url + queryString.stringify(queryObject);
  return client.get(url);
}

export function handleWidgetFilterLocalStorageChange(widgetId, filterOptions) {
  localStorageService.setLocalStorage(
    LocalStorageKeys.WidgetFilters + "_" + widgetId,
    filterOptions
  );
}

export function handleDeleteWidgetFilterLocalStorage(widgetId) {
  localStorageService.removeLocalStorage(
    LocalStorageKeys.WidgetFilters + "_" + widgetId
  );
}

export function getWidgetFilterLocalStorage(widgetId) {
  return localStorageService.getLocalStorage(
    LocalStorageKeys.WidgetFilters + "_" + widgetId
  );
}
