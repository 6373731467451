import "./LynxDataGrid.css";

import { GridFilterInputSingleSelect } from "@mui/x-data-grid-pro";

export const multipleSelectContainsOperator = [
  {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return true;
    },
    InputComponent: GridFilterInputSingleSelect,
  },
];

export const multipleSelectDoesNotContainOperator = [
  {
    label: "does not contain",
    value: "doesNotContain",
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return true;
    },
    InputComponent: GridFilterInputSingleSelect,
  },
];
