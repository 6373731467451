import ArticleIcon from "@mui/icons-material/Article";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import GasMeterOutlinedIcon from "@mui/icons-material/GasMeterOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MonitorOutlinedIcon from "@mui/icons-material/MonitorOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PeopleIcon from "@mui/icons-material/People";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Table } from "tabler-react";
import { roleMatch } from "../../actions/auth";
import { UserRoles } from "../../types/enums";
import useOnlineStatus from "./../../hooks/useOnlineStatus";
export const MainSidebar = (props) => {
  const history = useHistory();
  var organization = useSelector((state) => state.organization);
  const isOffline = !useOnlineStatus();

  return (
    <aside className="sidebar" id="mainSidebar">
      <Card className=" h-100">
        <Table
          responsive
          highlightRowOnHover
          hasOutline
          verticalAlign="center"
          cards
          className="text-nowrap"
        >
          <Table.Body>
            {!isOffline && (
              <>
                <Table.Row
                  className="pointer sidebar-dashboard"
                  onClick={() => history.push("/dashboard")}
                >
                  <Table.Col>
                    <MonitorOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </Table.Col>
                  <Table.Col>Dashboard</Table.Col>
                </Table.Row>

                {!roleMatch([UserRoles.MshaManagedUser]) && (
                  <Table.Row
                    className="pointer sidebar-map"
                    onClick={() => history.push("/map")}
                  >
                    <Table.Col>
                      <MapOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#495057" }}
                      />
                    </Table.Col>
                    <Table.Col>Map</Table.Col>
                  </Table.Row>
                )}
                {roleMatch([UserRoles.Admin]) && (
                  <Table.Row
                    className="pointer sidebar-assets"
                    onClick={() => history.push("/assets")}
                  >
                    <Table.Col>
                      <HomeOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#495057" }}
                      />
                    </Table.Col>
                    <Table.Col>Assets</Table.Col>
                  </Table.Row>
                )}
              </>
            )}
            {roleMatch([UserRoles.EventsUser]) && (
              <Table.Row
                className="pointer sidebar-events"
                onClick={() => history.push("/events")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <EventOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#495057" }}
                  />
                </Table.Col>
                <Table.Col>Events</Table.Col>
              </Table.Row>
            )}
            {roleMatch([
              UserRoles.InspectionsUser,
              UserRoles.MshaManagedUser,
            ]) && (
              <Table.Row
                className="pointer sidebar-inspections"
                onClick={() => history.push("/inspections")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <ContentPasteIcon
                    fontSize="small"
                    sx={{ color: "#495057" }}
                  />
                </Table.Col>
                <Table.Col>Inspections</Table.Col>
              </Table.Row>
            )}
            {roleMatch([UserRoles.MshaManagedUser, UserRoles.MshaUser]) &&
              !isOffline && (
                <Table.Row
                  className="pointer sidebar-programs"
                  onClick={() => history.push("/programs")}
                  style={{
                    borderBottom: isOffline ? "1px solid #ced4da" : "",
                  }}
                >
                  <Table.Col>
                    <FlagOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </Table.Col>
                  <Table.Col>Programs</Table.Col>
                </Table.Row>
              )}
            {roleMatch([UserRoles.IncidentsUser]) && !isOffline && (
              <Table.Row
                className="pointer sidebar-incidents"
                onClick={() => history.push("/incidents")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <WarningAmberIcon
                    fontSize="small"
                    sx={{ color: "#495057" }}
                  />
                </Table.Col>
                <Table.Col>Incidents</Table.Col>
              </Table.Row>
            )}
            {roleMatch([UserRoles.EventsUser, UserRoles.IncidentsUser]) &&
              !isOffline && (
                <Table.Row
                  className="pointer sidebar-actions"
                  onClick={() => history.push("/actions")}
                  style={{
                    borderBottom: isOffline ? "1px solid #ced4da" : "",
                  }}
                >
                  <Table.Col>
                    <PendingActionsIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </Table.Col>
                  <Table.Col>Actions</Table.Col>
                </Table.Row>
              )}

            {roleMatch([UserRoles.MonitoringUser]) && (
              <Table.Row
                className="pointer sidebar-monitoring"
                onClick={() => history.push("/monitoring")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
                id="monitoringSidebarRow"
              >
                <Table.Col>
                  <GasMeterOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#495057" }}
                  />
                </Table.Col>
                <Table.Col>Monitoring</Table.Col>
              </Table.Row>
            )}

            {roleMatch([UserRoles.EnergyUser]) && !isOffline && (
              <Table.Row
                className="pointer sidebar-energy-logs"
                onClick={() => history.push("/energy-logs")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <FactoryIcon fontSize="small" sx={{ color: "#495057" }} />
                </Table.Col>
                <Table.Col>Energy Logs</Table.Col>
              </Table.Row>
            )}
            {organization.hasPermitFeature && !isOffline && (
              <Table.Row
                className="pointer sidebar-permits"
                onClick={() => history.push("/permits")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <ArticleIcon fontSize="small" sx={{ color: "#495057" }} />
                </Table.Col>
                <Table.Col>Permits</Table.Col>
              </Table.Row>
            )}
            {organization.featureFlags.showInDevelopmentFeatures && (
              <Table.Row
                className="pointer"
                onClick={() => history.push("/portal-submissions")}
                style={{
                  borderBottom: isOffline ? "1px solid #ced4da" : "",
                }}
              >
                <Table.Col>
                  <PeopleIcon fontSize="small" sx={{ color: "#495057" }} />
                </Table.Col>
                <Table.Col>
                  Community <br></br>Portal
                </Table.Col>
              </Table.Row>
            )}

            {roleMatch([UserRoles.MshaManagedUser, UserRoles.MshaUser]) &&
              !isOffline && (
                <Table.Row
                  className="pointer sidebar-equipment"
                  onClick={() => history.push("/equipment")}
                  style={{
                    borderBottom: isOffline ? "1px solid #ced4da" : "",
                  }}
                >
                  <Table.Col>
                    <ConstructionOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </Table.Col>
                  <Table.Col>Equipment</Table.Col>
                </Table.Row>
              )}
            {!isOffline && !roleMatch([UserRoles.MshaManagedUser]) && (
              <>
                <>
                  <Table.Row
                    className="pointer"
                    onClick={() => history.push("/kpis")}
                  >
                    <Table.Col>
                      <InsightsIcon
                        fontSize="small"
                        sx={{ color: "#495057" }}
                      />
                    </Table.Col>
                    <Table.Col>KPIs</Table.Col>
                  </Table.Row>
                  <Table.Row
                    className="pointer"
                    onClick={() => history.push("/contacts")}
                  >
                    <Table.Col>
                      <RecentActorsOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#495057" }}
                      />
                    </Table.Col>
                    <Table.Col>Contacts</Table.Col>
                  </Table.Row>
                </>
                {roleMatch([UserRoles.MshaUser]) && (
                  <Table.Row
                    className="pointer sidebar-manage-customers"
                    onClick={() => history.push("/msha-clients")}
                  >
                    <Table.Col>
                      <ManageAccountsOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#495057" }}
                      />
                    </Table.Col>
                    <Table.Col>
                      Manage<br></br> Clients
                    </Table.Col>
                  </Table.Row>
                )}
              </>
            )}

            <Table.Row
              className="pointer sidebar-settings"
              onClick={() => history.push("/settings")}
              style={{ borderBottom: "1px solid #ced4da" }}
            >
              <Table.Col>
                <SettingsOutlinedIcon
                  fontSize="small"
                  sx={{ color: "#495057" }}
                />
              </Table.Col>
              <Table.Col>Settings</Table.Col>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card>
    </aside>
  );
};
