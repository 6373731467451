import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { getAssetLookups } from "../../../services/assets";
import { dateUtil } from "../../../services/date-util";
import { getParameters } from "../../../services/parameters";
import { getUsersLookups } from "../../../services/users";
import { LookupTypes, VarianceStatuses } from "../../../types/enums";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
export const monitoringResultColumns = [
  {
    field: "monitoringEventNumber",
    headerName: "Event #",
    width: 100,
    type: "string",
  },
  {
    field: "monitoringEventDueDateTimeUtc",
    headerName: "Due Date",
    width: 150,
    type: "date",
    importable: true,
    isEventField: true,
    valueGetter: (params) => {
      return dateUtil.convertDateOnlyToLocal(params.value);
    },
    disabled: true,
  },

  {
    field: "monitoringEventStartDateTimeUtc",
    headerName: "Start Date",
    width: 150,
    type: "dateTime",
    importable: true,
    isEventField: true,
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "monitoringEventEndDateTimeUtc",
    headerName: "End Date",
    width: 150,
    type: "dateTime",
    importable: true,
    isEventField: true,
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },

  {
    field: "monitoringEventName",
    headerName: "Sample ID",
    width: 150,
    type: "string",
    importable: true,
    isEventField: true,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 150,
    type: "singleSelect",
    lookupFunction: getParameters,
    valueField: "id",
    labelField: "name",
    importable: true,
  },
  {
    field: "enteredValue",
    headerName: "Value",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "value",
    headerName: "Numeric Value",
    width: 50,
    type: "number",
    disabled: true,
  },

  {
    field: "unitName",
    headerName: "Unit",
    width: 150,
    type: "singleSelect",
    lookupType: "unit",
    importable: true,
  },
  {
    field: "enteredMethodDetectionLimit",
    headerName: "MDL",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "enteredReportingDetectionLimit",
    headerName: "RDL",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "sequence",
    headerName: "Sequence",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "populationVariance",
    headerName: "Population Variance",
    width: 50,
    type: "number",
  },
  {
    field: "sampleVariance",
    headerName: "Sample Variance",
    width: 50,
    type: "number",
    useInDashboard: false,
  },
  {
    field: "varianceLimit",
    headerName: "Variance Allowance",
    width: 50,
    type: "number",
    useInDashboard: false,
  },
  {
    field: "varianceStatus",
    headerName: "Variance Status",
    width: 50,
    type: "singleSelect",
    valueOptions: [VarianceStatuses.Exceedance, VarianceStatuses.NonExceedance],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value !== "isAnyOf"
    ),
    renderCell: (params) => (
      <div>
        <>
          <i
            className={`fe fe-circle ${
              params.value === VarianceStatuses.Exceedance
                ? "text-danger"
                : "text-success"
            }`}
          />
          &nbsp;{params.value}
        </>
      </div>
    ),
  },
  {
    field: "rdp",
    headerName: "RPD",
    width: 50,
    type: "number",
  },
  {
    field: "analysisMethod",
    headerName: "Analysis Method",
    width: 150,
    type: "string",
    importable: true,
  },

  {
    field: "lowestLimitString",
    headerName: "Lowest Limit",
    width: 50,
    type: "string",
    filterable: false,
    sortable: false,
  },

  {
    field: "limitsList",
    headerName: "All Limits",
    width: 150,
    type: "string",
    filterable: false,
    sortable: false,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 100,
    type: "string",
    importable: true,
  },
  {
    field: "resultType",
    headerName: "Result Type",
    width: 100,
    type: "singleSelect",
    lookupType: LookupTypes.MonitoringResultType,
    importable: true,
  },
  {
    field: "monitoringLocationNumber",
    headerName: "Location Number",
    width: 150,
    type: "singleSelect",
    lookupFunction: getMonitoringLocations,
    valueField: "id",
    labelField: "monitoringLocationNumber",
  },
  {
    field: "monitoringLocationName",
    headerName: "Monitoring Location",
    width: 150,
    type: "singleSelect",
    lookupFunction: getMonitoringLocations,
    valueField: "id",
    labelField: "name",
    importable: true,
    isEventField: true,
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    width: 150,
    type: "singleSelect",
    lookupFunction: getAssetLookups,
    valueField: "id",
    labelField: "name",
  },

  {
    field: "monitoringEventType",
    headerName: "Event Type",
    width: 200,
    type: "singleSelect",
    lookupType: "monitoringEventType",
    importable: true,
    isEventField: true,
  },
  {
    field: "monitoringEventDescription",
    headerName: "Description",
    width: 150,
    type: "string",
    importable: true,
    disabled: true,
    isEventField: true,
  },
  {
    field: "assignedToUserFullName",
    headerName: "Assigned To",
    width: 150,
    type: "singleSelect",
    lookupFunction: getUsersLookups,
    valueField: "id",
    labelField: "fullName",
    importable: true,
    isEventField: true,
    disabled: true,
  },
  {
    field: "isNonCompliant",
    headerName: "Compliance Status",
    width: 150,
    type: "singleSelect",
    valueOptions: ["Noncompliant", "Compliant"],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value !== "isAnyOf"
    ),
    renderCell: (params) => (
      <div>
        <>
          <i
            className={`fe fe-circle ${
              params.value === true
                ? "text-danger"
                : params.value === false
                ? "text-success"
                : "text-warning"
            }`}
          />
          &nbsp;{params.value}
        </>
      </div>
    ),
  },
];
