import _ from "lodash";

class ValidationService {
  validateRequiredValue(value, errorObject, fieldToPassError, fieldLabel) {
    if (
      value === undefined ||
      value === null ||
      (_.isString(value) && value.trim().length === 0) ||
      (_.isArray(value) && value.length < 1)
    ) {
      if (fieldLabel) {
        _.set(errorObject, fieldToPassError, fieldLabel + " is required.");
      } else {
        _.set(errorObject, fieldToPassError, "Field is required.");
      }
      return false;
    } else {
      _.unset(errorObject, fieldToPassError);
      return true;
    }
  }

  validateRequiredField(object, field, fieldToPassError, fieldLabel) {
    var value = _.get(object, field);
    return this.validateRequiredValue(
      value,
      object,
      fieldToPassError,
      fieldLabel
    );
  }

  validateFieldLength(object, field, fieldToPassError, maxLength, fieldLabel) {
    var value = _.get(object, field);
    if (value.length > maxLength) {
      object[fieldToPassError] = fieldLabel
        ? fieldLabel
        : field + " exceed maximum length of " + maxLength.toString();

      return false;
    } else {
      _.unset(object, fieldToPassError);
      return true;
    }
  }

  validateNumberField(
    object,
    numberFieldName,
    fieldToPassError,
    required,
    fieldLabel
  ) {
    const value = _.get(object, numberFieldName);
    if (!_.isEmpty(value) || typeof value == "number") {
      if (_.isNaN(_.toNumber(value))) {
        if (fieldLabel) {
          _.set(object, fieldToPassError, `${fieldLabel} must be a number.`);
        } else {
          _.set(
            object,
            fieldToPassError,
            `The value provided for ${fieldLabel} is not a valid number.`
          );
        }
      } else {
        _.unset(object, fieldToPassError);
        return value;
      }
    } else {
      if (required === true) {
        if (fieldLabel) {
          _.set(object, fieldToPassError, `${fieldLabel} is required.`);
        } else {
          _.set(object, fieldToPassError, "Field is required.");
        }
      } else {
        _.unset(object, fieldToPassError);
      }
    }
  }

  validateEmailValue(emailStr, errorObject, fieldToPassError, isRequired) {
    if (!_.isEmpty(emailStr)) {
      if (!this.isEmailFormatValid(emailStr)) {
        errorObject[fieldToPassError] = "Email format is invalid.";
        return false;
      } else {
        _.unset(errorObject, fieldToPassError);
      }
    } else if (isRequired) {
      errorObject[fieldToPassError] = "Email is required.";
    } else {
      _.unset(errorObject, fieldToPassError);
    }

    return true;
  }

  isEmailFormatValid = (value) => {
    let emailRegex =
      /^(([^<>()[\]\\.#{}!,$`~*;:\s@"]+(\.[^<>()[\]\\.{},;$#*`!~:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(value);
  };

  hasError(object, ...errorFieldNames) {
    let hasError = false;
    _.each(errorFieldNames, (errorField) => {
      if (_.has(object, errorField) && _.get(object, errorField)) {
        hasError = true;
        return false;
      }
    });
    return hasError;
  }

  unsetErrors(object, ...errorFieldNames) {
    _.each(errorFieldNames, (errorField) => {
      if (_.has(object, errorField) && _.get(object, errorField)) {
        _.unset(object, errorField);
      }
    });
    return object;
  }

  unsetFields(object, ...fieldNames) {
    _.each(fieldNames, (field) => {
      if (_.has(object, field)) {
        _.unset(object, field);
      }
    });
    return object;
  }
}

export const validationService = new ValidationService();
