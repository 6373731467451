import { apiUrl, client } from "../modules/store";
export function getMonitoringResults(params) {
  return client.get(apiUrl + "/monitoringResults", {
    params: params,
  });
}

export function getMonitoringResultCountByFilterId(filterId) {
  let url = apiUrl + "/monitoringResults/count/" + filterId;
  return client.get(url);
}

export function updateMonitoringResult(resultId, dto) {
  let url = `${apiUrl}/monitoringResults/${resultId}`;
  return client.put(url, dto);
}

export function deleteMonitoringResult(id) {
  return client.delete(apiUrl + "/monitoringResults/" + id);
}
export function getMonitoringResultsWide(params) {
  return client.get(apiUrl + "/monitoringResults/wide", {
    params: params,
  });
}

export function getMonitoringResultExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/monitoringResults/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
