import React from "react";
import { Redirect, Route } from "react-router-dom";
import { loggedIn, logoutUser, roleMatch } from "../actions/auth";
import queryString from "query-string";
import PageNotFound from "./PageNotFound";
import { UserRoles } from "types/enums";

function PrivateRoute({ component: Component, roles, path, ...rest }) {
  const redirectPath = { redirect: rest.location.pathname };
  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn() && !isPortalUser && roles === undefined) {
          return <Component {...props} />;
        }

        if (loggedIn() && !isPortalUser && roleMatch(roles)) {
          return <Component {...props} />;
        }
        if (loggedIn() && !isPortalUser && !roleMatch(roles)) {
          return <PageNotFound />;
        } else {
          return (
            <Redirect
              to={{
                pathname: isPortalUser ? "/wyloo" : `/authorize`,
                state: { from: props.location },
                search: isPortalUser
                  ? null
                  : `?${queryString.stringify(redirectPath)}`,
              }}
            />
          );
        }
      }}
    />
  );
}
export default PrivateRoute;
