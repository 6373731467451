import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import {
  getMshaAssets,
  getMshaOrganizationById,
  getMshaUsers,
} from "../../../services/msha-service";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDialog } from "../../lynx-dialog";
import { LynxDataGrid } from "./../../data-grid/LynxDataGrid";
import { MshaEditUser } from "./msha-edit-user";
import { MshaEditAsset } from "./msha-edit-asset";
import { equipmentColumns } from "./equipment-list";
import { EquipmentModal } from "./equipment-modal";
import PageNotFound from "../../PageNotFound";
import { getEquipment } from "./../../../services/equipment-service";
export function MshaCustomerDetails(props) {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [customerFormState, setCustomerFormState] = useState({});
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddAsset, setShowAddAsset] = useState(false);
  const [showAddEquipment, setShowAddEquipment] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState({});
  useEffect(() => {
    loadOrganization();
  }, []);
  const loadOrganization = () => {
    setLoading(true);
    getMshaOrganizationById(props.match.params.id)
      .then((res) => {
        setCustomerFormState({
          name: res.data.name,
          description: res.data.description,
          nameError: "",
        });
        setCustomer(res.data);
        setLoading(false);
      })
      .catch(() => {
        setNotFound(true);
        setLoading(false);
      });
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...customerFormState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setCustomerFormState(newState);
  };
  const assetColumns = [
    {
      field: "edit",
      headerName: "",
      width: 125,
      type: "string",
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setSelectedAsset(params.row);
            }}
            className="edit-asset-button"
            variant="outlined"
          >
            Edit Mine Site
          </Button>
        );
      },
    },
    {
      field: "name",
      headerName: "Mine Site Name",
      width: 150,
      type: "string",
    },
    {
      field: "assetNumber",
      headerName: "Mine Site ID",
      width: 150,
      type: "string",
    },
  ];
  const userColumns = [
    {
      field: "edit",
      headerName: "",
      width: 125,
      type: "string",
      renderCell: (params) => {
        return (
          <Button
            className="edit-user-button"
            onClick={() => {
              setSelectedUser(params.row);
            }}
            variant="outlined"
          >
            Edit User
          </Button>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      type: "singleSelect",
      valueOptions: ["Active", "Inactive", "Pending Registration"],
      renderCell: (params) => (
        <div className="event-status">
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(params.row) }}
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      type: "string",
    },
    {
      field: "title",
      headerName: "Title",
      width: 100,
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 100,
      type: "boolean",
    },
    {
      field: "hasRegistered",
      headerName: "Registered?",
      width: 100,
      type: "boolean",
    },

    {
      field: "registrationCompletedDateTimeUtc",
      headerName: "Registered Date",
      width: 150,
      type: "dateTime",
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
    },
    {
      field: "registrationGuid",
      headerName: "Register Link",
      width: 400,
      type: "string",
      renderCell: (params) => {
        if (params.value) {
          return (
            <div>
              <Tooltip title="Copy" className="mr-2">
                <IconButton
                  className="grid-edit-button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/register/${params.value}`
                    );
                    alertify.success("Registration Link Copied.");
                  }}
                  aria-label="Copy"
                  size="small"
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              {`${window.location.origin}/register/${params.value}`}
            </div>
          );
        }
        return <></>;
      },
    },
  ];
  const handleUserClose = (refresh) => {
    setSelectedUser(null);
    setShowAddAsset(false);
    setShowAddUser(false);
    setSelectedAsset(null);
    setSelectedEquipment({});
    setShowAddEquipment(false);
    if (refresh) {
      loadOrganization();
    }
  };
  if (notFound) {
    return <PageNotFound />;
  } else
    return (
      <Grid>
        <Dimmer active={loading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography
                    variant="h3"
                    className="form-header-text"
                    component="div"
                  >
                    {customer.name}
                  </Typography>
                </div>
              </Grid.Col>{" "}
              <Grid.Col lg={12} width={12} className="">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleNavigateTo(e, "/msha-clients")}
                  >
                    MSHA Clients
                  </Link>
                  <Typography color="text.primary">{customer.name}</Typography>
                </Breadcrumbs>
              </Grid.Col>
            </Grid.Row>
          </Paper>
          <Grid.Row>
            <Container className="mt-2" maxWidth="xl">
              <Form className="card ">
                <Card.Body className="p-0">
                  <Grid.Row>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Grid.Col md={6} width={12}>
                            <Form.Group
                              label="Client / Mine Operator Name"
                              isRequired
                            >
                              <Form.Input
                                type="text"
                                name="name"
                                className="customer-name"
                                onChange={handleInputChange}
                                value={customerFormState.name}
                                error={customerFormState.nameError}
                              ></Form.Input>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group label="Description">
                              <Form.Textarea
                                onChange={handleInputChange}
                                name={`description`}
                                value={customerFormState.description}
                              ></Form.Textarea>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={12} width={12}>
                            <Button variant="contained" className="float-right">
                              Save Customer Details
                            </Button>
                          </Grid.Col>
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Users</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Button
                            className="ml-auto mb-2"
                            variant="contained"
                            onClick={() => {
                              setShowAddUser(true);
                            }}
                          >
                            Add User
                          </Button>
                        </Grid.Row>
                        <div style={{ height: "100%" }} className="mt-1">
                          <LynxDataGrid
                            isLoading={loading}
                            getDataFunction={getMshaUsers}
                            columns={userColumns}
                            hasNoLookups
                            onlyShowTable
                            autoHeight
                            getDataParams={{
                              organizationId: props.match.params.id,
                            }}
                            localStorageName={`mshaOrg_users_${props.match.params.id}`}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Mine Sites</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Button
                            className="ml-auto mb-2"
                            variant="contained"
                            onClick={() => {
                              setShowAddAsset(true);
                            }}
                          >
                            Add Mine Site
                          </Button>
                        </Grid.Row>
                        <div style={{ height: "100%" }} className="mt-1">
                          <LynxDataGrid
                            isLoading={loading}
                            getDataFunction={getMshaAssets}
                            columns={assetColumns}
                            hasNoLookups
                            onlyShowTable
                            autoHeight
                            getDataParams={{
                              organizationId: props.match.params.id,
                            }}
                            localStorageName={`mshaOrg_assets_${props.match.params.id}`}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Equipment</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Button
                            className="ml-auto mb-2"
                            variant="contained"
                            onClick={() => {
                              setShowAddEquipment(true);
                            }}
                          >
                            Add Equipment
                          </Button>
                        </Grid.Row>
                        <div style={{ height: "100%" }} className="mt-1">
                          <LynxDataGrid
                            isLoading={loading}
                            getDataFunction={getEquipment}
                            columns={[
                              {
                                field: "edit",
                                headerName: "",
                                width: 150,
                                type: "string",
                                renderCell: (params) => {
                                  return (
                                    <Button
                                      onClick={() => {
                                        setSelectedEquipment(params.row);
                                      }}
                                      variant="outlined"
                                    >
                                      Edit Equipment
                                    </Button>
                                  );
                                },
                              },
                              ...equipmentColumns,
                            ]}
                            hasNoLookups
                            onlyShowTable
                            autoHeight
                            getDataParams={{
                              organizationId: props.match.params.id,
                            }}
                            localStorageName={`mshaOrg_equipment_${props.match.params.id}`}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid.Row>
                </Card.Body>
              </Form>
            </Container>
          </Grid.Row>
        </Dimmer>
        {(selectedUser != null || showAddUser) && (
          <LynxDialog
            maxWidth={"md"}
            open={selectedUser != null || showAddUser}
            title={showAddUser ? "Add User" : `Edit User`}
            fullWidth
            dialogContent={
              <MshaEditUser
                handleClose={handleUserClose}
                user={selectedUser}
                organizationId={props.match.params.id}
              />
            }
          />
        )}

        {(selectedAsset != null || showAddAsset) && (
          <LynxDialog
            maxWidth={"md"}
            open={selectedAsset != null || showAddAsset}
            title={showAddAsset ? "Add Mine Site" : `Edit Mine Site`}
            fullWidth
            dialogContent={
              <MshaEditAsset
                handleClose={handleUserClose}
                asset={selectedAsset}
                organizationId={props.match.params.id}
              />
            }
          />
        )}
        {(!_.isEmpty(selectedEquipment) || showAddEquipment) && (
          <LynxDialog
            maxWidth={"md"}
            open={!_.isEmpty(selectedEquipment) || showAddEquipment}
            fullWidth
            dialogContent={
              <EquipmentModal
                entity={selectedEquipment}
                handleModalClose={handleUserClose}
                organizationId={props.match.params.id}
              />
            }
          />
        )}
      </Grid>
    );
}
const statusColorSwitch = (row) => {
  switch (_.toLower(row.status)) {
    case "active":
      return lynxColors.success;
    case "inactive":
      return lynxColors.error;
    default:
      return lynxColors.harvestOrange;
  }
};
