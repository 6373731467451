import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SquareIcon from "@mui/icons-material/Square";
import Typography from "@mui/material/Typography";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { Form } from "tabler-react";
import { LynxDialog } from "../../lynx-dialog";
export function MapSettingsSidebar(props) {
  const [showSourceDialog, setShowSourceDialog] = useState(false);
  const [selectedSource, setSelectedSource] = useState({});

  const columns = [
    { field: "name", headerName: "Name", type: "string", width: 150 },
    {
      field: "actions",
      type: "actions",

      width: 20,

      getActions: (params) => {
        return params.row.publisherLink || params.row.hasFilter
          ? [
              <GridActionsCellItem
                onClick={() => {
                  if (params.row.publisherLink) {
                    setShowSourceDialog(true);
                    setSelectedSource(params.row);
                  }
                  if (params.row.hasFilter) {
                    props.handleShowFilterDialog(params.row);
                  }
                }}
                icon={<InfoOutlinedIcon />}
                label="Info"
              />,
            ]
          : [];
      },
    },
  ];

  const getColorElement = () => {
    const paint =
      !_.isEmpty(selectedSource) && selectedSource.paintJson
        ? JSON.parse(selectedSource.paintJson)
        : {};

    if (selectedSource.layerType == "fill")
      return (
        <SquareIcon
          sx={{ color: paint["fill-color"], opacity: paint["fill-opacity"] }}
        />
      );
    if (selectedSource.layerType == "circle")
      return <CircleIcon sx={{ color: paint["circle-color"] }} />;
  };

  return (
    <div className={`map-sidebar-right `}>
      <div className="map-sidebar-header ">
        <Typography variant="h5" component="span">
          {props.action}
        </Typography>
        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {props.action == "Layers" && (
        <>
          <DataGridPro
            rows={props.layerLibrary}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            getRowId={(row) => row.key}
            hideFooter
            getRowHeight={() => "auto"}
            autoHeight={true}
            onSelectionModelChange={(ids) =>
              props.handleLayerSelectionChange(ids)
            }
            selectionModel={props.enabledLayers}
          />

          <div className="map-sidebar-header ">
            <Typography variant="h5" component="span">
              Base Map Style
            </Typography>
          </div>
          <Form.Group>
            <Form.Select
              name={`baseMapStyle`}
              onChange={props.handleBaseMapStyleChange}
              value={props.baseMapStyle.value}
            >
              {_.orderBy(props.mapStyles, ["label"]).map((style) => (
                <option value={style.value}>{style.label}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
      {props.action == "Geofence" && (
        <div className="pl-2">
          <Form.Group label="Geofence Radius (km)">
            <Form.Input
              type="number"
              name="radius"
              value={props.geofenceRadius}
              onChange={props.handleGeofenceRadiusChange}
            ></Form.Input>
          </Form.Group>
        </div>
      )}
      <LynxDialog
        open={showSourceDialog}
        title={selectedSource.name}
        handleConfirm={() => {
          setShowSourceDialog(false);
          setSelectedSource({});
        }}
        description={
          <div className="w-100">
            <div className="d-flex flex-col">
              <b>Color:</b> <span className="ml-1">{getColorElement()}</span>
            </div>
            <p style={{ wordBreak: "break-all", whiteSpace: "normal" }}>
              <a
                target="_blank"
                alt="Source link"
                rel="external nofollow noopener noreferrer"
                href={selectedSource.publisherLink}
              >
                {selectedSource.publisherLink}
              </a>
            </p>
            <p>
              <b>Publisher:</b> {selectedSource.publisherName}
            </p>
            <p>
              <b>Last updated date:</b> {selectedSource.lastUpdatedDate}
            </p>
            <p>
              <b>Update frequency:</b> {selectedSource.updateFrequency}
            </p>
          </div>
        }
      />
    </div>
  );
}
