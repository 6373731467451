import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { roleMatch } from "../../../actions/auth";
import {
  createContact,
  deleteContact,
  getContact,
  getContactGroups,
  patchContact,
} from "../../../services/contacts";
import { validationService } from "../../../services/validation";
import { UserRoles } from "../../../types/enums";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LocationSelectionControl } from "../../location-selection-control";
import { LynxDialog } from "../../lynx-dialog";
import { SearchableSelect } from "./../../form-controls/searchable-select";
import { ContactGroupModal } from "./contact-group-modal";
const initialForm = {
  fullName: "",
  companyName: "",
  phone: "",
  email: "",
  title: "",
  notes: "",
  fullAddress: "",
  latitude: "",
  longitude: "",
  contactTypeId: "",
  isInternal: false,
};

export function ContactForm(props) {
  const [formState, setFormState] = useState(initialForm);

  const [isLoading, setIsLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [contactTypes, setContactTypes] = useState([]);
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [contactGroups, setContactGroups] = useState([]);
  const [lookupsLoading, setIsLookupsLoading] = useState(true);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [locationExpanded, setLocationExpanded] = useState(false);

  useEffect(() => {
    if (isExistingContact()) {
      getContact(props.match.params.contactId).then((res) => {
        setFormStateFromProps(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadLookups();
  }, []);

  const loadLookups = () => {
    getContactGroups({}, true).then((res) => {
      setContactGroups(res.data);
      setIsLookupsLoading(false);
    });
  };

  const setFormStateFromProps = (contact) => {
    let newState = {
      fullName: contact.fullName,
      companyName: contact.companyName,
      contactGroupId: contact.contactGroupId,
      contactTypeId: contact.contactTypeId,
      phone: contact.phone,
      email: contact.email,
      title: contact.title,
      notes: contact.notes,
      fullAddress: contact.fullAddress,
      latitude: contact.latitude,
      longitude: contact.longitude,
      isInternal: contact.isInternal,
    };
    setFormState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value, checked } = e.target;
    if (name == "isInternal") {
      _.set(newState, name, checked);
    } else {
      _.set(newState, name, value);
    }

    setFormState(newState);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "fullName",
      "fullNameError",
      "Full Name"
    );

    validationService.validateEmailValue(
      formState.email,
      newState,
      "emailError",
      false
    );

    isFormValid = !validationService.hasError(
      newState,
      "fullNameError",
      "emailError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleGroupFormSave = (newGroup) => {
    setContactGroups([...contactGroups, newGroup]);
    setFormState({ ...formState, contactGroupId: newGroup.id });
    setShowAddGroupModal(false);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "fullNameError");

    if (!isExistingContact()) {
      createContact(formToSave)
        .then((res) => {
          alertify.success(`Contact Created`);
          if (props.modalMode) {
            props.handleSave(res.data);
          } else {
            handleNavigateTo(null, "/contacts");
          }
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
    if (isExistingContact()) {
      patchContact(props.match.params.contactId, formToSave)
        .then((res) => {
          alertify.success(`Contact Saved`);
          handleNavigateTo(null, "/contacts");
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const deleteItem = () => {
    deleteContact(props.match.params.contactId).then(() => {
      alertify.success(`Contact Deleted`);
      handleNavigateTo(null, "/contacts");
    });
  };

  const handleDelete = () => {
    setShowDelete(true);
  };
  const handleLatLongChange = (lat, long) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };
  const isExistingContact = () => {
    return props.modalMode ? false : props.match.params.contactId;
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const getContactGroupLabel = () => {
    return (
      <label className="form-label">
        Contact Group
        {!props.modalMode && (
          <Link
            href="#"
            className="float-right mr-6"
            underline="none"
            onClick={() => {
              setShowAddGroupModal(true);
            }}
          >
            + Or Add New
          </Link>
        )}
      </label>
    );
  };
  const getDetatilsForm = (
    <Grid.Row>
      <Grid.Col md={6} width={12}>
        <Form.Group isRequired label="Full Name">
          <Form.Input
            name="fullName"
            error={formState.fullNameError}
            onChange={handleInputChange}
            value={formState.fullName}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={12}>
        <Form.Group label={getContactGroupLabel()}>
          <SearchableSelect
            name={`contactGroupId`}
            options={contactGroups}
            labelField="name"
            idField="id"
            id={"contactGroupId"}
            value={formState.contactGroupId}
            placeholder={"Search or choose from list"}
            onChange={(e) => {
              setFormState({
                ...formState,
                contactGroupId: e.id ?? "",
              });
            }}
          />
        </Form.Group>
      </Grid.Col>
      {/* <Grid.Col md={6} width={12}>
        <Form.Group label="Contact Type">
          <Form.Select
            name="contactTypeId"
            onChange={handleInputChange}
            value={formState.contactTypeId}
          >
            <option value={""}></option>
            {contactTypes.map((type) => (
              <option value={type.id} key={type.id}>
                {type.code}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Grid.Col> */}
      <Grid.Col md={6} width={12}>
        <Form.Group label="Title">
          <Form.Input
            name="title"
            onChange={handleInputChange}
            value={formState.title}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={12}>
        <Form.Group label="Phone">
          <Form.Input
            name="phone"
            onChange={handleInputChange}
            value={formState.phone}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={12}>
        <Form.Group label="Email">
          <Form.Input
            name="email"
            error={formState.emailError}
            onChange={handleInputChange}
            value={formState.email}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        <Form.Group label="Notes">
          <Form.Textarea
            name="notes"
            onChange={handleInputChange}
            value={formState.notes}
          ></Form.Textarea>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        <Form.Group label="Full Address">
          <Form.Textarea
            name="fullAddress"
            onChange={handleInputChange}
            value={formState.fullAddress}
          ></Form.Textarea>
        </Form.Group>
      </Grid.Col>
    </Grid.Row>
  );
  const cardFooter = (
    <>
      {!isExistingContact() && (
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Add Contact
        </Button>
      )}
      {isExistingContact() && (
        <div>
          <div>
            {roleMatch([UserRoles.Admin]) && (
              <Button
                variant="contained"
                color="error"
                className="float-left"
                onClick={handleDelete}
              >
                Delete Contact
              </Button>
            )}
          </div>

          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            Save Contact
          </Button>
        </div>
      )}
    </>
  );
  if (props.modalMode) {
    return (
      <Dimmer active={isLoading || lookupsLoading} loader>
        {getDetatilsForm}
        {cardFooter}
      </Dimmer>
    );
  } else
    return (
      <Grid>
        <Dimmer active={isLoading || lookupsLoading} loader>
          {!props.modalMode && (
            <Paper>
              <Grid.Row className="ml-0 mr-0">
                <Grid.Col lg={12} width={12} className="">
                  <div className="d-flex">
                    <Typography
                      variant="h3"
                      className="form-header-text"
                      component="div"
                    >
                      {isExistingContact() ? "Edit Contact" : "Add Contact"}
                    </Typography>
                  </div>
                </Grid.Col>
                <Grid.Col lg={12} width={12} className="">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) =>
                        handleNavigateTo(e, "/contacts?view=contacts")
                      }
                    >
                      Contacts
                    </Link>
                    <Typography color="text.primary">
                      {isExistingContact() ? "Edit Contact" : "Add Contact"}
                    </Typography>
                  </Breadcrumbs>
                </Grid.Col>
              </Grid.Row>
            </Paper>
          )}

          <Grid.Row>
            <Container className="mt-2" maxWidth="xl">
              <Form className="card ">
                <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                  <Accordion
                    expanded={detailsExpanded}
                    onChange={() => setDetailsExpanded(!detailsExpanded)}
                    className="w-100"
                  >
                    <AccordionSummary
                      aria-controls="details-content"
                      id="details-header"
                    >
                      <Typography>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{getDetatilsForm}</AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={locationExpanded}
                    onChange={() => setLocationExpanded(!locationExpanded)}
                    className="w-100"
                  >
                    <AccordionSummary aria-controls="location-content">
                      <Typography>Map Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <LocationSelectionControl
                          handleLatLongChange={handleLatLongChange}
                          defaultLatitude={formState.latitude}
                          defaultLongitude={formState.longitude}
                        />
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                </Card.Body>
                <Card.Footer>{cardFooter}</Card.Footer>

                <LynxDialog
                  open={showDelete}
                  handleClose={() => setShowDelete(false)}
                  handleDelete={deleteItem}
                  title={`Delete Contact?`}
                  description={
                    "The contact will be removed from all associated events."
                  }
                />
              </Form>
            </Container>
          </Grid.Row>
        </Dimmer>
        {showAddGroupModal && (
          <ContactGroupModal
            open={showAddGroupModal}
            handleGroupFormSave={handleGroupFormSave}
            handleClose={() => setShowAddGroupModal(false)}
          />
        )}
      </Grid>
    );
}
