import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";

import { Divider } from "@mui/material";
import Chip from "@mui/material/Chip";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { roleMatch } from "../../../actions/auth";
import lynxColors from "../../../modules/lynxColors";
import { getAssetLookups } from "../../../services/assets";
import {
  getInspection,
  updateInspection,
} from "../../../services/inspection-event-service";
import { getMshaAssets } from "../../../services/msha-service";
import {
  getProgram,
  updateProgramDetails,
} from "../../../services/program-service";
import { CustomFieldTypes, EntityTypes, UserRoles } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { MultiSelect } from "../../form-controls/multi-select";
import { LynxDialog } from "../../lynx-dialog";
import { validationService } from "./../../../services/validation";
import { AttachmentViewer } from "./../../attachment-viewer";
import { InspectionFormField } from "./inspection-form-field";
import { programColumns } from "./program-columns";
export function ProgramDetails(props) {
  const { users } = useSelector((state) => state.lookups);

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [inspectionExpanded, setInspectionExpanded] = useState(true);
  const [inspectionState, setInspectionState] = useState({});
  const [assets, setAssets] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inspection, setInspection] = useState({});

  const [programFormState, setProgramFormState] = useState({});
  const [program, setProgram] = useState({});
  const [columns, setColumns] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleInspectionNumberClick = (row) => {
    props.history.push(`/program-forms/${row.id}`);
  };
  const navigateToProgramForm = () => {
    props.history.push(
      `/programs/${props.match.params.programId}/new-program-form`
    );
  };
  useEffect(() => {
    let newColumns = [...programColumns];
    let colIndex = newColumns.findIndex((x) => x.field == "inspectionNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="program-number-button"
          onClick={() => handleInspectionNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    if (roleMatch([UserRoles.MshaUser])) {
      newColumns.splice(2, 0, {
        field: "organizationName",
        headerName: "Client",
        width: 100,
        type: "string",
      });
    }
    setColumns(newColumns);
  }, [programColumns]);

  const formFields =
    !_.isEmpty(inspection) &&
    inspection.inspectionFormFields.filter(
      (x) =>
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Paragraph) &&
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Divider)
    );

  const formRef = useRef();
  var organization = useSelector((state) => state.organization);
  const isMshaAdminView = inspection.organizationId !== organization.id;
  const isProgramUrl =
    _.includes(window.location.href, "/programs") ||
    _.includes(window.location.href, "/new-program-form");

  useEffect(() => {
    if (!_.isEmpty(inspection) && organization && organization.id) {
      loadLookups();
    }
  }, [organization, inspection]);

  const loadProgram = () => {
    getProgram(props.match.params.programId).then((res) => {
      setProgram(res.data);
      setProgramFormState({
        assetId: res.data.assetId,
        responsibleUserIds: res.data.responsibleUserIds ?? [],
      });
      loadInspection(res.data.defaultInspectionEventId);
    });
  };
  useEffect(() => {
    loadProgram();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(users) && !_.isEmpty(program)) {
      if (program.responsibleUserIds) {
        setSelectedUsers(
          users.filter((x) => program.responsibleUserIds.includes(x.id))
        );
      }
    }
  }, [program, users]);

  useEffect(() => {
    if (!_.isEmpty(inspection) && !_.isEmpty(program)) {
      setInspectionEventStatesFromInspection();
    }
  }, [inspection]);

  const loadInspection = (id) => {
    getInspection(id).then((res) => {
      setInspection(res.data);
      setIsLoading(false);
    });
    setIsLoading(false);
  };

  const setInspectionEventStatesFromInspection = () => {
    // set new inspeciton state
    let newInspectionState = getFullStateFromInspectionDef();
    if (inspection.keyValuePairs) {
      var keyValuePairsObj = JSON.parse(inspection.keyValuePairs);
      newInspectionState = { ...newInspectionState, ...keyValuePairsObj };
    }
    setInspectionState(newInspectionState);
  };

  const getFullStateFromInspectionDef = () => {
    const notRequiredState = formFields
      .filter((x) => !x.isRequired)
      .map((field) => ({
        [field.name]: "",
      }));
    const requiredState = formFields
      .filter((x) => x.isRequired)
      .map((field) => ({
        [field.name]: "",
        [`${field.name}Error`]: "",
      }));

    const notRequiredSignatures = formFields
      .filter((x) => !x.isRequired && x.type == CustomFieldTypes.Signature)
      .map((field) => ({
        [field.name]: "",
      }));
    const requiredSignatures = formFields
      .filter((x) => x.isRequired && x.type == CustomFieldTypes.Signature)
      .map((field) => ({
        [field.name + "_signature"]: "",
        [`${field.name}_signatureError`]: "",
      }));

    const fullState = Object.assign(
      ...notRequiredState,
      ...requiredState,
      ...notRequiredSignatures,
      ...requiredSignatures
    );
    return fullState;
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...programFormState };
    let name = e.target.name;
    let value = e.target.value;

    _.set(newState, name, value);

    setProgramFormState(newState);
  };
  const handleInspectionInputChange = (e, sigName, sigInput) => {
    let newState = { ...inspectionState };
    let name = sigName ?? e.target.name;
    let value = sigName
      ? sigInput
      : e.type == "checkbox"
      ? e.target.checked
      : e.target.value;
    _.set(newState, name, value);
    setInspectionState(newState);
  };
  const loadLookups = () => {
    if (isMshaAdminView) {
      const params = {
        organizationId: inspection.organizationId,
        pageSize: 1000,
      };
      getMshaAssets(params).then((res) => {
        setAssets(res.data);
      });
    } else {
      getAssetLookups().then((res) => {
        setAssets(res.data);
      });
      setIsLoading(false);
    }
  };

  const getFormButtons = () => {
    return (
      <>
        {inspection.status != "Completed" ? (
          <>
            <Button
              onClick={handleSubmitProgram}
              variant="contained"
              className="float-right"
            >
              Submit
            </Button>
            <Button
              onClick={handleSaveDraft}
              variant="contained"
              color="success"
            >
              Save As Draft
            </Button>
          </>
        ) : (
          <Button
            onClick={handleSubmitProgram}
            variant="contained"
            color="success"
          >
            {isProgramUrl
              ? "Update Completed Program Form"
              : "Update Completed Inspection"}
          </Button>
        )}
      </>
    );
  };

  const handleSubmitProgram = () => {
    if (!validateSubmitDataForSave()) {
      return;
    }
    setIsSaving(true);
    let programToSave = validationService.unsetErrors(
      programFormState,
      "assetIdError"
    );
    const errorFields = getErrorFieldArray();
    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const formHtml = formRef.current.innerHTML;
    const dtoToSave = {
      program: programToSave,
      keyValuePairs: JSON.stringify(inspectionFormValuesToSave),
      completedFormHtml: formHtml,
    };

    updateInspection(program.defaultInspectionEventId, dtoToSave, true, true)
      .then((res) => {
        alertify.success("Program Saved.");
        setIsSaving(false);
        setInspection(res.data);
      })
      .catch((err) => {
        setIsSaving(false);
        alertify.error(err.response.data.message);
      });
  };

  const handleSaveDraft = () => {
    if (!validateDraftDataForSave()) {
      let newInspectionState = validationService.unsetErrors(
        inspectionState,
        ...getErrorFieldArray()
      );
      setInspectionState(newInspectionState);
      return;
    }
    setIsSaving(true);
    let programToSave = validationService.unsetErrors(
      programFormState,
      "assetIdError",
      "inspectionTypeIdError"
    );
    const errorFields = getErrorFieldArray();

    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const dtoToSave = {
      program: programToSave,
      keyValuePairs: JSON.stringify(inspectionFormValuesToSave),
      isProgramForm: isProgramUrl,
    };

    updateInspection(program.defaultInspectionEventId, dtoToSave, false, true)
      .then((res) => {
        alertify.success("Program Saved.");
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
        alertify.error(err.response.data.message);
      });
  };

  const getErrorFieldArray = () => {
    let errorFieldArray = formFields
      .filter((x) => x.isRequired)
      .map((field) => `${field.name}Error`);
    return errorFieldArray;
  };

  const validateSubmitDataForSave = () => {
    let newState = { ...inspectionState };
    let isFormValid = false;
    formFields
      .filter((x) => x.isRequired)
      .forEach((field) => {
        validationService.validateRequiredField(
          newState,
          field.name,
          `${field.name}Error`,
          field.label
        );
      });

    isFormValid = !validationService.hasError(
      newState,
      ...getErrorFieldArray()
    );

    let eventDataValid = validateDraftDataForSave(true);
    if (!isFormValid) {
      setInspectionState(newState);
    }
    if (!isFormValid || !eventDataValid) {
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid && eventDataValid;
  };

  const validateDraftDataForSave = (skipAlert = false) => {
    let newState = { ...programFormState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    let errorFields = ["assetIdError"];

    isFormValid = !validationService.hasError(newState, ...errorFields);
    if (!isFormValid) {
      setProgramFormState(newState);
      if (!skipAlert) {
        alertify.error("Form is not valid for saving.");
      }
    }
    return isFormValid;
  };

  const handleSaveDetails = () => {
    if (!validateDraftDataForSave()) {
      return;
    }
    setIsSaving(true);
    const dtoToSave = {
      assetId: programFormState.assetId,
      responsibleUserIds: selectedUsers.map((x) => x.id),
    };

    updateProgramDetails(props.match.params.programId, dtoToSave)
      .then((res) => {
        alertify.success("Program Details Updated.");
        setIsSaving(false);
        let newState = validationService.unsetErrors(
          programFormState,
          "assetIdError"
        );
        setProgramFormState(newState);
      })
      .catch((err) => {
        setIsSaving(false);
        alertify.error(err.response.data.message);
      });
  };

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {program.name}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/programs")}
                >
                  Programs
                </Link>
                <Typography color="text.primary">{program.name}</Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Container className="mt-2" maxWidth="xl">
            <Form className="card ">
              <Card.Body className="p-0">
                <Accordion
                  expanded={detailsExpanded}
                  onChange={() => setDetailsExpanded(!detailsExpanded)}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="details-content"
                    id="details-header"
                  >
                    <Typography>Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid.Row>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Mine Site">
                          <Form.Select
                            name="assetId"
                            onChange={handleInputChange}
                            value={programFormState.assetId}
                            error={programFormState.assetIdError}
                            disabled={true}
                          >
                            <option value={""}></option>
                            {assets.map((asset) => (
                              <option value={asset.id} key={asset.id}>
                                {asset.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>

                      <Grid.Col md={6} width={12}>
                        <Form.Group label="Responsible Person(s)">
                          <MultiSelect
                            name="selectedUsers"
                            id="selectedUsers"
                            onChange={(e) => {
                              setSelectedUsers(e.target.value);
                            }}
                            value={selectedUsers}
                            dropdownValues={users}
                            key="id"
                            label="fullName"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Button
                          onClick={handleSaveDetails}
                          variant="contained"
                          color="success"
                          className="float-right"
                        >
                          Save Details
                        </Button>
                      </Grid.Col>
                    </Grid.Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={inspectionExpanded}
                  onChange={() => setInspectionExpanded(!inspectionExpanded)}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="form-content"
                    id="form-header"
                  >
                    <Typography>Evaluations</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="pt-0">
                    <div style={{ height: 400 }}>
                      {!_.isEmpty(program) && (
                        <LynxDataGrid
                          noElevation
                          columns={columns}
                          hasNoTitle
                          data={program.inspectionEvents}
                          getDataParams={{
                            onlyShowProgramForms: true,
                          }}
                          addButtonText="Add Evaluation"
                          localStorageName={EntityTypes.Program}
                          entityName={EntityTypes.Program}
                          addButtonAction={navigateToProgramForm}
                          {...props}
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
                {!_.isEmpty(inspection.inspectionFormFields) && (
                  <Accordion
                    expanded={inspectionExpanded}
                    onChange={() => setInspectionExpanded(!inspectionExpanded)}
                    className="w-100"
                  >
                    <AccordionSummary
                      aria-controls="form-content"
                      id="form-header"
                    >
                      <Typography>
                        {isProgramUrl ? "Program Form" : "Inspection Form"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!isMshaAdminView && (
                        <Grid.Row>
                          <Grid.Col width={12}>
                            <div className="mb-4">{getFormButtons()}</div>
                          </Grid.Col>
                          <Grid.Col width={12}>
                            <Divider className="mb-2" />
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      <div ref={formRef}>
                        <Grid.Row>
                          <Grid.Col width={12}>
                            <Typography variant="h6" className="mb-2">
                              {inspection.inspectionFormTitle}
                            </Typography>
                          </Grid.Col>
                          {_.orderBy(inspection.inspectionFormFields, [
                            "position",
                          ]).map((field) => {
                            return (
                              <InspectionFormField
                                field={field}
                                signatureValue={
                                  field.type == CustomFieldTypes.Signature
                                    ? inspectionState[field.name + "_signature"]
                                    : null
                                }
                                value={inspectionState[field.name]}
                                handleInputChange={handleInspectionInputChange}
                                error={inspectionState[`${field.name}Error`]}
                                disabled={isMshaAdminView}
                              />
                            );
                          })}
                        </Grid.Row>
                      </div>
                      {!isMshaAdminView && (
                        <Grid.Row>
                          <Grid.Col width={12}>
                            <Divider className="mb-3" />
                          </Grid.Col>
                          <Grid.Col width={12}>
                            <div className="mb-0">{getFormButtons()}</div>
                          </Grid.Col>
                        </Grid.Row>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion className="w-100">
                  <AccordionSummary
                    aria-controls="attachments-content"
                    id="attachments-header"
                  >
                    <Typography>Attachments</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AttachmentViewer
                      isExistingEntity={true}
                      entityType={EntityTypes.Program}
                      entityId={props.match.params.programId}
                      cardClass="mb-0"
                      rowHeight={200}
                      cols={6}
                    />
                  </AccordionDetails>
                </Accordion>
              </Card.Body>
            </Form>
          </Container>
        </Grid.Row>
        {isSaving && (
          <LynxDialog
            open={isSaving}
            title={`Saving Inspection. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
      </Dimmer>
    </Grid>
  );
}
export const getStatusChip = (status) => {
  if (_.toLower(status) == "draft") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(status) == "incomplete") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }

  return <Chip label={status} color="primary" />;
};
