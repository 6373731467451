import { apiUrl, client } from "../modules/store";
export function getMshaOrganizations(params) {
  return client.get(apiUrl + "/msha/organizations", {
    params: params,
  });
}

export function createMshaOrganization(dto) {
  return client.post(`${apiUrl}/msha/organizations`, dto);
}

export function updateMshaOrganization(dto) {
  return client.put(`${apiUrl}/msha/organizations`, dto);
}
export function getMshaOrganizationById(id) {
  return client.get(apiUrl + "/msha/organizations/" + id);
}
export function getMshaUsers(params) {
  return client.get(apiUrl + "/msha/users", {
    params: params,
  });
}
export function deleteMshaUser(id) {
  return client.delete(apiUrl + "/msha/users/" + id);
}

export function disableMshaUser(id) {
  return client.post(apiUrl + "/msha/users/" + id + "/disable");
}
export function enableMshaUser(id) {
  return client.post(apiUrl + "/msha/users/" + id + "/enable");
}
export function updateMshaUser(id, dto) {
  return client.put(apiUrl + "/msha/users/" + id, dto);
}
export function getMshaAssets(params) {
  return client.get(apiUrl + "/msha/assets", {
    params: params,
  });
}
export function updateMshaAsset(id, dto) {
  return client.put(apiUrl + "/msha/assets/" + id, dto);
}

export function createMshaAsset(dto) {
  return client.post(`${apiUrl}/msha/assets`, dto);
}
export function createMshaUser(dto) {
  return client.post(`${apiUrl}/msha/users`, dto);
}
export function deleteMshaAsset(id) {
  return client.delete(apiUrl + "/msha/assets/" + id);
}
