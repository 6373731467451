import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import React from "react";
import { dateUtil } from "../../../services/date-util";
export function EventAdditionalFieldsDetails(props) {
  const getCustomFieldValue = (field) => {
    var field = props.event.eventCustomFieldValues.find(
      (x) => x.fieldName == field.fieldName
    );

    if (field) {
      switch (_.toLower(field.fieldType)) {
        case "date":
          return field.dateValue;

        case "datetime":
          return dateUtil.convertDateTimeToLocal(field.dateTimeValue);
        case "number":
          return field.numericValue;
        case "checkbox":
          return field.booleanValue == true ? "true" : "false";
        case "multiselect":
          return _.orderBy(field.textValue.split("|")).map((a) => (
            <Chip className="mr-1" label={a} variant="outlined" key={a} />
          ));
        default:
          return field.textValue;
      }
    }
    return "";
  };
  return (
    <div className="w-100  d-flex justify-content-center">
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardHeader
          className="lynx-card-header"
          title="Additional Fields"
          action={
            props.eventLocked ? (
              <LockIcon
                className="mt-3 mr-3"
                color="disabled"
                fontSize="small"
              />
            ) : (
              <Button
                id="editEventAdditionalDetailsBtn"
                onClick={() => props.handleEdit("additional-fields")}
                disabled={props.eventLocked}
              >
                Edit
              </Button>
            )
          }
        />

        <CardContent>
          <Grid container spacing={1}>
            {_.orderBy(props.customFields, ["position"]).map((field) => (
              <Grid item xs={12} sm={6} key={field.id}>
                <div className="details-label-text">{field.fieldLabel}</div>

                <div className="details-value-text">
                  {(!_.isEmpty(props.event) && getCustomFieldValue(field)) ||
                    "-"}
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
