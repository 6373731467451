import React from "react";
import { Link } from "react-router-dom";

export const MainFooter = (props) => {
  return (
    <div className="bottom-right-container">
      <div className="footer-content">
        <div className="mr-2 d-flex justify-content-end align-items-center flex-wrap-reverse">
          <Link to="/privacy">Privacy Policy</Link>
          <span className="ml-5">
            Copyright © 2024 Lynx Global Intelligence, Inc.
          </span>
        </div>
      </div>
    </div>
  );
};
