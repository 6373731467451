import { apiUrl, client } from "../modules/store";
import queryString from "query-string";
export function forgotPassword(email) {
  let emailObject = { email: email };
  return client.post(
    apiUrl + `/auth/forgotPassword?${queryString.stringify(emailObject)}`
  );
}

export function validatePasswordReset(passwordResetGuid) {
  return client.get(
    apiUrl +
      "/auth/validatePasswordReset?passwordResetGuid=" +
      passwordResetGuid
  );
}
export function getUserForRegister(registerGuid) {
  return client.get(apiUrl + "/auth/register?registerGuid=" + registerGuid);
}

export function registerUser(user, registerGuid) {
  return client.put(
    apiUrl + "/auth/register?registerGuid=" + registerGuid,
    user
  );
}
