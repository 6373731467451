import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import _ from "lodash";
import alertify from "alertifyjs";
import { validationService } from "../../../../services";
import { Grid, Card, Form, Dimmer } from "tabler-react";
import SingleSelect from "components/form-controls/single-select";
import { useAddUnitConversionMutation } from "services/rtkApi/endpoints/unitConversion";

const initialForm = {
  fromUnitId: "",
  toUnitId: "",
  conversionFactor: "",
};

export function AddUnitConversion(props) {
  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(true);
  const [addTrigger, { data, isLoading: isAddLoading }] =
    useAddUnitConversionMutation();

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const saveForm = () => {
    let formToSave = validationService.unsetErrors(
      formState,
      ...["fromUnitIdError", "toUnitIdError", "conversionFactorError"]
    );
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors

    formToSave.id = 0;

    addTrigger(formToSave).then((res) => {
      if (res.data) {
        alertify.success(`Unit Conversion Created.`);
        setFormState(initialForm);
      }

      if (res.error) {
        alertify.error(res.error?.data?.message);
      }
    });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "fromUnitId",
      "fromUnitIdError",
      "From Unit"
    );

    validationService.validateRequiredField(
      newState,
      "toUnitId",
      "toUnitIdError",
      "To Unit"
    );

    validationService.validateRequiredField(
      newState,
      "conversionFactor",
      "conversionFactorError",
      "Conversion Factor"
    );

    console.log(newState);

    isFormValid = !validationService.hasError(
      newState,
      ...["fromUnitIdError", "toUnitIdError", "conversionFactorError"]
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  useEffect(() => {
    setIsLoading(false);
  }, [props.unitList]);

  return (
    <Form className="w-30 h-auto">
      <Dimmer active={isLoading} loader>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={5} width={12}>
              <Form.Group label="From Unit" isRequired>
                <SingleSelect
                  onChange={handleInputChange}
                  dropdownValues={props.unitList.filter(
                    (f) => f.id != formState.toUnitId
                  )}
                  label="code"
                  id="id"
                  name="fromUnitId"
                  value={formState.fromUnitId}
                  error={formState.fromUnitIdError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={5} width={12}>
              <Form.Group label="To Unit" isRequired>
                <SingleSelect
                  onChange={handleInputChange}
                  dropdownValues={props.unitList.filter(
                    (f) => f.id != formState.fromUnitId
                  )}
                  label="code"
                  id="id"
                  name="toUnitId"
                  value={formState.toUnitId}
                  error={formState.toUnitIdError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={10} width={12}>
              <Form.Group label={"Conversion Factor"} isRequired>
                <Form.Input
                  type="number"
                  value={formState.conversionFactor}
                  name="conversionFactor"
                  onChange={handleInputChange}
                  error={formState.conversionFactorError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={2} width={12}>
              <Form.Group label="&nbsp;&nbsp;">
                <Button
                  variant="contained"
                  className="float-right"
                  onClick={saveForm}
                >
                  Add
                </Button>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Dimmer>
    </Form>
  );
}
