const { apiService } = require("../apiService");

const UNIT_CONVERSION_ROUTE = "/unitconversions";

const unitConversionEndpoints = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getUnitConversions: builder.query({
      query: () => UNIT_CONVERSION_ROUTE,
      providesTags: ["GetUnitConversions"],
    }),

    addUnitConversion: builder.mutation({
      query: (args) => ({
        url: UNIT_CONVERSION_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["GetUnitConversions"],
    }),

    updateUnitConversion: builder.mutation({
      query: (args) => ({
        url: UNIT_CONVERSION_ROUTE,
        method: "PUT",
        body: args,
      }),
      invalidatesTags: ["GetUnitConversions"],
    }),
    deleteUnitConversion: builder.mutation({
      query: (id) => ({
        url: `${UNIT_CONVERSION_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetUnitConversions"],
    }),
  }),
});

export const {
  useGetUnitConversionsQuery,
  useAddUnitConversionMutation,
  useUpdateUnitConversionMutation,
  useDeleteUnitConversionMutation,
} = unitConversionEndpoints;
