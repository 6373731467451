import { apiUrl, client } from "../modules/store";
export function acceptPrivacyPolicy(user) {
  return client.post(apiUrl + "/account/accept", {});
}

export function getUserGuide() {
  return client
    .get(`${apiUrl}/account/userguide`, {
      responseType: "blob",
    })
    .then((res) => res);
}

export function completeTour(tourName) {
  return client.post(`${apiUrl}/account/tour/${tourName}`, {});
}

export function resetTours() {
  return client.post(`${apiUrl}/account/reset-tours/`, {});
}
