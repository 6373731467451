import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layer, Map, Marker, NavigationControl, Source } from "react-map-gl";
import lynxColors from "../../../modules/lynxColors";
import { getAssetLayerById } from "../../../services/assets";
import { MAPBOX_TOKEN } from "../map/dashboard-map";
import Pin from "../map/pin";
import { useSelector } from "react-redux";
export function EventDetailsMap(props) {
  // prettier-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
  const targetRef = useRef();
  const mapRef = useRef(null);
  const [size, setSize] = useState([0, 0]);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 40,
    longitude: -100,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });
  const [assetLayer, setAssetLayer] = useState({});
  var organization = useSelector((state) => state.organization);
  useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;
      setSize([width, targetRef.current.offsetHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props.event)) {
      getAssetLayerGeojson(
        organization.multipleAssetEvents
          ? _.last(props.event.entityAssets).assetId
          : props.event.assetId
      );
      setViewport({
        ...viewport,
        latitude: props.event.latitude,
        longitude: props.event.longitude,
      });
    }
  }, [props.event]);
  const getAssetLayerGeojson = (assetId) => {
    getAssetLayerById(assetId).then((res) => {
      if (!_.isEmpty(res.data.geojson)) {
        setAssetLayer(JSON.parse(res.data.geojson));
      }
    });
  };
  return (
    <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
      <CardHeader
        className="lynx-card-header"
        title="Location"
        action={
          props.eventLocked ? (
            <LockIcon className="mt-3 mr-3" color="disabled" fontSize="small" />
          ) : (
            <Button
              onClick={() => props.handleEdit("location")}
              disabled={props.eventLocked}
            >
              Edit
            </Button>
          )
        }
      />

      <CardContent className="p-0">
        <div
          style={{
            height: "300px",
            width: "100%",
          }}
          ref={targetRef}
        >
          <Map
            {...viewport}
            mapStyle={"mapbox://styles/mapbox/light-v11"}
            ref={mapRef}
            style={{ width: size[0], height: size[1] }}
            mapboxAccessToken={MAPBOX_TOKEN}
            onMove={(nextViewport) => setViewport(nextViewport.viewState)}
          >
            <NavigationControl
              style={{
                right: 10,
                top: 10,
              }}
            />
            {!_.isEmpty(props.event) && (
              <Marker
                longitude={props.event.longitude}
                latitude={props.event.latitude}
              >
                <Pin size={20} />
              </Marker>
            )}
            {!_.isEmpty(assetLayer) && (
              <>
                <Source type="geojson" data={assetLayer}>
                  <Layer
                    type="fill"
                    filter={["==", "$type", "Polygon"]}
                    paint={{
                      "fill-color": lynxColors.harvestOrange,
                      "fill-opacity": 0.3,
                    }}
                  />
                </Source>
                <Source type="geojson" data={assetLayer}>
                  <Layer
                    type="line"
                    filter={["==", "$type", "LineString"]}
                    paint={{
                      "line-color": lynxColors.harvestOrange,
                    }}
                  />
                </Source>
                <Source type="geojson" data={assetLayer}>
                  <Layer
                    type="circle"
                    filter={["==", "$type", "Point"]}
                    paint={{
                      "circle-color": lynxColors.harvestOrange,
                    }}
                  />
                </Source>
              </>
            )}
          </Map>
        </div>
      </CardContent>
    </Card>
  );
}
