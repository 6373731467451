import { apiUrl, client } from "../modules/store";
const url = `${apiUrl}/correspondenceLogs`;
export function createCorrespondenceLog(dto) {
  return client.post(url, dto);
}
export function updateCorrespondenceLog(id, dto) {
  return client.put(`${url}/${id}`, dto);
}

export function getCorrespondenceLogs(params) {
  return client.get(`${url}`, { params: params });
}
export function deleteCorrespondenceLog(id) {
  return client.delete(`${url}/${id}`);
}
