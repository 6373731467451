import * as React from "react";
import _ from "lodash";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  GridLinkOperator,
  useGridApiContext,
  GridAddIcon,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
  GridFilterForm,
  useGridRootProps,
  useGridSelector,
  gridFilterModelSelector,
  gridFilterableColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
const CustomFilterPanel = React.forwardRef(function GridFilterPanel(
  props,
  ref
) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterableColumns = useGridSelector(
    apiRef,
    gridFilterableColumnDefinitionsSelector
  );
  const lastFilterRef = React.useRef(null);

  const {
    linkOperators = [GridLinkOperator.And, GridLinkOperator.Or],
    columnsSort,
    filterFormProps,
    ...other
  } = props;

  const applyFilter = React.useCallback(
    (item) => {
      apiRef.current.upsertFilterItem(item);
    },
    [apiRef]
  );

  const applyFilterLinkOperator = React.useCallback(
    (operator) => {
      apiRef.current.setFilterLinkOperator(operator);
    },
    [apiRef]
  );

  const getDefaultItem = React.useCallback(() => {
    const firstColumnWithOperator = filterableColumns.find(
      (colDef) => colDef.filterOperators?.length
    );

    if (!firstColumnWithOperator) {
      return null;
    }

    return {
      columnField: firstColumnWithOperator.field,
      operatorValue: firstColumnWithOperator.filterOperators[0].value,
      id: Math.round(Math.random() * 1e5),
    };
  }, [filterableColumns]);

  const items = React.useMemo(() => {
    if (filterModel.items.length) {
      return filterModel.items;
    }

    const defaultItem = getDefaultItem();

    return defaultItem ? [defaultItem] : [];
  }, [filterModel.items, getDefaultItem]);

  const hasMultipleFilters = items.length > 1;

  const addNewFilter = () => {
    const defaultItem = getDefaultItem();
    if (!defaultItem) {
      return;
    }
    apiRef.current.upsertFilterItems([...items, defaultItem]);
  };

  const deleteFilter = React.useCallback(
    (item) => {
      const shouldCloseFilterPanel = items.length === 1;
      apiRef.current.deleteFilterItem(item);
      if (shouldCloseFilterPanel) {
        apiRef.current.hideFilterPanel();
      }
    },
    [apiRef, items.length]
  );

  React.useEffect(() => {
    if (
      linkOperators.length > 0 &&
      filterModel.linkOperator &&
      !linkOperators.includes(filterModel.linkOperator)
    ) {
      applyFilterLinkOperator(linkOperators[0]);
    }
  }, [linkOperators, applyFilterLinkOperator, filterModel.linkOperator]);

  React.useEffect(() => {
    if (items.length > 0) {
      lastFilterRef.current.focus();
    }
  }, [items.length]);

  const isFilterEmpty = () => {
    return _.isEmpty(
      props.filterModel.items.filter(
        (x) =>
          x.value ||
          x.operatorValue == "isEmpty" ||
          x.operatorValue == "isNotEmpty"
      )
    );
  };

  return (
    <GridPanelWrapper ref={ref} {...other}>
      <GridPanelContent>
        <Typography variant="h6" className="ml-3 mt-1">
          {!_.isEmpty(props.selectedFilter)
            ? props.selectedFilter.name
            : "New Filter"}
        </Typography>
        {items.map((item, index) => (
          <GridFilterForm
            key={item.id == null ? index : item.id}
            item={item}
            applyFilterChanges={applyFilter}
            deleteFilter={deleteFilter}
            hasMultipleFilters={hasMultipleFilters}
            showMultiFilterOperators={index > 0}
            multiFilterOperator={filterModel.linkOperator}
            disableMultiFilterOperator={index !== 1}
            applyMultiFilterOperatorChanges={applyFilterLinkOperator}
            focusElementRef={index === items.length - 1 ? lastFilterRef : null}
            linkOperators={linkOperators}
            columnsSort={columnsSort}
            {...filterFormProps}
          />
        ))}
      </GridPanelContent>
      {!rootProps.disableMultipleColumnsFiltering && (
        <GridPanelFooter>
          <rootProps.components.BaseButton
            onClick={addNewFilter}
            startIcon={<GridAddIcon />}
            {...rootProps.componentsProps?.baseButton}
          >
            {apiRef.current.getLocaleText("filterPanelAddFilter")}
          </rootProps.components.BaseButton>

          <Stack spacing={2} direction="row">
            {!_.isEmpty(props.selectedFilter) && (
              <Button
                className="align-self-end"
                color="error"
                onClick={props.showDeleteConfirmation}
                disabled={props.selectedFilter.isInUse}
              >
                Delete
              </Button>
            )}
            {props.enableSaveFilters && (
              <Button
                className="align-self-end"
                onClick={
                  !isFilterEmpty()
                    ? props.handleSaveFilter
                    : props.handleLoadFilter
                }
              >
                {!isFilterEmpty() ? "Save Filter" : "Load Filter"}
              </Button>
            )}
          </Stack>
        </GridPanelFooter>
      )}
    </GridPanelWrapper>
  );
});

export default CustomFilterPanel;
