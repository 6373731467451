import React, { useState } from "react";
import { Grid, Card, Form } from "tabler-react";
import { createLookup } from "../../../../services/lookup";
import Button from "@mui/material/Button";
import _ from "lodash";
import alertify from "alertifyjs";
import { validationService } from "../../../../services";

export function EventSubCategoryModel(props) {
  const initialForm = {
    code: "",
    description: "",
    parentLookupId: "",
    codeError: "",
  };
  const [formState, setFormState] = useState(initialForm);

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "codeError",
      "parentLookupIdError"
    );
    if (props.id > 0) {
    } else {
      formToSave.id = 0;
      formToSave.lookupType = "EventSubCategory";
      createLookup(formToSave)
        .then((res) => {
          alertify.success("Event subcategory created");
          props.handleAddEventSubCategory(true);
          setFormState(initialForm);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      "Subcategory"
    );
    validationService.validateRequiredField(
      newState,
      "parentLookupId",
      "parentLookupIdError",
      "Category"
    );

    isFormValid = !validationService.hasError(
      newState,
      "codeError",
      "parentLookupIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="w-30" id="incident-type-form">
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={5} width={12}>
            <Form.Group label="Category">
              <Form.Select
                name="parentLookupId"
                onChange={handleInputChange}
                value={formState.parentLookupId}
                error={formState.parentLookupIdError}
              >
                <option value={""}></option>
                {props.categories.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={5} width={12}>
            <Form.Group label="Subcategory">
              <Form.Input
                type="text"
                value={formState.code}
                name="code"
                onChange={handleInputChange}
                error={formState.codeError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Form.Group label="Description">
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={2} width={12}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
