import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
export default function PageNotFound(props) {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            {props.message ?? "Page not found"}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
