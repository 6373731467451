import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import lynxColors from "../modules/lynxColors";
export const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: !error ? "1px solid #ced4da" : "1px solid #cd201f",
    fontSize: "0.9375rem",
    padding: "0.375rem 0.75rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const BootstrapDateInput = styled(DatePicker)(({ theme, error }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: !error ? "1px solid #ced4da" : "1px solid #cd201f",
    fontSize: 16,
    padding: "0.375rem 0.75rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&:disabled": {
      backgroundColor: lynxColors.disabled,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": { display: "none" },
}));
