import React, { isValidElement } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export function LynxDialog(props) {
  const handleClose = (e, reason) => {
    if (reason == "backdropClick" && !props.closeOnBackdropClick) {
      return;
    }
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      maxWidth={props.maxWidth ?? "sm"}
      fullWidth={props.fullWidth ?? false}
      onClose={handleClose}
      className="lynx-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.title}
        {props.isCloseInHeader ? (
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent dividers={props.dividers}>
        {props.dialogContent ? (
          props.dialogContent
        ) : isValidElement(props.description) ? (
          props.description
        ) : (
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        )}
      </DialogContent>
      {!props.hasNoActions && (
        <DialogActions>
          {((props.handleClose && !props.isCloseInHeader) ||
            props.handleCancel) && (
            <Button
              onClick={props.handleClose ?? props.handleCancel}
              color={props.handleCancel ? "error" : "primary"}
              variant="contained"
            >
              Cancel
            </Button>
          )}

          {props.handleDelete && !props.disableDelete && (
            <Button
              onClick={props.handleDelete}
              variant="contained"
              color="error"
              className="confirm-delete-button"
            >
              Delete
            </Button>
          )}
          {props.handleConfirm && (
            <Button
              onClick={props.handleConfirm}
              variant="contained"
              color="success"
              sx={{ color: "white" }}
              disabled={props.confirmDisabled}
            >
              OK
            </Button>
          )}
          {props.handleSave && (
            <Button
              onClick={props.handleSave}
              variant="contained"
              sx={{ color: "white" }}
            >
              {props.saveButtonText ?? "Save"}
            </Button>
          )}
          {props.handleDownload && (
            <Button
              onClick={props.handleDownload}
              variant="contained"
              color="success"
            >
              Download
            </Button>
          )}
          {props.buttons && <props.buttons />}
        </DialogActions>
      )}
    </Dialog>
  );
}
