import moment from "moment";
export class DateUtil {
  convertDateTimeToLocal(dateTime) {
    if (!dateTime) {
      return "";
    }

    return moment.utc(dateTime).local().format("YYYY-MM-DD HH:mm");
  }
  convertDateTimeToLocalDateOnly(dateTime) {
    if (!dateTime) {
      return "";
    }

    return moment.utc(dateTime).local().format("YYYY-MM-DD");
  }
  convertDateOnlyToLocal(dateOnly) {
    if (!dateOnly) {
      return "";
    }
    return dateOnly;
  }
}

export const dateUtil = new DateUtil();
