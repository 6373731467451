import { MenuList } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import React, { useState, useEffect } from "react";
import moment from "moment";
import TimeAgo from "javascript-time-ago";

import _ from "lodash";
import { useHistory } from "react-router";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AdjustIcon from "@mui/icons-material/Adjust";
import Checkbox from "@mui/material/Checkbox";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Tooltip from "@mui/material/Tooltip";

import { localStorageService } from "./../services/local-storage-service";
const showUnreadLocalStorage = "show_unread_notifications";

export function NotificationsMenu(props) {
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const timeAgo = new TimeAgo("en-US");
  const history = useHistory();

  useEffect(() => {
    let unreadSetting = localStorageService.getLocalStorage(
      showUnreadLocalStorage
    );
    if (!_.isEmpty(props.notifications)) {
      let newNotifications = props.notifications;
      if (unreadSetting == true) {
        newNotifications = newNotifications.filter((x) => !x.isRead);
      }

      setNotifications(newNotifications);
    }
    setOnlyShowUnread(unreadSetting);
  }, [props.notifications]);
  const getInitials = (fullName) => {
    if (fullName) {
      return fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
    }
    return "";
  };

  const navigateToEventAndMarkAsRead = (notification, e) => {
    if (e.target.type == "checkbox") return;
    //mark as read via props
    props.handleMarkAsRead(notification);
    //

    if (notification.linkedEventId > 0) {
      history.push(`/events/${notification.linkedEventId}?view=details`);
    }
    if (notification.linkedMonitoringEventId > 0) {
    }
  };

  const handleNavigateToNotifications = (e) => {
    e.preventDefault();
    props.handleNotificationClose();
    history.push("/notifications");
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleToggleChange = (e) => {
    let value = e.target.checked;
    localStorageService.setLocalStorage(showUnreadLocalStorage, value);
    setOnlyShowUnread(value);
    if (value == true) {
      let newNotifications = [...props.notifications];
      newNotifications = newNotifications.filter((x) => x.isRead == false);
      setNotifications(newNotifications);
    }
    if (value == false) {
      setNotifications(props.notifications);
    }
  };

  return (
    <MenuList
      sx={{
        width: "100%",
        maxWidth: 460,
        minWidth: 200,
        bgcolor: "background.paper",
      }}
      dense
      subheader={
        <ListSubheader
          sx={{ lineHeight: 2 }}
          component="div"
          id="nested-list-subheader"
        >
          Notifications
          <div className="float-right">
            <Stack direction="row" spacing={1} alignItems="center">
              <span className="mr-1">Only show unread</span>
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={onlyShowUnread}
                onChange={handleToggleChange}
              />
            </Stack>
          </div>
          {notifications.length > 0 && (
            <div>
              Latest
              <div className="float-right">
                <Stack direction="row" spacing={1} alignItems="center">
                  {notifications.any}
                  {notifications.some((x) => !x.isRead) && (
                    <span className="mr-1">
                      <Link
                        href="#"
                        underline="none"
                        color="inherit"
                        onClick={props.handleMarkAllAsRead}
                      >
                        <Typography variant="caption">
                          Mark all as read
                        </Typography>
                      </Link>
                    </span>
                  )}
                </Stack>
              </div>
            </div>
          )}
        </ListSubheader>
      }
    >
      <Divider component="li" />
      {notifications.map((notification, i) => (
        <div>
          <ListItem
            alignItems="flex-start"
            className="notification-list-item"
            onClick={(e) => navigateToEventAndMarkAsRead(notification, e)}
          >
            <ListItemAvatar>
              <Avatar src="/static/images/avatar/2.jpg">
                {getInitials(notification.triggeredByUserFullName)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div className="d-flex ">
                  <div>
                    <span
                      className="mr-1"
                      dangerouslySetInnerHTML={{ __html: notification.subject }}
                    ></span>
                    <div style={{ fontWeight: "normal" }}>
                      {timeAgo.format(
                        _.toNumber(
                          moment
                            .utc(notification.notificationDateTimeUtc)
                            .valueOf()
                        )
                      )}
                    </div>
                  </div>
                </div>
              }
              primaryTypographyProps={{
                fontSize: 13,
                fontWeight: "medium",
                letterSpacing: 0,
              }}
              secondaryTypographyProps={{
                fontSize: 13,
                component: "span",
              }}
              secondary={
                <div
                  dangerouslySetInnerHTML={{ __html: notification.body }}
                ></div>
              }
            />{" "}
            <div>
              {" "}
              <Tooltip
                title={!notification.isRead ? "Mark as read" : "Mark as unread"}
              >
                <Checkbox
                  checked={!notification.isRead}
                  onChange={(e) =>
                    props.handleNotificationReadChange(
                      notification.id,
                      !e.target.checked
                    )
                  }
                  icon={<CircleOutlinedIcon sx={{ fontSize: 15 }} />}
                  checkedIcon={<AdjustIcon sx={{ fontSize: 15 }} />}
                  className="float-right"
                />
              </Tooltip>
            </div>
          </ListItem>
          <Divider
            variant={i < props.notifications.length - 1 ? "inset" : ""}
            component="li"
          />
        </div>
      ))}
      <ListItem>
        <Typography variant="body2">
          That's all your notifications from the last 30 days.
        </Typography>
      </ListItem>
    </MenuList>
  );
}
