import { apiUrl, client } from "../modules/store";
import { CustomFieldTypes } from "./../types/enums";
import _ from "lodash";
import moment from "moment";
const url = `${apiUrl}/inspectionForms`;

export function getInspectionForms(params) {
  return client.get(url, {
    params: params,
  });
}
export function createInspectionForm(dto) {
  return client.post(url, dto);
}
export function updateInspectionForm(id, dto) {
  return client.put(`${url}/${id}`, dto);
}
export function getInspectionForm(id) {
  return client.get(url + "/" + id);
}
export function deleteInspectionForm(id) {
  return client.delete(`${url}/${id}`);
}
export const getFormFields = (fields, forValues = false) => {
  if (forValues) {
    return fields.filter(
      (x) =>
        _.toLower(x.inspectionFormFieldType) !=
          _.toLower(CustomFieldTypes.Paragraph) &&
        _.toLower(x.inspectionFormFieldType) !=
          _.toLower(CustomFieldTypes.Divider)
    );
  } else {
    return fields.filter(
      (x) =>
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Paragraph) &&
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Divider)
    );
  }
};

const textTypes = [
  CustomFieldTypes.Text,
  CustomFieldTypes.TextArea,
  CustomFieldTypes.Dropdown,
  CustomFieldTypes.MultiSelect,
  CustomFieldTypes.Signature,
];
const numberTypes = [CustomFieldTypes.Number];
const dateTimeTypes = [CustomFieldTypes.DateTime];
const dateTypes = [CustomFieldTypes.Date];
const booleanTypes = [CustomFieldTypes.Checkbox];

export const convertInspectionStateToValueObject = (fields, state) => {
  fields = getFormFields(fields);
  var objectList = [];
  fields.forEach((field) => {
    var fieldValue = state[field.name];
    var sigValue = null;
    if (_.toLower(field.type) == _.toLower(CustomFieldTypes.Signature)) {
      sigValue = state[`${field.name}_signature`];
    }
    if (fieldValue || fieldValue === true || sigValue) {
      var objectToCreate = {
        inspectionFormFieldName: field.name,
        textValue: null,
        numericValue: null,
        dateTimeValue: null,
        dateValue: null,
        booleanValue: null,
      };
      if (textTypes.map((a) => _.toLower(a)).includes(_.toLower(field.type))) {
        objectToCreate.textValue = fieldValue;
        if (_.toLower(field.type) == _.toLower(CustomFieldTypes.Signature)) {
          objectToCreate.signatureValue = state[`${field.name}_signature`];
        }
      }
      if (
        numberTypes.map((a) => _.toLower(a)).includes(_.toLower(field.type))
      ) {
        objectToCreate.numericValue = fieldValue;
      }
      if (
        dateTimeTypes.map((a) => _.toLower(a)).includes(_.toLower(field.type))
      ) {
        objectToCreate.dateTimeValue = fieldValue
          ? moment(fieldValue).utc().format()
          : "";
      }
      if (dateTypes.map((a) => _.toLower(a)).includes(_.toLower(field.type))) {
        objectToCreate.dateValue = fieldValue;
      }
      if (
        booleanTypes.map((a) => _.toLower(a)).includes(_.toLower(field.type))
      ) {
        objectToCreate.booleanValue = fieldValue;
      }
      objectList.push(objectToCreate);
    }
  });
  return objectList;
};
export const getStateFromExistingInspection = (values) => {
  const formFields = getFormFields(values, true);

  const textState = formFields
    .filter((x) =>
      textTypes
        .map((a) => _.toLower(a))
        .includes(_.toLower(x.inspectionFormFieldType))
    )
    .map((field) => ({
      [field.inspectionFormFieldName]: field.textValue,
    }));

  const sigState = formFields
    .filter(
      (x) =>
        _.toLower(x.inspectionFormFieldType) ==
        _.toLower(CustomFieldTypes.Signature)
    )
    .map((field) => ({
      [`${field.inspectionFormFieldName}_signature`]: field.signatureValue,
    }));

  const numberState = formFields
    .filter((x) =>
      numberTypes
        .map((a) => _.toLower(a))
        .includes(_.toLower(x.inspectionFormFieldType))
    )
    .map((field) => ({
      [field.inspectionFormFieldName]: field.numericValue,
    }));
  const dateTimeState = formFields
    .filter((x) =>
      dateTimeTypes
        .map((a) => _.toLower(a))
        .includes(_.toLower(x.inspectionFormFieldType))
    )
    .map((field) => ({
      [field.inspectionFormFieldName]: field.dateTimeValue
        ? moment(field.dateTimeValue).format("YYYY-MM-DD HH:mm")
        : "",
    }));
  const dateState = formFields
    .filter((x) =>
      dateTypes
        .map((a) => _.toLower(a))
        .includes(_.toLower(x.inspectionFormFieldType))
    )
    .map((field) => ({
      [field.inspectionFormFieldName]: field.dateValue,
    }));
  const booleanState = formFields
    .filter((x) =>
      booleanTypes
        .map((a) => _.toLower(a))
        .includes(_.toLower(x.inspectionFormFieldType))
    )
    .map((field) => ({
      [field.inspectionFormFieldName]: field.booleanValue,
    }));
  if (
    !_.isEmpty(textState) ||
    !_.isEmpty(numberState) ||
    !_.isEmpty(dateTimeState) ||
    !_.isEmpty(dateState) ||
    !_.isEmpty(booleanState) ||
    !_.isEmpty(sigState)
  ) {
    const fullState = Object.assign(
      ...textState,
      ...numberState,
      ...dateTimeState,
      ...dateState,
      ...booleanState,
      ...sigState
    );
    return fullState;
  }
  return {};
};

export const getFullStateFromInspectionDef = (fields) => {
  const formFields = getFormFields(fields);
  const notRequiredState = formFields
    .filter((x) => !x.isRequired)
    .map((field) => ({
      [field.name]: "",
    }));
  const requiredState = formFields
    .filter((x) => x.isRequired)
    .map((field) => ({
      [field.name]: "",
      [`${field.name}Error`]: "",
    }));

  const notRequiredSignatures = formFields
    .filter((x) => !x.isRequired && x.type == CustomFieldTypes.Signature)
    .map((field) => ({
      [field.name + "_signature"]: "",
    }));
  const requiredSignatures = formFields
    .filter((x) => x.isRequired && x.type == CustomFieldTypes.Signature)
    .map((field) => ({
      [field.name + "_signature"]: "",
      [`${field.name}_signatureError`]: "",
    }));

  const fullState = Object.assign(
    ...notRequiredState,
    ...requiredState,
    ...notRequiredSignatures,
    ...requiredSignatures
  );
  return fullState;
};

export const getErrorFieldArray = (fields) => {
  let errorFieldArray = getFormFields(fields)
    .filter((x) => x.isRequired)
    .map((field) => `${field.name}Error`);
  let sigErrorFields = getFormFields(fields)
    .filter((x) => x.isRequired && x.type == CustomFieldTypes.Signature)
    .map((field) => `${field.name}_signatureError`);
  return [...errorFieldArray, ...sigErrorFields];
};
