import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import _ from "lodash";
import React, { useState } from "react";

export function ReportsModal(props) {
  const [selectedReportId, setSelectedReportId] = useState(0);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <FormControl fullWidth className="mt-2">
          <InputLabel id="filter-select-label">Select Report</InputLabel>
          <Select
            labelId="filter-report-label"
            id="report-select"
            value={selectedReportId}
            label="Select Report"
            onChange={(e) => setSelectedReportId(e.target.value)}
            sx={{ minWidth: "500" }}
          >
            <MenuItem value={0}>&nbsp;</MenuItem>
            {_.sortBy(props.reports, ["name"]).map((report) => (
              <MenuItem value={report.id} key={report.id}>
                {report.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={() => props.handleRunReport(selectedReportId)}>
          Run
        </Button>
      </DialogActions>
    </Dialog>
  );
}
