import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip, { chipClasses } from "@mui/material/Chip";
import MuiGrid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dimmer, Grid } from "tabler-react";
import { LynxDialog } from "../../lynx-dialog";
import {
  createEntityTag,
  deleteEntityTag,
  getEntityTags,
  searchTags,
} from "./../../../services/tag-service";
import { TagQuickCard } from "./tag-quick-card";
import { TagModal } from "../settings/ComplianceSettings/tag-modal";
export function Tags(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [existingTags, setExistingTags] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTag, setSelectedTag] = useState({});
  const [autoCompleteTagValue, setAutoCompleteTagValue] = useState(null);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [autoCompleteTagInputValue, setAutoCompleteTagInputValue] =
    useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    if (props.entityId && props.entityType && !props.isLoading) {
      getEntityTags(props.entityType, props.entityId).then((res) => {
        setExistingTags(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.entityId, props.entityType, props.isLoading]);

  useEffect(() => {
    if (autoCompleteTagInputValue === "") {
      setOptions(autoCompleteTagValue ? [autoCompleteTagValue] : []);
      return;
    }
    setTagsLoading(true);
    searchTags(autoCompleteTagInputValue).then((res) => {
      setOptions(res.data);
      setTagsLoading(false);
    });
  }, [autoCompleteTagValue, autoCompleteTagInputValue]);
  const handleTagDeleted = (tag) => {
    let newTags = _.cloneDeep(existingTags);
    let index = newTags.findIndex((x) => x.lookupId == tag.lookupId);
    newTags.splice(index, 1);
    if (props.entityId) {
      setIsLoading(true);
      deleteEntityTag(existingTags[index].id).then((res) => {
        setExistingTags(res.data);
        setIsLoading(false);
        alertify.success("Associated Tag Removed.");
      });
    } else {
      setExistingTags();
      props.handleSetTags(newTags);
      setExistingTags(newTags);
    }
  };

  const handleTagSelected = (e, value) => {
    if (value) {
      setAutoCompleteTagValue(null);
      let newTags = _.cloneDeep(existingTags);
      let existingTag = newTags.find((x) => x.lookupId == value.id);
      if (!existingTag) {
        const tagsToSet = [
          ...newTags,
          {
            lookupId: value.id,
            lookupCode: value.code,
            parentLookupCode: value.parentLookupCode,
          },
        ];
        if (props.entityId) {
          setIsLoading(true);
          createEntityTag({
            eventId: props.entityId,
            lookupId: value.id,
          }).then((res) => {
            setExistingTags(res.data);
            alertify.success("Tag Added.");
            setIsLoading(false);
          });
        } else {
          setExistingTags(tagsToSet);
          props.handleSetTags(tagsToSet);
        }
      }
    }
  };

  const autoCompleteTagInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteTagValue(null);

      return;
    } else {
      setAutoCompleteTagInputValue(newValue);
    }
  };

  const getTagOptions = () => {
    var tagOptions = options.filter(
      (x) => !existingTags.map((y) => y.lookupId).includes(x.id)
    );
    return tagOptions;
  };

  const handleTagClick = (event, tag) => {
    setAnchorEl(event.currentTarget);
    setSelectedTag(tag);
  };
  const handleAddTag = () => {
    setShowAddTag(false);
  };
  return (
    <Dimmer active={isLoading || props.isLoading} loader>
      <Grid.Row>
        {!_.isEmpty(existingTags) && (
          <Grid.Col md={12} width={12} className="mb-1">
            <MuiGrid container spacing={1}>
              {_.sortBy(existingTags, [
                (tag) => tag.lookupCode.toLowerCase(),
              ]).map((tag) => (
                <MuiGrid item key={tag.id}>
                  <Chip
                    onClick={(e) => handleTagClick(e, tag)}
                    label={`${
                      tag.parentLookupCode ? tag.parentLookupCode + " - " : ""
                    }${tag.lookupCode}`}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    {...(!props.eventLocked
                      ? {
                          onDelete: () => handleTagDeleted(tag),
                        }
                      : {})}
                  />
                </MuiGrid>
              ))}
            </MuiGrid>
          </Grid.Col>
        )}
        <Grid.Col md={12} width={12} className="mb-2 mt-1">
          {/* Add "search" to ID to disabled autofill / lastpass */}
          <Autocomplete
            filterOptions={(x) => x}
            loading={tagsLoading}
            disabled={props.eventLocked}
            id="tag-grouped-search"
            options={getTagOptions()}
            noOptionsText={
              !autoCompleteTagInputValue ? "Enter text to search" : "No options"
            }
            getOptionLabel={(option) => option.name + " " + option.tagGroupName}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.parentLookupCode && (
                  <Typography>
                    {option.parentLookupCode}&nbsp;-&nbsp;
                  </Typography>
                )}

                {option.code}
                {option.childLookupCode && (
                  <Typography variant="body2">
                    &nbsp;({option.childLookupCode})
                  </Typography>
                )}
              </Box>
            )}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search tags by tag, tag group, or tag category"
              />
            )}
            onChange={handleTagSelected}
            value={autoCompleteTagValue}
            inputValue={autoCompleteTagInputValue}
            onInputChange={autoCompleteTagInputChange}
          />
        </Grid.Col>
        <Grid.Col
          md={12}
          width={12}
          className="mb-2 mt-1 align-items-end d-flex"
        >
          <Button
            variant="outlined"
            onClick={() => {
              setShowAddTag(true);
            }}
          >
            Create New Tag
          </Button>
        </Grid.Col>
        {showAddTag && (
          <LynxDialog
            dividers
            hasNoActions
            isCloseInHeader
            open={showAddTag}
            handleClose={() => setShowAddTag(false)}
            title={`Add Tag`}
            maxWidth="md"
            dialogContent={
              <TagModal handleAddTag={handleAddTag} isForEvent={true} />
            }
          />
        )}
      </Grid.Row>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {!_.isEmpty(selectedTag) && <TagQuickCard id={selectedTag.lookupId} />}
      </Popover>
    </Dimmer>
  );
}
