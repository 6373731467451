import { apiUrl, client } from "../modules/store";

export function getTags(params) {
  return client.get(apiUrl + "/tags", {
    params: params,
  });
}
export function createTag(dto) {
  return client.post(apiUrl + "/tags", {
    ...dto,
  });
}

export function deleteTag(id) {
  return client.delete(apiUrl + "/tags/" + id);
}

export function patchTag(id, dto) {
  return client.patch(apiUrl + "/tags/" + id, dto);
}

export function getTag(id) {
  return client.get(apiUrl + "/tags/" + id);
}

export function createTagGroup(dto) {
  return client.post(apiUrl + "/tags/groups", {
    ...dto,
  });
}
export function searchTags(query) {
  return client.get(apiUrl + "/tags/search?query=" + query);
}
export function getEntityTags(entityType, entityId) {
  return client.get(
    `${apiUrl}/tags/entity?entityType=${entityType}&entityId=${entityId}`
  );
}

export function deleteEntityTag(id) {
  return client.delete(`${apiUrl}/tags/entity/${id}`);
}
export function createEntityTag(dto) {
  return client.post(`${apiUrl}/tags/entity`, dto);
}
