import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React from "react";
export function GenericSidebarCard(props) {
  const showAllProps = () => {
    if (
      props.layer &&
      props.layer.includeProperties &&
      props.layer.includeProperties[0] != "*"
    ) {
      return false;
    }
    return true;
  };

  const getFeaturesForList = () => {
    if (showAllProps()) return props.features;
    else return _.orderBy(props.features, props.layer.includeProperties[0]);
  };
  return (
    <>
      {getFeaturesForList().map((feature, i) => (
        <Card className="w-100" key={i} variant="outlined">
          <CardContent className="pb-0 pt-1 pl-1 pr-1">
            {showAllProps()
              ? Object.entries(feature).map(([key, value]) => {
                  return (
                    <div>
                      <Typography variant="subtitle2" component={"span"}>
                        {key}:&nbsp;
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        {value ?? "-"}
                      </Typography>
                    </div>
                  );
                })
              : props.layer.includeProperties.map((prop) => {
                  return (
                    <div>
                      <Typography component={"span"} variant="subtitle2">
                        {prop}:&nbsp;
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        {feature[prop] ?? "-"}
                      </Typography>
                    </div>
                  );
                })}
          </CardContent>
        </Card>
      ))}
    </>
  );
}
