import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { getStatusColor, getUrgencyColor } from "../map/map-sidebar";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
import { useSelector } from "react-redux";
export function EventMobileCard(props) {
  const history = useHistory();
  var organization = useSelector((state) => state.organization);
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const isOffline = !useOnlineStatus();
  const event = props.entity;
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          {!isOffline ? (
            <Link
              href="#"
              underline="none"
              onClick={(e) => handleNavigateTo(e, `events/${event.id}`)}
            >
              {event.eventNumber}
            </Link>
          ) : (
            event.eventNumber
          )}
        </Typography>
        {!organization.multipleAssetEvents ? (
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Asset: {event.assetName}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Assets: {event.assetNames}
          </Typography>
        )}
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {event.eventDateTime
            ? "Completed: " +
              moment
                .utc(event.eventDateTime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss")
            : "Due: " +
              moment
                .utc(event.eventDueDateTime)

                .format("YYYY-MM-DD")}
        </Typography>
        <Typography variant="h6">{event.eventCategory}</Typography>
        {event.eventSubCategory && (
          <Typography color="text.secondary">
            {event.eventSubCategory}
          </Typography>
        )}
        <Typography sx={{ mt: 1, mb: 1 }} variant="body2">
          Description: {event.description || "-"}
        </Typography>{" "}
        <Typography variant="body2">
          <i
            className={"fe fe-circle "}
            style={{ color: getStatusColor(event.status) }}
          />
          &nbsp;Status:{" "}
          {_.toLower(event.status) == "pending review"
            ? "Pending Review"
            : _.toLower(event.status) == "incomplete (overdue)"
            ? "Incomplete (Overdue)"
            : event.status}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <i
            className={"fe fe-circle "}
            style={{ color: getUrgencyColor(event.urgency) }}
          />
          &nbsp;Urgency: {event.urgency || "None"}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Assigned To: {event.assignedToUserFullNames || "-"}
        </Typography>
      </CardContent>
    </Card>
  );
}
