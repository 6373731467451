import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { localStorageService } from "services/local-storage-service";
import CloseIcon from "@mui/icons-material/Close";

export function DataGridSearch(props) {
  const searchStorageName = `${props.localStorageName}_search`;
  const [debounceTime, setDebounceTime] = useState(500);
  const [search, setSearch] = useState(
    localStorageService.getLocalStorage(searchStorageName) || ""
  );

  const debouncedSearch = useDebounce(search, debounceTime);

  const handleChange = (e) => {
    const value = typeof e == "string" ? e : e.target.value;
    setSearch(value);
    localStorageService.setLocalStorage(searchStorageName, value);
  };

  useEffect(() => {
    props.handleSearch(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <FormControl className={search}>
      <TextField
        fullWidth
        placeholder={props.placeholder}
        size="small"
        variant="standard"
        onChange={(e) => {
          setDebounceTime(500);
          handleChange(e);
        }}
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: search && (
            <InputAdornment position="end">
              <IconButton
                color="error"
                title="Clear"
                size="small"
                aria-label="Clear"
                onClick={(e) => {
                  setDebounceTime(0);
                  handleChange("");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
