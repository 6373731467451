import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { dateUtil } from "../../../services/date-util";
export function LinkedEvents(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 50 }}></TableCell>
            <TableCell sx={{ width: 80 }}>Event #</TableCell>
            <TableCell sx={{ width: 110 }}>Due Date </TableCell>
            <TableCell sx={{ width: 140 }}>Completed Date </TableCell>
            <TableCell sx={{ width: 110 }}>Category</TableCell>
            <TableCell sx={{ width: 110 }}>Subcategory</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.events.map((event) => (
            <TableRow
              key={event.eventNumber}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {!event.isParent ? (
                  <ArrowUpwardIcon sx={{ color: "rgba(0, 0, 0, .60)" }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: "rgba(0, 0, 0, .60)" }} />
                )}
              </TableCell>
              <TableCell component="th" scope="row">
                {event.eventNumber}
              </TableCell>
              <TableCell>
                {event.eventDueDateTime
                  ? dateUtil.convertDateOnlyToLocal(event.eventDueDateTime)
                  : ""}
              </TableCell>
              <TableCell>
                {event.eventDateTime
                  ? moment.utc(event.eventDateTime).format("YYYY-MM-DD")
                  : ""}
              </TableCell>
              <TableCell>{event.eventCategory}</TableCell>
              <TableCell>{event.eventSubCategory}</TableCell>
              <TableCell>{event.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
