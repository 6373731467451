import { Pending } from "@mui/icons-material";

export const UserRoles = {
  Admin: "Admin",
  SuperAdmin: "SuperAdmin",
  IncidentsUser: "IncidentsUser",
  EventsUser: "EventsUser",
  MonitoringUser: "MonitoringUser",
  InternalUser: "InternalUser",
  InspectionsUser: "InspectionsUser",
  EnergyUser: "EnergyUser",
  MshaManagedUser: "MshaManagedUser",
  MshaUser: "MshaUser",
  TimeSeriesUser: "TimeSeriesUser",
  WylooUser: "WylooUser",
  PortalUser: "PortalUser",
  PortalAdmin: "PortalAdmin",
};

export const PortalUserHistoryActions = {
  PageVisit: "PageVisit",
};
export const EventStatuses = {
  PendingEmailReview: "Pending Email Review",
  Complete: "Complete",
  Incomplete: "Incomplete",
  PendingReview: "Pending Review",
  PendingActions: "Pending Actions",
  IncompleteOverdue: "Incomplete (Overdue)",
};

export const MapLayerNames = {
  MonitoringEvents: "Monitoring Events",
  MonitoringLocations: "Monitoring Locations",
  Assets: "Assets",
  Events: "Events",
};

export const MapLayerKeys = {
  MonitoringEvents: "internalMonitoring",
  Assets: "internalAssets",
  Events: "internalEvents",
  MonitoringLocations: "internalMonitoringLocations",
};

export const EntityTypes = {
  Event: "Event",
  Incident: "Incident",
  IncidentInvestigation: "IncidentInvestigation",
  MonitoringEvent: "MonitoringEvent",
  MonitoringResult: "MonitoringResult",
  Action: "Action",
  MonitoringVarianceLimit: "MonitoringVarianceLimit",
  MonitoringLocation: "MonitoringLocation",
  InspectionEvent: "InspectionEvent",
  EnergyLog: "EnergyLog",
  Organization: "Organization",
  Equipment: "Equipment",
  Program: "Program",
  Permit: "Permit",
  TimeSeries: "TimeSeries",
};

export const TourTypes = {
  Initial: "Initial",
  EventsGrid: "EventsGrid",
  MonitoringGrid: "MonitoringGrid",
  IncidentsGrid: "IncidentsGrid",
};

export const MapSourceTypes = {
  Mapbox: "Mapbox",
  ArcGis: "ArcGis",
};

export const LookupTypes = {
  CustomerType: "CustomerType",
  CustomerCategory: "CustomerCategory",
  AssetType: "AssetType",
  EventSubCategory: "EventSubCategory",
  EventCategory: "EventCategory",
  EventLocationType: "EventLocationType",
  ContactType: "ContactType",
  ContactGroupType: "ContactGroupType",
  TagGroup: "TagGroup",
  Tag: "Tag",
  TagCategory: "TagCategory",
  Unit: "Unit",
  MonitoringEventType: "MonitoringEventType",
  ActionCategory: "ActionCategory",
  ActionEvidenceRequired: "ActionEvidenceRequired",
  IncidentParticipantType: "IncidentParticipantType",
  IncidentSeverity: "IncidentSeverity",
  IncidentType: "IncidentType",
  IncidentSubType: "IncidentSubType",
  MonitoringResultType: "MonitoringResultType",
  MonitoringLimitType: "MonitoringLimitType",
  MonitoringLocationType: "MonitoringLocationType",
  //non standard
  Users: "Users",
  Assets: "Assets",
  MonitoringLocations: "MonitoringLocations",
  ImportSpecifications: "ImportSpecifications",
  EnergyParameterUnits: "EnergyParameterUnits",
  EnergyLogType: "EnergyLogType",
  PermitType: "PermitType",
  CorrespondenceLogType: "CorrespondenceLogType",
};

export const VarianceStatuses = {
  Exceedance: "Exceedance",
  NonExceedance: "Non-Exceedance",
};

export const CustomFieldTypes = {
  Dropdown: "Dropdown",
  Text: "Text",
  TextArea: "TextArea",
  Number: "Number",
  Date: "Date",
  DateTime: "DateTime",
  Checkbox: "Checkbox",
  MultiSelect: "MultiSelect",
  Paragraph: "Paragraph",
  Divider: "Divider",
  Signature: "Signature",
};
export const EventContactTypes = {
  Contact: "Contact",
  ContactGroup: "ContactGroup",
  User: "User",
};

export const EntityPermissions = {
  CanPerformInitialReview: "canPerformInitialReview",
  CanPerformFinalReview: "canPerformFinalReview",
  CanApprove: "canApprove",
  CanVerify: "canVerify",
};
export const InspectionFormTypes = {
  DefaultSafetyProgram: "DefaultSafetyProgram",
  IncidentInvestigation: "IncidentInvestigation",
  IncidentParticipant: "IncidentParticipant",
  Inspection: "Inspection",
  SafetyProgramEvaluation: "SafetyProgramEvaluation",
};
export const LocalStorageKeys = {
  DashboardMapViewport: "dashboard-map-viewport",
  DashboardMapEnabledLayers: "layerLibraryDashboard",
  OfflineTimeout: "offlineTimeout",
  WidgetFilters: "widgetFilters",
  DisableOfflineMode: "disableOfflineMode",
  DisableOfflineDateTime: "disableOfflineDateTime",
  MapFilterSelectedAssets: "mapFilterSelectedAssets",
  MapFilterSelectedMonitoringLocationTypes:
    "mapFilterSelectedMonitoringLocationTypes",
  AccessToken: "access_token",
  Ip: "ip",
};
export const ActionStatuses = {
  Closed: "Closed",
  PendingApproval: "Pending Approval",
  Approved: "Approved",
  Overdue: "Overdue",
  PendingVerification: "Pending Verification",
};
export const BulkUpdateActions = {
  Update: "Update",
  UpdateIfEmpty: "UpdateIfEmpty",
  Clear: "Clear",
  Append: "Append",
  Prepend: "Prepend",
};
export const Statuses = {
  Complete: "Complete",
  Incomplete: "Incomplete",
  PendingReview: "Pending Review",
};

export const MonitoringMenuKeys = {
  Events: "Events",
  Locations: "Locations",
  Results: "Results",
  ResultsWide: "Wideresults",
  Timeseries: "Timeseries",
};

export const PhotoImportHistoryStatuses = {
  UploadingPhotos: "UploadingPhotos",
  Staged: "Staged",
  StartingModel: "StartingModel",
  AnalyzingPhotos: "AnalyzingPhotos",
  AwaitingReview: "AwaitingReview",
  Complete: "Complete",
  AwaitingModelTraining: "AwaitingModelTraining",
};

export const MonitoringLocationPortalTypes = {
  SurfaceWater: "SurfaceWater",
  GroundWater: "GroundWater",
  Wildlife: "Wildlife",
  Air: "Air",
  Vegetation: "Vegetation",
};
export const IncidentReportTypes = {
  InitialIncidentReport: "InitialIncidentReport",
  IncidentInvestigationReport: "IncidentInvestigationReport",
  FullIncidentReport: "FullIncidentReport",
};
