export const permitColumns = (predefinedLookups = null) => [
  {
    field: "permitNumber",
    headerName: "Permit #",
    width: 100,
    type: "string",
  },
  {
    field: "assetNames",
    headerName: "Asset Names",
    width: 200,
    type: "string",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdByFullName",
    headerName: "Created By",
    width: 150,
    type: "singleSelect",
    lookup: "users",
    lookupValues: predefinedLookups.users,
    valueField: "id",
    labelField: "fullName",
  },
  {
    field: "permitTypeName",
    headerName: "Permit Type",
    width: 200,
    type: "singleSelect",
    lookup: "permitType",
    valueField: "id",
    labelField: "code",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 200,
    type: "dateTime",
    filterable: false,
  },
  {
    field: "expiryDate",
    headerName: "Expiry Date",
    width: 200,
    type: "dateTime",
    filterable: false,
  },
  {
    field: "appliedDate",
    headerName: "Applied Date",
    width: 200,
    type: "dateTime",
    filterable: false,
  },
  {
    field: "circulatedDate",
    headerName: "Circulated Date",
    width: 200,
    type: "dateTime",
    filterable: false,
  },
  {
    field: "applyToCirc",
    headerName: "Apply To Circ",
    width: 125,
    type: "number",
    filterable: false,
    sortable: false,
  },
  {
    field: "applyToAccept",
    headerName: "Apply To Accept",
    width: 125,
    type: "number",
    filterable: false,
    sortable: false,
  },
  {
    field: "circToAccept",
    headerName: "Circ To Accept",
    width: 125,
    type: "number",
    filterable: false,
    sortable: false,
  },
];
