import * as React from "react";

import { getAssetLookups } from "../../../services/assets";
import { dateUtil } from "../../../services/date-util";
import { getInspectionForms } from "../../../services/inspection-form-service";
import { getUsersLookups } from "../../../services/users";
import { statusColorSwitch } from "./inspection-columns";
export const programColumns = [
  {
    field: "inspectionNumber",
    headerName: "Form #",
    width: 100,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Mine Site",
    width: 100,
    type: "singleSelect",
    lookupFunction: getAssetLookups,
    valueField: "id",
    labelField: "name",
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    type: "singleSelect",
    valueOptions: ["Completed", "Incomplete", "Overdue"],
    renderCell: (params) => (
      <div className="event-status">
        <i
          className={"fe fe-circle "}
          style={{ color: statusColorSwitch(params.row) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "inspectionFormName",
    headerName: "Form",
    width: 300,
    type: "singleSelect",
    lookupFunction: getInspectionForms,
    lookupFunctionParams: { onlyProgramForms: true },
    valueField: "id",
    labelField: "name",
  },

  {
    field: "assignedToUserFullName",
    headerName: "Assigned To",
    width: 100,
    type: "singleSelect",
    lookupFunction: getUsersLookups,
    valueField: "id",
    labelField: "fullName",
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 150,
    type: "date",
    valueGetter: (params) => {
      return dateUtil.convertDateOnlyToLocal(params.value);
    },
  },
  {
    field: "completedDateTime",
    headerName: "Completed Date",
    width: 150,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
];
