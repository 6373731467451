import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Layer,
  Map,
  Marker,
  Source,
  NavigationControl,
  Popup,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { Form, Grid } from "tabler-react";
import useOnlineStatus from "./../hooks/useOnlineStatus";
import {
  MAPBOX_TOKEN,
  baseMapStyleKey,
  mapStyles,
} from "./dashboards/map/dashboard-map";
import Pin from "./dashboards/map/pin";
import lynxColors from "../modules/lynxColors";
import { DrawControl } from "./dashboards/map/draw-control";
import { GeocoderControl } from "./dashboards/map/geocoder-control";
import { LynxDialog } from "./lynx-dialog";
import { localStorageService } from "../services/local-storage-service";
import { EntityTypes, MapSourceTypes } from "../types/enums";
import { getEventMapLayers } from "../services/map";
import { GenericSidebarCard } from "./dashboards/map/generic-sidebar-card";
import { Accordion, AccordionDetails, AccordionSummary } from "./accordion";
const gjv = require("geojson-validation");
export function LocationSelectionControl(props) {
  const [formState, setFormState] = useState({});
  const drawRef = React.useRef();
  const [allowPaste, setAllowPaste] = useState(false);
  const [marker, setMarker] = useState({
    latitude: 40,
    longitude: -100,
  });

  const [size, setSize] = useState([0, 0]);
  const [popupLatLng, setPopupLatLng] = useState(null);
  const navControlStyle = {
    left: 10,
    top: 10,
  };
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 40,
    longitude: -100,
    zoom: 11,
    bearing: 0,
    pitch: 0,
  });
  const [hasUtmError, setHasUtmError] = useState(false);
  const [geojson, setGeojson] = useState("");
  const [features, setFeatures] = useState([]);
  const [mapExpanded, setMapExpanded] = useState(false);
  const [showGeojsonError, setShowGeojsonError] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [baseMapStyle, setBaseMapStyle] = useState(null);
  const [externalLayers, setExternalLayers] = useState([]);
  const [externalFeature, setExternalFeature] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState({});
  var account = useSelector((state) => state.account);
  const useUtm = account.coordinateType == "LatLong" ? false : true;
  const isOffline = !useOnlineStatus();

  // prettier-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
  var utmObj = require("utm-latlng");
  var utm = new utmObj();
  const utmPercision = 1;

  const map = useRef();
  const targetRef = useRef();

  useEffect(() => {
    if (props.entityType && props.entityType == EntityTypes.Event) {
      getEventMapLayers().then((res) => {
        setExternalLayers(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (drawRef.current) {
      var featureCollection = drawRef.current.getAll();
      if (!_.isEmpty(featureCollection.features)) {
        setGeojson(JSON.stringify(featureCollection));
        props.handleGeojsonChange(JSON.stringify(featureCollection));
      } else {
        setGeojson("");
        props.handleGeojsonChange("");
      }
    }
  }, [features]);

  useEffect(() => {
    if (props.defaultGeojson && mapLoaded && props.defaultGeojsonCentroid) {
      drawRef.current.deleteAll().add(JSON.parse(props.defaultGeojson));
      setGeojson(props.defaultGeojson);
      setViewport({
        ...viewport,
        latitude: props.defaultGeojsonCentroid[0],
        longitude: props.defaultGeojsonCentroid[1],
      });
      setMarker({
        latitude: props.defaultGeojsonCentroid[0],
        longitude: props.defaultGeojsonCentroid[1],
      });
    }
  }, [props.defaultGeojson, props.defaultGeojsonCentroid, mapLoaded]);

  useLayoutEffect(() => {
    //handle resizing of map
    if (!isOffline && baseMapStyle && baseMapStyle.value) {
      function updateSize() {
        var width = targetRef.current.offsetWidth;
        setSize([width, targetRef.current.offsetHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [isOffline, baseMapStyle]);

  useEffect(() => {
    if (props.defaultLatitude && props.defaultLongitude) {
      setViewport({
        ...viewport,
        latitude: props.defaultLatitude,
        longitude: props.defaultLongitude,
      });
      setMarker({
        longitude: props.defaultLongitude,
        latitude: props.defaultLatitude,
      });
      var utmValues = utm.convertLatLngToUtm(
        props.defaultLatitude,
        props.defaultLongitude,
        utmPercision
      );

      let newFormState = {
        ...formState,
        latitude: props.defaultLatitude,
        longitude: props.defaultLongitude,
        northUtm: utmValues.Northing,
        eastUtm: utmValues.Easting,
        utmZoneLetter: utmValues.ZoneLetter,
        utmZoneNumber: utmValues.ZoneNumber,
      };
      handleSetFormState(newFormState);
    }
  }, [props.defaultLatitude, props.defaultLongitude]);

  useEffect(() => {
    var baseStyle = localStorageService.getLocalStorage(baseMapStyleKey);
    if (baseStyle) {
      setBaseMapStyle(baseStyle);
    } else {
      setBaseMapStyle(mapStyles[0]);
    }
  }, []);

  const onMarkerDragEnd = useCallback(
    (event) => {
      if (useUtm) {
        var utmValues = utm.convertLatLngToUtm(
          event.lngLat.lat,
          event.lngLat.lng,
          utmPercision
        );
      }
      setMarker({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
      });
      if (props.handleLocationTouched) {
        props.handleLocationTouched();
      }

      handleSetFormState({
        ...formState,
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
        northUtm: useUtm ? utmValues.Northing : "",
        eastUtm: useUtm ? utmValues.Easting : "",
        utmZoneNumber: useUtm ? utmValues.ZoneNumber : "",
        utmZoneLetter: useUtm ? utmValues.ZoneLetter : "",
      });
    },
    [formState]
  );

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value = e.target.value;
    _.set(newState, name, value);
    if (props.handleLocationTouched) {
      props.handleLocationTouched();
    }

    handleSetFormState(newState);
  };

  const validateLatLong = (e) => {
    const { name, value } = e.target;
    let isValid = false;
    let newState = { ...formState };
    if (useUtm) {
      if (
        name === "northUtm" ||
        name === "eastUtm" ||
        name === "utmZoneNumber" ||
        name === "utmZoneLetter"
      ) {
        var latLong = utm.convertUtmToLatLng(
          newState.eastUtm,
          newState.northUtm,
          newState.utmZoneNumber,
          newState.utmZoneLetter
        );

        if (!latLong.lat || !latLong.lng) {
          setHasUtmError(true);
          alertify.error("Invalid UTM coordinates.");
        } else {
          setHasUtmError(false);
          newState.latitude = latLong.lat;
          newState.longitude = latLong.lng;
          setMarker({ latitude: latLong.lat, longitude: latLong.lng });
        }
      }
    }
    if (name === "latitude") {
      isValid = isFinite(value) && Math.abs(value) <= 90;
      _.set(newState, "latitudeError", "");
    }
    if (name === "longitude") {
      isValid = isFinite(value) && Math.abs(value) <= 180;
      _.set(newState, "longitudeError", "");
    }
    if (isValid) {
      let newMarker = { ...marker };
      _.set(newMarker, name, _.toNumber(value));
      setMarker(newMarker);
    } else {
      if (name === "latitude") {
        let latitudeError = "Latitude is invalid";
        _.set(newState, "latitudeError", latitudeError);
      }
      if (name === "longitude") {
        let longitudeError = "Longitude is invalid";
        _.set(newState, "longitudeError", longitudeError);
      }
    }
    handleSetFormState(newState);
  };
  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        let newState = {
          ...formState,
          latitude: latitude,
          longitude: position.coords.longitude,
        };
        var utmValues = utm.convertLatLngToUtm(
          latitude,
          longitude,
          utmPercision
        );
        newState.northUtm = utmValues.Northing;
        newState.eastUtm = utmValues.Easting;
        newState.utmZoneLetter = utmValues.ZoneLetter;
        newState.utmZoneNumber = utmValues.ZoneNumber;
        if (props.handleLocationTouched) {
          props.handleLocationTouched();
        }

        handleSetFormState(newState);
        setMarker({
          latitude: _.toNumber(latitude),
          longitude: _.toNumber(longitude),
        });
        setViewport({
          ...viewport,
          latitude: _.toNumber(latitude),
          longitude: _.toNumber(longitude),
        });
      },
      function (error) {
        alertify.error("Can't access current location.");
      }
    );
  };
  const handleMapClick = (e) => {
    e.originalEvent.stopPropagation();
    let externalFeatures = e.features
      .filter((x) =>
        externalLayers
          .filter((x) => x.isInteractive)
          .map((x) => x.key)
          .includes(x.layer.id)
      )
      .map((x) => x.properties);

    if (!_.isEmpty(externalFeatures)) {
      setSelectedLayer(
        externalLayers.find(
          (x) => x.isInteractive && x.key == e.features[0].layer.id
        )
      );

      setPopupLatLng(e.lngLat);
      setExternalFeature(externalFeatures[0]);
    }
  };
  const handleSetFormState = (newState) => {
    props.handleLatLongChange(newState.latitude, newState.longitude);
    setFormState(newState);
  };
  const handleSelectorChanged = (selector) => {
    props.setSelector(selector);
  };

  const onDrawUpdate = useCallback((e) => {
    if (props.handleLocationTouched) {
      props.handleLocationTouched();
    }
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDrawDelete = useCallback((e) => {
    if (props.handleLocationTouched) {
      props.handleLocationTouched();
    }
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const handleAllowPaste = () => {
    if (!allowPaste) {
      setAllowPaste(true);
    } else {
      if (isJsonString(geojson)) {
        if (gjv.valid(JSON.parse(geojson))) {
          props.handleGeojsonChange(geojson);
          drawRef.current.deleteAll().add(JSON.parse(geojson));
          setAllowPaste(false);
          if (props.handleLocationTouched) {
            props.handleLocationTouched();
          }
        } else {
          setShowGeojsonError(true);
        }
      } else {
        setShowGeojsonError(true);
      }
    }
  };

  const getPopupBody = () => {
    return (
      <GenericSidebarCard features={[externalFeature]} layer={selectedLayer} />
    );
  };

  const getMapSection = () => {
    return (
      <div
        style={{ width: "100%", height: "400px" }}
        className="mb-2"
        ref={targetRef}
      >
        <Map
          {...viewport}
          mapStyle={"mapbox://styles/mapbox/" + baseMapStyle.value}
          onMove={(nextViewport) => setViewport(nextViewport.viewState)}
          mapboxAccessToken={MAPBOX_TOKEN}
          ref={map}
          onLoad={() => {
            setMapLoaded(true);
          }}
          doubleClickZoom={false}
          interactiveLayerIds={[
            ...externalLayers.filter((x) => x.isInteractive).map((x) => x.key),
          ]}
          style={{ width: size[0], height: size[1] }}
          onClick={(e) => handleMapClick(e)}
        >
          {props.hasGeocoder && (
            <GeocoderControl
              mapboxAccessToken={MAPBOX_TOKEN}
              position="top-left"
            />
          )}
          {popupLatLng != null && (
            <Popup
              longitude={Number(popupLatLng.lng)}
              latitude={Number(popupLatLng.lat)}
              closeOnClick={false}
              style={{ padding: 0 }}
              onClose={() => setPopupLatLng(null)}
            >
              {getPopupBody()}
            </Popup>
          )}
          {(props.selector === "pin" || !props.selector) && (
            <Marker
              longitude={marker.longitude}
              latitude={marker.latitude}
              draggable
              onDragEnd={props.disabled ? null : onMarkerDragEnd}
            >
              <Pin size={20} />
            </Marker>
          )}
          <NavigationControl style={navControlStyle} />
          {!_.isEmpty(props.supportingGeojson) && (
            <>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="fill"
                  filter={["==", "$type", "Polygon"]}
                  paint={{
                    "fill-color": lynxColors.harvestOrange,
                    "fill-opacity": 0.3,
                  }}
                />
              </Source>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="line"
                  filter={["==", "$type", "LineString"]}
                  paint={{
                    "line-color": lynxColors.harvestOrange,
                  }}
                />
              </Source>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="circle"
                  filter={["==", "$type", "Point"]}
                  paint={{
                    "circle-color": lynxColors.harvestOrange,
                  }}
                />
              </Source>
            </>
          )}
          {props.selector === "fence" && (
            <DrawControl
              position="top-right"
              displayControlsDefault={true}
              ref={drawRef}
              defaultMode="draw_polygon"
              onCreate={onDrawUpdate}
              onUpdate={onDrawUpdate}
              onDelete={onDrawDelete}
            />
          )}
          {mapLoaded &&
            externalLayers
              .filter(
                (x) =>
                  _.toLower(x.sourceType) == _.toLower(MapSourceTypes.Mapbox)
              )
              .map((layer) => (
                <Source type="vector" url={layer.url}>
                  <Layer
                    type={_.toLower(layer.layerType)}
                    id={_.toString(layer.key)}
                    source-layer={layer.key}
                    paint={JSON.parse(layer.paintJson)}
                    layout={{ visibility: "visible" }}
                    key={_.toString(layer.key)}
                  />
                </Source>
              ))}
          {!_.isEmpty(props.geojson) && props.selector == "geojson" && (
            <>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="fill"
                  filter={["==", "$type", "Polygon"]}
                  paint={{
                    "fill-color": lynxColors.harvestOrange,
                    "fill-opacity": 0.3,
                  }}
                />
              </Source>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="line"
                  filter={["==", "$type", "LineString"]}
                  paint={{
                    "line-color": lynxColors.harvestOrange,
                  }}
                />
              </Source>
              <Source type="geojson" data={props.supportingGeojson}>
                <Layer
                  type="circle"
                  filter={["==", "$type", "Point"]}
                  paint={{
                    "circle-color": lynxColors.harvestOrange,
                  }}
                />
              </Source>
            </>
          )}
        </Map>
      </div>
    );
  };

  return (
    <>
      {showGeojsonError && (
        <LynxDialog
          open={showGeojsonError}
          handleClose={() => setShowGeojsonError(false)}
          title={`Invalid Geojson`}
          description={"The geojson is invalid."}
        />
      )}
      {props.selector && (
        <Grid.Col width={12} className="mb-2">
          <Form.SelectGroup>
            <Form.SelectGroupItem
              icon="map-pin"
              name="selection"
              value="map-pin"
              checked={props.selector === "pin"}
              onClick={() => handleSelectorChanged("pin")}
            />
            <Form.SelectGroupItem
              icon="square"
              name="selection"
              value="square"
              checked={props.selector === "fence"}
              onClick={() => handleSelectorChanged("fence")}
            />
          </Form.SelectGroup>
        </Grid.Col>
      )}

      {(props.selector == "pin" || !props.selector) && (
        <>
          {useUtm && (
            <>
              <Grid.Col md={useUtm ? 2 : 6} width={12}>
                <Form.Group label={"Number"} isRequired={props.isRequired}>
                  <Form.Input
                    type="number"
                    value={formState.utmZoneNumber}
                    name={"utmZoneNumber"}
                    onChange={handleInputChange}
                    onBlur={validateLatLong}
                    error={props.error ?? hasUtmError}
                    disabled={props.disabled}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={useUtm ? 2 : 6} width={12}>
                <Form.Group label={"Letter"} isRequired={props.isRequired}>
                  <Form.Input
                    type="text"
                    value={formState.utmZoneLetter}
                    name={"utmZoneLetter"}
                    onChange={handleInputChange}
                    onBlur={validateLatLong}
                    error={hasUtmError}
                    disabled={props.disabled}
                  />
                </Form.Group>
              </Grid.Col>
            </>
          )}
          <Grid.Col md={useUtm ? 4 : 6} width={12}>
            <Form.Group
              label={useUtm ? "UTM Easting" : "Latitude"}
              isRequired={props.isRequired}
            >
              <Form.Input
                type="text"
                value={useUtm ? formState.eastUtm : formState.latitude}
                name={useUtm ? "eastUtm" : "latitude"}
                onChange={handleInputChange}
                onBlur={validateLatLong}
                disabled={props.disabled}
                error={
                  useUtm ? hasUtmError : props.error ?? formState.latitudeError
                }
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={useUtm ? 4 : 6} width={12}>
            <Form.Group
              label={useUtm ? "UTM Northing" : "Longitude"}
              isRequired={props.isRequired}
            >
              <Form.Input
                type="text"
                value={useUtm ? formState.northUtm : formState.longitude}
                name={useUtm ? "northUtm" : "longitude"}
                onChange={handleInputChange}
                disabled={props.disabled}
                onBlur={validateLatLong}
                error={useUtm ? hasUtmError : formState.longitudeError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Button
              color="secondary"
              variant="outlined"
              className="mb-3 w-100"
              disabled={props.disabled}
              onClick={getCurrentLocation}
            >
              Use Current Location
            </Button>
          </Grid.Col>
        </>
      )}

      {!isOffline && baseMapStyle && baseMapStyle.value && (
        <Grid.Col md={12} width={12}>
          {props.isMapExpandable ? (
            <Accordion
              expanded={mapExpanded}
              onChange={() => setMapExpanded(!mapExpanded)}
            >
              <AccordionSummary aria-controls="map-content" id="map-header">
                <Typography>
                  Click to {mapExpanded ? "close" : "open"} map
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{getMapSection()}</AccordionDetails>
            </Accordion>
          ) : (
            getMapSection()
          )}
        </Grid.Col>
      )}
      {props.selector == "fence" && (
        <>
          <Grid.Col width={12}>
            <Button
              className="mb-2"
              variant="contained"
              onClick={handleAllowPaste}
            >
              {allowPaste ? "Submit" : "Unlock"} Geojson
            </Button>
          </Grid.Col>
          <Grid.Col width={12}>
            <div className="">
              <Form.Group>
                <div>
                  <Form.Textarea
                    error={props.error}
                    disabled={!allowPaste}
                    onChange={(e) => {
                      setGeojson(e.target.value);
                    }}
                  >
                    {geojson}
                  </Form.Textarea>
                </div>
              </Form.Group>
            </div>
          </Grid.Col>
        </>
      )}
    </>
  );
}
