import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import { loadAccount } from "actions/account";
import { loggedIn, logoutUser } from "actions/auth";
import alertify from "alertifyjs";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LynxLogo from "../../../images/Lynx-logomark-colour-rgb.svg";
import "../wyloo/wyloo-microsite.scss";
import { WylooLoginRegister } from "./wyloo-login-register";
import { wylooTheme } from "./wyloo-theme";
import { loadPortal } from "actions/portal";
import { logUserHistory } from "services/organization-portal-service";
import { LocalStorageKeys, PortalUserHistoryActions } from "types/enums";
import { LynxDialog } from "components/lynx-dialog";
import { Grid, Form } from "tabler-react";
import { validationService } from "services/validation";
import { updatePortalUserSelf } from "services/users";
import { deactivateUser } from "services/auth-service";

export function WylooPortalNavbar(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const portal = useSelector((state) => state.portal);
  var account = useSelector((state) => state.account);
  const [showProfile, setShowProfile] = useState(false);
  const [ip, setIp] = useState(null);
  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const [formState, setFormState] = useState({});
  const [deactivate, setDeactivate] = useState(false);
  useEffect(() => {
    if (loggedIn()) {
      dispatch(loadAccount());
    }
  }, [loggedIn()]);

  useEffect(() => {
    dispatch(loadPortal(window.location.href));
  }, []);
  useEffect(() => {
    if (!account.isLoading) {
      setFormState({
        email: account.email,
        firstName: account.firstName,
        lastName: account.lastName,
      });
    }
  }, [account]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);

    setFormState(newState);
  };
  useEffect(() => {
    if (!portal.isLoading) {
      document.title = portal.name;
    }
  }, [portal]);
  React.useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(LocalStorageKeys.Ip, data.ip);
        setIp(data.ip);
      })
      .catch((error) => {
        localStorage.setItem(LocalStorageKeys.Ip, "");
        setIp("");
      });
  }, []);
  React.useEffect(() => {
    if (ip != null) {
      logUserHistory({
        url: window.location.href,
        ipAddress: ip,
        action: PortalUserHistoryActions.PageVisit,
      });
    }
  }, [location, ip]);

  useEffect(() => {
    var searchObj = queryString.parse(location.search);
    if (searchObj && searchObj.redirect) {
      setRedirect(searchObj.redirect);
      setShowLoginRegister(true);
    }
  }, [location]);
  const openAccount = Boolean(anchorElAccount);
  const auth = useSelector((state) => state.auth);
  const [showLoginRegister, setShowLoginRegister] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const handleLogoutLogin = () => {
    if (loggedIn()) {
      dispatch(logoutUser());
      alertify.success("Logged out successfully");
    } else {
      setShowLoginRegister(true);
    }
  };

  const clearRedirect = () => {
    history.replace({
      search: null,
    });
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const getInitials = () => {
    let nameString = "";
    if (account.firstName) nameString += _.first(account.firstName);
    if (account.lastName) nameString += _.first(account.lastName);
    return nameString;
  };
  const handleAccountClick = (event) => {
    if (openAccount) {
      setAnchorElAccount(null);
    } else {
      setAnchorElAccount(event.currentTarget);
    }
  };
  const profileForm = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Col width={12} md={12}>
            <Form.Group label="Email address" isRequired>
              <Form.Input
                type="email"
                placeholder="Email"
                disabled
                value={formState.email}
                name="email"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12} md={12}>
            <Button
              variant="outlined"
              color="error"
              className="mb-5"
              onClick={() => setDeactivate(true)}
            >
              Deactivate account
            </Button>
          </Grid.Col>
          <Grid.Col width={12} md={12}>
            <Form.Group label="First Name" isRequired>
              <Form.Input
                type="text"
                placeholder="First Name"
                value={formState.firstName}
                error={formState.firstNameError}
                name="firstName"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12} md={12}>
            <Form.Group label="Last Name" isRequired>
              <Form.Input
                type="text"
                placeholder="Last Name"
                value={formState.lastName}
                error={formState.lastNameError}
                name="lastName"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First Name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last Name"
    );

    validationService.validateRequiredField(
      newState,
      "timezoneId",
      "timezoneIdError",
      "Timezone"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError",
      "timezoneIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleSaveUser = () => {
    if (!validateDataForSave()) {
      return;
    }
    var dto = {
      firstName: formState.firstName,
      lastName: formState.lastName,
    };
    updatePortalUserSelf(dto)
      .then((res) => {
        dispatch(loadAccount());
        alertify.success("Profile updated successfully.");
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
      })
      .finally(() => {
        setShowProfile(false);
      });
  };

  const handleDeactivateUser = () => {
    deactivateUser(window.location.href, ip).then(() => {
      dispatch(logoutUser());
      history.push("/wyloo");
      alertify.success("Account deactivated successfully.");
    });
  };
  return (
    <ThemeProvider theme={wylooTheme}>
      <div className="wyloo-navbar p-2">
        <img
          className="mt-1"
          src="https://wyloo.com/wp-content/uploads/2024/02/logo.svg"
          alt="Wyloo"
        />
        <img
          className="ml-5"
          style={{ paddingTop: "0.4rem" }}
          src="https://wyloo.com/wp-content/uploads/2024/02/Group-1000004464.svg"
          alt="Wyloo"
        />
        <div className="lynx-logo-container pointer">
          <img
            className="lynx-logo-wyloo"
            height={300}
            src={LynxLogo}
            alt="Wyloo"
          />
        </div>
        {!portal.isLoading && portal.isEnabled && (
          <>
            {auth.isAuthenticated && (
              <Avatar
                className="avatar-wyloo pointer"
                src="/broken-image.jpg"
                onClick={handleAccountClick}
              >
                {getInitials(account)}
              </Avatar>
            )}
            {!auth.isAuthenticated && (
              <>
                <Button
                  size="large"
                  className="login-button-wyloo mt-1 mr-1"
                  onClick={handleLogoutLogin}
                >
                  Log in
                </Button>{" "}
                <Button
                  onClick={() => {
                    window.open(
                      "https://wyloo.com/wylooeaglesnest/connect/",
                      "_blank"
                    );
                  }}
                  size="large"
                  className="contact-button-wyloo mt-1 mr-1"
                >
                  Contact
                </Button>
              </>
            )}
            {showLoginRegister && (
              <WylooLoginRegister
                open={showLoginRegister}
                redirect={redirect}
                handleClose={() => {
                  setShowLoginRegister(false);
                  clearRedirect();
                }}
              />
            )}
          </>
        )}
      </div>
      <Menu
        className="nav-menu"
        anchorEl={anchorElAccount}
        open={openAccount}
        disableScrollLock={true}
        onClose={handleCloseAccount}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setShowProfile(true);
            handleCloseAccount();
          }}
        >
          <ListItemText>Profile</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(logoutUser());
            alertify.success("Logged out successfully");

            handleCloseAccount();
          }}
        >
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
      <LynxDialog
        open={showProfile}
        handleClose={() => setShowProfile(false)}
        dialogContent={profileForm()}
        handleSave={handleSaveUser}
        title="Edit Profile"
      />
      <LynxDialog
        open={deactivate}
        handleClose={() => setDeactivate(false)}
        description={
          "Are you sure you want to deactivate your account? You will need to re-regiester to access the portal."
        }
        handleConfirm={() => {
          handleDeactivateUser();
          setDeactivate(false);
        }}
        title="Edit Profile"
      />
    </ThemeProvider>
  );
}
