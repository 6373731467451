import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { getParameters } from "../../../services/parameters";
import { LookupTypes } from "../../../types/enums";
export const monitoringEventResultColumns = [
  {
    field: "edit",
    headerName: "Edit / Delete",
    width: 100,
    type: "actions",
    sortable: false,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 150,
    type: "singleSelect",
    lookupFunction: getParameters,
    valueField: "id",
    labelField: "name",
    importable: true,
  },
  {
    field: "enteredValue",
    headerName: "Value",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "unitName",
    headerName: "Unit",
    width: 150,
    type: "singleSelect",
    lookupType: "unit",
    importable: true,
  },
  {
    field: "enteredMethodDetectionLimit",
    headerName: "MDL",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "enteredReportingDetectionLimit",
    headerName: "RDL",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "sequence",
    headerName: "Sequence",
    width: 50,
    type: "string",
    importable: true,
  },
  {
    field: "populationVariance",
    headerName: "Population Variance",
    width: 50,
    type: "number",
  },
  {
    field: "sampleVariance",
    headerName: "Sample Variance",
    width: 50,
    type: "number",
    useInDashboard: false,
  },
  {
    field: "rdp",
    headerName: "RPD",
    width: 50,
    type: "number",
  },
  {
    field: "analysisMethod",
    headerName: "Analysis Method",
    width: 150,
    type: "string",
    importable: true,
  },

  {
    field: "lowestLimitString",
    headerName: "Lowest Limit",
    width: 50,
    type: "string",
    filterable: false,
    sortable: false,
  },

  {
    field: "limitsList",
    headerName: "All Limits",
    width: 150,
    type: "string",
    filterable: false,
    sortable: false,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 100,
    type: "string",
    importable: true,
  },
  {
    field: "resultType",
    headerName: "Result Type",
    width: 100,
    type: "singleSelect",
    lookupType: LookupTypes.MonitoringResultType,
    importable: true,
  },
  {
    field: "isNonCompliant",
    headerName: "Compliance Status",
    width: 150,
    type: "singleSelect",
    valueOptions: ["Noncompliant", "Compliant"],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value !== "isAnyOf"
    ),
    renderCell: (params) => (
      <div>
        <>
          <i
            className={`fe fe-circle ${
              params.value === true
                ? "text-danger"
                : params.value === false
                ? "text-success"
                : "text-warning"
            }`}
          />
          &nbsp;{params.value}
        </>
      </div>
    ),
  },
];
