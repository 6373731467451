import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { default as lynxColors } from "../modules/lynxColors";
import { LynxDialog } from "./lynx-dialog";
import { useSelector } from "react-redux";
import { localStorageService } from "../services/local-storage-service";
import _ from "lodash";
import { completeTour } from "../services/accounts";
import { TourTypes } from "../types/enums";
import { useDispatch } from "react-redux";
import { loadAccount } from "../actions/account";
export function LynxTour(props) {
  const dispatch = useDispatch();
  const [showIntro, setShowIntro] = React.useState(false);
  const [tourState, setTourState] = useState({
    run: false,
    steps: props.steps,
    stepIndex: 0,
  });

  var account = useSelector((state) => state.account);
  useEffect(() => {
    if (!account.isLoading) {
      var currentStep = localStorageService.getLocalStorage(props.flagField);
      if (account[props.flagField]) {
        return;
      }
      if (
        props.tourType != TourTypes.Initial &&
        !account.initialTourCompletedDateTimeUtc
      ) {
        return;
      }

      if (!account[props.flagField] && (!currentStep || currentStep == 0)) {
        setShowIntro(true);
      } else {
        setTourState({
          ...tourState,
          run: true,
          stepIndex: _.toNumber(currentStep),
        });
      }
    }
  }, [account]);

  const { run, stepIndex, steps } = tourState;

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      localStorageService.setLocalStorage(
        props.flagField,
        _.toString(nextStep)
      );
      // Update state to advance the tour
      setTourState({
        ...tourState,
        stepIndex: nextStep,
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      completeTour(props.tourType).then((res) => {
        dispatch(loadAccount());
      });
      localStorageService.removeLocalStorage(props.flagField);
      setTourState({ ...tourState, run: false });
    }
  };

  return (
    <>
      <LynxDialog
        open={showIntro}
        title={props.title}
        description={props.description}
        buttons={() => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowIntro(false);
                setTourState({ ...tourState, run: true });
              }}
            >
              Start Tour
            </Button>
          );
        }}
      ></LynxDialog>

      <Joyride
        hideCloseButton
        callback={handleJoyrideCallback}
        run={run}
        stepIndex={stepIndex}
        continuous={true}
        steps={steps}
        locale={{
          back: "Back",
          close: "Close",
          last: "Finish",
          next: "Next",
          open: "Open the dialog",
          skip: "Skip",
        }}
        styles={{
          options: {
            primaryColor: lynxColors.harvestOrange,
          },
        }}
      />
    </>
  );
}
