import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../../services/date-util";
import { getComplianceColor } from "../map/map-sidebar";
export function MonitoringResultMobileCard(props) {
  const result = props.entity;
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) =>
              handleNavigateTo(e, `monitoring/${result.monitoringEventId}`)
            }
          >
            {result.monitoringEventNumber}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Location: {result.monitoringLocationName} ({result.assetName})
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {result.monitoringEventEndDateTimeUtc
            ? "End Date: " +
              dateUtil.convertDateTimeToLocal(
                result.monitoringEventEndDateTimeUtc
              )
            : "Due: " +
              dateUtil.convertDateOnlyToLocal(
                result.monitoringEventDueDateTimeUtc
              )}
        </Typography>

        <Typography variant="body2">
          Sample ID: {result.monitoringEventName}
        </Typography>
        <Typography variant="body2">
          Event Type: {result.monitoringEventType || "-"}
        </Typography>
        <Typography variant="h6">{result.parameterName}</Typography>
        {result.enteredValue && (
          <Typography variant="h6">
            {result.enteredValue} {result.unitName}
          </Typography>
        )}
        <Typography variant="body2">
          Result Type: {result.resultType || "-"}
        </Typography>
        <Typography variant="body2">Notes: {result.notes || "-"}</Typography>

        <Typography variant="body2" className="mt-1 mb-1">
          <i
            className={"fe fe-circle "}
            style={{ color: getComplianceColor(result.isNonCompliant) }}
          />
          {result.isNonCompliant == true ? " Noncompliant" : " Compliant"}
        </Typography>
      </CardContent>
    </Card>
  );
}
