import * as actions from "../actions/portal";

const initialState = {
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PORTAL_LOAD:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
      };
    case actions.PORTAL_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
        hasErrored: false,
      };
    case actions.PORTAL_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: action.error.message,
      };
    default:
      break;
  }
  return state;
};
