import * as React from "react";

import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "../../data-grid/multiple-select-operator";
import { EventStatuses } from "../../../types/enums";
var utmObj = require("utm-latlng");
var utm = new utmObj();
const utmPercision = 1;

export const getEventColumns = (hasPermitFeature, hasMultipleEventAssets, predefinedLookups = null) => {
  return [
    {
      field: "eventNumber",
      headerName: "Event #",
      width: 90,
      type: "string",
    },
    {
      field: "eventDueDateTime",
      headerName: "Due Date",
      width: 110,
      type: "date",
      valueGetter: (params) => {
        return dateUtil.convertDateOnlyToLocal(params.value);
      },
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: "eventDateTime",
      headerName: "Completed Date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
      importable: true,
      updateable: true,
      nullable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      type: "singleSelect",
      valueOptions: [
        "Complete",
        "Incomplete",
        "Incomplete (Overdue)",
        "Pending Actions",
        "Pending Review",
      ],
      renderCell: (params) => (
        <div className="event-status">
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(params.row) }}
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "assignedToUserFullNames",
      headerName: "Assigned To",
      width: 150,
      type: "singleSelect",
      lookup: "users",
      lookupValues: predefinedLookups.users,
      valueField: "id",
      labelField: "fullName",
      importable: true,
      filterOperators: [
        ...multipleSelectContainsOperator,
        ...multipleSelectDoesNotContainOperator,
      ],
      sortable: false,
    },
    {
      field: "urgency",
      headerName: "Urgency",
      width: 100,
      type: "singleSelect",
      importable: true,
      valueOptions: ["Low", "Medium", "High"],
      updateable: true,
      nullable: true,
      renderCell: (params) => (
        <div>
          {params.value && (
            <>
              <i
                className={"fe fe-circle "}
                style={{ color: urgencyColorSwitch(params.row) }}
              />
              &nbsp;{params.value}
            </>
          )}
        </div>
      ),
    },

    {
      field: "eventCategory",
      headerName: "Category",
      width: 150,
      type: "singleSelect",
      lookup: "eventCategory",
      valueField: "id",
      labelField: "code",
      updateable: true,
      nullable: false,
      dtoId: "eventCategoryId",
    },

    {
      field: "eventSubCategory",
      headerName: "Subcategory",
      width: 150,
      type: "singleSelect",
      lookup: "eventSubCategory",
      valueField: "id",
      labelField: "code",
      updateable: true,
      nullable: true,
      dtoId: "eventSubCategoryId",
    },

    ...(hasMultipleEventAssets
      ? [
          {
            field: "assetNames",
            headerName: "Asset Names",
            width: 150,
            type: "singleSelect",
            lookup: "assets",
            valueField: "id",
            labelField: "name",

            sortable: false,
            filterOperators: [
              ...multipleSelectContainsOperator,
              ...multipleSelectDoesNotContainOperator,
            ],
          },
        ]
      : [
          {
            field: "assetName",
            headerName: "Asset Name",
            width: 150,
            type: "singleSelect",
            lookup: "assets",
            valueField: "id",
            labelField: "name",
            importable: true,
            updateable: true,
            nullable: false,
            dtoId: "assetId",
          },
        ]),

    ...(hasPermitFeature
      ? [
          {
            field: "permitNames",
            headerName: "Permit Names",
            width: 150,
            type: "singleSelect",
            lookup: "permits",
            valueField: "id",
            labelField: "permitNumber",
            filterOperators: [
              ...multipleSelectContainsOperator,
              ...multipleSelectDoesNotContainOperator,
            ],
            sortable: false,
          },
        ]
      : []),
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: "followUpRequired",
      headerName: "Follow up required?",
      width: 200,
      type: "boolean",
      importable: true,
      updateable: true,
      nullable: true,
    },
    {
      field: "isConfidential",
      headerName: "Confidential?",
      width: 200,
      type: "boolean",
      updateable: true,
      nullable: true,
    },
    {
      field: "followUpDate",
      headerName: "Follow Up Date",
      width: 200,
      type: "date",
      updateable: true,
      valueGetter: (params) => {
        return dateUtil.convertDateOnlyToLocal(params.value);
      },
      importable: true,
      nullable: true,
    },
    {
      field: "followUpDescription",
      headerName: "Follow Up Description",
      width: 300,
      type: "string",
      updateable: true,
      importable: true,
      nullable: true,
    },
    {
      field: "reviewCompleted",
      headerName: "Review Completed?",
      width: 150,
      type: "boolean",

      disabled: true,
    },
    {
      field: "lastReviewedBy",
      headerName: "Last Reviewed By",
      width: 200,
      type: "singleSelect",
      lookup: "users",
      lookupValues: predefinedLookups.users,
      valueField: "id",
      disabled: true,
      labelField: "fullName",
      useInDashboard: false,
    },
    {
      field: "lastReviewedDateTimeUtc",
      headerName: "Last Review Date",
      width: 150,
      type: "dateTime",
      useInDashboard: false,
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
      disabled: true,
    },
    {
      field: "co2Emissions",
      headerName: "kg/CO2",
      width: 100,
      type: "number",
      filterable: false,
      sortable: false,
      disabled: true,
    },
    {
      field: "numberOfAttachments",
      headerName: "# of attachments",
      width: 150,
      type: "number",
    },
  ];
};

export const latLongColumns = [
  {
    field: "latitude",
    headerName: "Latitude",
    width: 100,
    type: "number",
    isEventField: true,
    disabled: true,
    valueFormatter: (params) => {
      if (!params.value) {
        return "";
      }

      return params.value;
    },
  },
  {
    field: "longitude",
    headerName: "Longitude",
    width: 100,
    isEventField: true,
    type: "number",
    disabled: true,
    valueFormatter: (params) => {
      if (!params.value) {
        return "";
      }

      return params.value;
    },
  },
];

export const utmColumns = [
  {
    field: "utmLetter",
    headerName: "UTM Letter",
    width: 100,
    isEventField: true,
    type: "string",
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{utmValues.ZoneLetter}</div>;
    },
  },
  {
    field: "utmNumber",
    headerName: "UTM Number",
    width: 100,
    type: "string",
    isEventField: true,
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{utmValues.ZoneNumber}</div>;
    },
  },
  {
    field: "utmEasting",
    headerName: "UTM Easting",
    isEventField: true,
    width: 100,
    type: "number",
    filterable: false,
    sortable: false,
    disabled: true,
    renderCell: (params) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{utmValues.Easting}</div>;
    },
  },
  {
    field: "utmNorthing",
    headerName: "UTM Northing",
    isEventField: true,
    width: 100,
    filterable: false,
    sortable: false,
    disabled: true,
    type: "number",
    renderCell: (params) => {
      var utmValues = utm.convertLatLngToUtm(
        params.row.latitude,
        params.row.longitude,
        utmPercision
      );

      return <div>{utmValues.Northing}</div>;
    },
  },
];

export const statusColorSwitch = (row) => {
  switch (_.toLower(row.status)) {
    case _.toLower(EventStatuses.Complete):
      return lynxColors.primary;
    case _.toLower(EventStatuses.Incomplete):
      return lynxColors.success;
    case _.toLower(EventStatuses.PendingReview):
    case _.toLower(EventStatuses.PendingActions):
    case _.toLower(EventStatuses.PendingEmailReview):
      return lynxColors.harvestOrange;
    case _.toLower(EventStatuses.IncompleteOverdue):
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};

export const urgencyColorSwitch = (row) => {
  switch (row.urgency) {
    case "Low":
      return lynxColors.success;
    case "Medium":
      return lynxColors.warning;
    case "High":
      return lynxColors.error;
    default:
      return "";
  }
};
